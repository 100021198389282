import React, {
  forwardRef,
  useCallback,
} from 'react';
import {
  InputFieldWarning,
  InputIconWrapper,
  StyledInput,
  StyledInputLabel,
  StyledInputWrapper,
  WrapperWithIcon,
} from './style';
import { IInputProps } from './types';

const CustomStyledInput = forwardRef<HTMLInputElement, IInputProps>(({
  label,
  onChange,
  disabled,
  error,
  icon,
  className,
  ...restProps
}, ref) => {
  const onHandleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.target.value);
    }
  }, []);

  return (
    <StyledInputWrapper className={className}>
      <StyledInputLabel
        {...(error ? { error: true } : {})}
        disabled={disabled}
      >
        {label}
      </StyledInputLabel>
      <WrapperWithIcon>
        {icon
        && (
          <InputIconWrapper>
            {icon}
          </InputIconWrapper>
        )}
        <StyledInput
          {...restProps}
          ref={ref}
          disabled={disabled}
          {...(error ? { error: true } : {})}
          onChange={onHandleChange}
        />

      </WrapperWithIcon>

      <InputFieldWarning>{error}</InputFieldWarning>
    </StyledInputWrapper>
  );
});

export default CustomStyledInput;
