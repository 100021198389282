import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    box-shadow: ${(props) => props.theme.shadow?.dropShadow_white};
    background-color: ${(props) => props.theme.palette?.red10};
  }

  &&&.Toastify__toast-body {
    ${(props) => props.theme.typography?.body};

  }

  &&&.Toastify__toast {

  }
`;
