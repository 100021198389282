import { CustomErrors, IErrorResponse } from '../core/types';
import { SOMETHING_WENT_WRONG } from './constants';
import { ICourse } from '../types';

export const getCustomerMessage = (text: string):string => {
  let message = SOMETHING_WENT_WRONG;

  switch (true) {
    case (text in CustomErrors):
      message = CustomErrors[text];
      break;
    default:
      break;
  }

  return message;
};

export const getErrorMessage = (payload: IErrorResponse | undefined) => {
  let errorMessage = '';
  const message = payload?.message;
  if (message) {
    if (typeof message === 'string') {
      errorMessage = getCustomerMessage(message);
    } else {
      errorMessage = message.map((text) => getCustomerMessage(text)).join(', ');
    }
  }
  return errorMessage || SOMETHING_WENT_WRONG;
};

export const setStorageValue = (key:string, value: string) => {
  localStorage.setItem(key, value);
};

export const removeStorageValue = (key:string) => {
  localStorage.removeItem(key);
};

export const getStorageValue = (key:string) => localStorage.getItem(key) || '';

export const chunkArray = <T>(arr: Array<T>, numArrays: number) => {
  const chunkSize = Math.ceil(arr.length / numArrays);
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

export const sortCourseArray = (a: ICourse, b: ICourse) => {
  if (a.position === b.position) {
    return 0;
  }
  return a.position > b.position ? -1 : 1;
};

export const getCourseLevels = (
  levels: string[] | null | undefined,
) => levels
  ?.join(', ')
  .replaceAll('all', 'All Levels');

export const getMinutes = (
  seconds: number | string,
) => Math.round(
  parseInt(`${seconds}`, 10) / 60,
);

export const transformDateFull = (date: string | null) => (date ? new Date(date).toLocaleString('en', { month: 'long', day: 'numeric', year: 'numeric' }) : '');
export const transformDateShort = (date: string | null) => (date ? `${new Date(date).getDate()} ${new Date(date).toLocaleString('en', { month: 'long' })}` : '');

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);
