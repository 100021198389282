import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { registerSchema } from '../../../../utils/validation/schemas';
import CustomPasswordInput from '../../../../components/inputs/CustomPasswordInput';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import { IRegisterForm, IRegisterRequest } from '../../types';
import {
  CustomEmailStyledInput,
  StyledErrorFormMessage,
  StyledWarningPoint,
  SubmitStyledButton,
} from '../../../../components/wallpapers/auth/styles';
import { StyledRegisterForm } from '../../styles';

interface IRegisterFormComponentProps {
  onSubmit: (data: IRegisterRequest) => void
  registerError?: string | null
  isLoading: boolean
}

const RegisterForm: FC<IRegisterFormComponentProps> = ({
  onSubmit, registerError, isLoading,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isValid, errors, isDirty },
  } = useForm<IRegisterForm>({
    resolver: joiResolver(registerSchema),
    mode: 'onBlur',
  });

  return (
    <StyledRegisterForm
      onSubmit={handleSubmit(onSubmit)}
    >
      <CustomEmailStyledInput
        {...register('email')}
        label="Email"
        placeholder="Enter email"
        {...(errors.email?.message ? { error: errors?.email?.message } : {})}
        inputMode="email"
        {...(errors.email?.message ? { icon: <StyledWarningPoint /> } : {})}
      />
      <CustomPasswordInput
        label="Password"
        placeholder="Enter password"
        {...register('password')}
        error={errors.password?.message ?? errors?.password?.message}
      />
      <CustomPasswordInput
        label="Confirm password"
        placeholder="Confirm password"
        {...register('confirmPassword')}
        error={errors.confirmPassword?.message ?? errors?.confirmPassword?.message}
      />
      {registerError && (<StyledErrorFormMessage>{registerError}</StyledErrorFormMessage>)}
      <SubmitStyledButton
        disabled={!isValid || !isDirty}
        type="submit"
        isLoading={isLoading}
      >
        <StyledDefaultButtonTitle>
          Sign Up
        </StyledDefaultButtonTitle>
      </SubmitStyledButton>
    </StyledRegisterForm>
  );
};

export default RegisterForm;
