import React, {
  forwardRef,
  memo, useCallback, useRef, useState,
} from 'react';
import uuid from 'react-uuid';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import {
  ManifestoText,
  PageContainer,
  StyledImage,
  StyledProgress,
  StyledProgressBackground,
  StyledProgressBarContainer,
  StyledProgressContainer,
  StyledSliderContainer,
  StyledSwiperSlide,
  SubTitle,
  TextContentContainer,
  Title,
} from './styles';

import slider1 from '../../../../images/manifest-slide-1.svg';
import slider2 from '../../../../images/manifest-slide-2.svg';
import slider3 from '../../../../images/manifest-slide-3.svg';
import slider4 from '../../../../images/manifest-slide-4.svg';
import slider5 from '../../../../images/manifest-slide-5.svg';

const Home2 = forwardRef<HTMLDivElement>((_props, ref) => {
  const slides = [
    {
      image: slider1,
      title: 'Begin with Movement',
      text: 'At Swanly, we believe in the transformative power of body movement.\n'
        + 'Our bodies aren\'t just physical forms; they\'re instruments, playing the symphony of our lives',
    },
    {
      image: slider2,
      title: 'Grace in Every Step',
      text: 'Through the elegance of ballet, we find more than just form and technique. We discover grace in our actions, our thoughts, our very essence. Elegance is not about perfection; it\'s about authenticity.',
    },
    {
      image: slider3,
      title: 'Stretch Towards the Stars',
      text: 'Pushing our limits, challenging our boundaries, and reaching for the stars - every stretch is an ode to our aspirations, a testament to our potential.',
    },
    {
      image: slider4,
      title: 'Harmony Within',
      text: 'Connecting body and mind is not a mere practice; it\'s a philosophy. At Swanly, we seek that holistic harmony, where every move resonates with our soul.',
    },
    {
      image: slider5,
      title: 'Join the Movement',
      text: 'Be part of something bigger, a community that moves together, evolves together, and celebrates every step of the journey. With Swanly, you\'re never dancing alone.',
    },
  ];

  const [progress, setProgress] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [isAutoplay, autoplay] = useState<boolean>(true);
  const swiperRef = useRef<any>();

  const onAutoplayTimeLeft = (...props: any) => {
    const progressIndex = props[2];
    const swiper = props[0];
    if (!Number.isNaN(progressIndex)) {
      setProgress((1 - progressIndex) * 100);
      if (swiper.realIndex !== activeIndex) {
        setActiveIndex(swiper.realIndex);
      }
    }
  };

  const onSlideChange = useCallback((swiper: any) => {
    if (!isAutoplay) {
      setActiveIndex(swiper.realIndex);
    }
  }, [isAutoplay]);

  const onProgressClick = useCallback((index: number) => {
    swiperRef?.current?.swiper.slideTo(index);
    setActiveIndex(swiperRef?.current?.swiper.realIndex);
  }, []);

  const getCurrentPercent = useCallback((slideIndex: number) => {
    if (activeIndex === slideIndex) {
      return progress;
    }

    if (activeIndex > slideIndex) {
      return 100;
    }

    return 0;
  }, [progress, activeIndex]);

  return (
    <PageContainer ref={ref}>
      <ManifestoText>
        Swanly’s Manifesto
      </ManifestoText>
      <StyledSliderContainer>
        <Swiper
          ref={swiperRef}
          modules={[Autoplay]}
          autoplay
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          onSlideChange={onSlideChange}
          onAutoplayStop={() => {
            autoplay(false);
          }}
          onAutoplayStart={() => {
            autoplay(true);
          }}
        >
          {
            slides.map((imageSliderArray) => (
              <SwiperSlide key={uuid()}>
                <StyledSwiperSlide key={uuid()}>
                  <StyledImage key={imageSliderArray.image} image={imageSliderArray.image} />
                  <TextContentContainer key={uuid()}>
                    <Title key={uuid()}>
                      {imageSliderArray.title}
                    </Title>
                    <SubTitle key={uuid()}>
                      {imageSliderArray.text}
                    </SubTitle>
                  </TextContentContainer>
                </StyledSwiperSlide>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </StyledSliderContainer>
      <StyledProgressBarContainer>
        {
          slides.map((slide, index) => (
            <StyledProgressContainer
              key={uuid()}
              onClick={() => {
                onProgressClick(index);
              }}
            >
              <StyledProgressBackground key={uuid()} />
              <StyledProgress key={uuid()} percent={getCurrentPercent(index)} />
            </StyledProgressContainer>
          ))
        }
      </StyledProgressBarContainer>
    </PageContainer>
  );
});

export default memo(Home2);
