import styled from 'styled-components';
import { ReactComponent as CrownIcon } from '../../../../icons/crown-icon.svg';

export const PreviewProfile = styled.div<{ $display?: boolean; }>`
    display: ${(props) => (props.$display ? 'block' : 'none')};
    position: absolute;
    top: 76px;
    right: -50px;
`;

export const SubscribeStatusIcon = styled(CrownIcon)`
    width: 16px;
    height: 16px;
    position: absolute;
    top: -2px;
    right: -2px;
    `;
