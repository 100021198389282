import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  post,
} from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import {
  ICommonChunkConfig,
  IErrorResponse,
} from '../../../types';
import {
  IRegisterRequest,
  IRegisterResponse,
} from '../types';

export const register = createAsyncThunk<IRegisterResponse, IRegisterRequest, ICommonChunkConfig>(
  'register/register',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.REGISTER, data: _data });
      return response as IRegisterResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);
