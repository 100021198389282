import { APP_ROUTES } from '../../../core/router/appRoutes';
import { ISubscriptionMapping } from '../types';

export const TABS = [
  {
    id: 'info',
    path: APP_ROUTES.PROFILE_INFO,
    name: 'Profile Info',
  },
  {
    id: 'subscription',
    path: APP_ROUTES.PROFILE_SUBSCRIPTION,
    name: 'My Subscription',
  },
];

export const PASSWORD_SUCCESS_MESSAGE = 'Password updated successfully';
export const PROFILE_SUCCESS_MESSAGE = 'Profile updated successfully';
export const AVATAR_SUCCESS_MESSAGE = 'Avatar was uploaded successfully';
export const DELETE_AVATAR_SUCCESS_MESSAGE = 'Avatar was deleted successfully';

export const SUBSCRIPTION_MAPPING: { [x: string]: ISubscriptionMapping } = {
  friends: {
    price: '199.99',
    period: 'year',
    text: 'annualy',
    trialDays: 0,
  },
  students: {
    price: '9.99',
    period: 'month',
    text: 'monthly',
    trialDays: 0,
  },
  month: {
    price: '12.99',
    period: 'month',
    text: 'monthly',
    trialDays: 7,
  },
  year: {
    price: '79.99',
    period: 'year',
    text: 'annualy',
    trialDays: 14,
  },
};
