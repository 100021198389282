import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import ProfileTabs from './components/ProfileTabs';
import { APP_ROUTES } from '../../core/router/appRoutes';
import ProfileInfoForm from './components/ProfileInfoForm';
import { IChangePasswordForm, IProfileForm } from './types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getSubscriptions,
  updatePassword,
} from './services';
import PageLoader from '../../components/loaders/PageLoader';
import { Wrapper } from './styles';
import Avatar from './components/Avatar';
import Subscription from './containers/Subscription';
import { getGlobalProfileErrorSelector, getGlobalProfileSelector } from '../../core/selectors';
import {
  deleteAvatar, getProfile, updateProfile, uploadAvatar,
} from '../../core/services';
import { getIsSubscriptionsLoadingSelector, getProfileSubscriptionsSelector } from './selectors';

const Profile: FC = memo(() => {
  const profile = useAppSelector(getGlobalProfileSelector);
  const isSubscriptionsLoading = useAppSelector(getIsSubscriptionsLoadingSelector);
  const subscriptions = useAppSelector(getProfileSubscriptionsSelector);
  const profileError = useAppSelector(getGlobalProfileErrorSelector);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!profile && !profileError) {
      dispatch(getProfile());
    }
    if (!subscriptions) {
      dispatch(getSubscriptions());
    }
  }, [subscriptions, profileError, profile]);

  const onSubmit = (values: IProfileForm) => {
    dispatch(updateProfile(values)).then(() => dispatch(getProfile()));
  };

  const onPasswordSubmit = (values: IChangePasswordForm) => {
    const { password, currentPassword } = values;
    dispatch(updatePassword({
      oldPassword: currentPassword,
      newPassword: password,
    }));
  };

  const upload = useCallback((file: File) => {
    dispatch(uploadAvatar(file)).then(() => dispatch(getProfile()));
  }, []);
  const deleteImage = useCallback(() => {
    dispatch(deleteAvatar()).then(() => dispatch(getProfile()));
  }, []);

  if ((!profile && !profileError) || isSubscriptionsLoading) return <PageLoader />;

  return (
    <ProfileTabs location={pathname}>
      {pathname === APP_ROUTES.PROFILE_INFO
        ? (
          <Wrapper>
            <Avatar uploadAvatar={upload} deleteAvatar={deleteImage} />
            <ProfileInfoForm
              profile={profile}
              onSubmit={onSubmit}
              profileError={profileError}
              onPasswordSubmit={onPasswordSubmit}
            />
          </Wrapper>
        )
        : (
          <Subscription
            subscriptions={subscriptions || []}
            profile={profile}
          />
        )}
    </ProfileTabs>
  );
});

export default Profile;
