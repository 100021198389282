import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  del,
  get,
  post,
} from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import {
  IAppleSignInRequest,
  ILoginRequest, ILoginResponse, ISocialNetworkSignInRequest,
} from '../types';
import {
  ICurrentUserResponse,
  IErrorResponse,
  IOtpCodeResponse,
  IRefreshTokenRequest,
  IResendCodeRequest,
  ITokenResponse,
} from '../../../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse,
}

export const login = createAsyncThunk<ILoginResponse, ILoginRequest, ICommonChunkConfig>(
  'auth/login',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.LOGIN, data: _data });
      return response as ILoginResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const googleSignIn = createAsyncThunk<
ILoginResponse, ISocialNetworkSignInRequest, ICommonChunkConfig>(
  'auth/googleSignIn',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.GOOGLE_SIGN_IN, data: _data });
      return response as ILoginResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const facebookSignIn = createAsyncThunk<
ILoginResponse, ISocialNetworkSignInRequest, ICommonChunkConfig>(
  'auth/facebookSignIn',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.FACEBOOK_SIGN_IN, data: _data });
      return response as ILoginResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const appleSignIn = createAsyncThunk<
ILoginResponse, IAppleSignInRequest, ICommonChunkConfig>(
  'auth/appleSignIn',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.APPLE_SIGN_IN, data: _data });
      return response as ILoginResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const logout = createAsyncThunk<any, void, ICommonChunkConfig>(
  'auth/logout',
  async (_data, { rejectWithValue }) => {
    try {
      await del({ path: API_ROUTES.LOGOUT });
      return true;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const getAccessToken = createAsyncThunk<
ITokenResponse, IRefreshTokenRequest, ICommonChunkConfig>(
  'auth/refreshAccessToken',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.REFRESH_TOKEN, data: _data });
      return response as ITokenResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const getCurrentUser = createAsyncThunk<
ICurrentUserResponse, void, ICommonChunkConfig>(
  'auth/fetchCurrentUser',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.ME, params: {} });
      return response as ICurrentUserResponse;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const resendCode = createAsyncThunk<IOtpCodeResponse,
IResendCodeRequest,
ICommonChunkConfig>(
  'auth/resendCode',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.RESEND_CODE, data: _data });
      return response as IOtpCodeResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);
