import { useNavigate, useSearchParams } from 'react-router-dom';
import { memo, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../hooks';
import { appleSignIn, getCurrentUser } from '../Login/services';
import { APP_ROUTES } from '../../core/router/appRoutes';

const AppleCallback = memo(() => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userIdentifier = useMemo(() => params.get('userIdentifier'), [params]);
  const errorMessage = useMemo(() => params.get('errorMessage'), [params]);

  useEffect(() => {
    if (userIdentifier) {
      dispatch(appleSignIn({ userIdentifier: `${userIdentifier}` })).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(getCurrentUser());
        }
        if (response.meta.requestStatus === 'rejected') {
          navigate(APP_ROUTES.LOGIN);
        }
      });
    }

    if (errorMessage) {
      toast.error(errorMessage);
      navigate(APP_ROUTES.LOGIN);
    }
  }, [userIdentifier, errorMessage]);

  return null;
});

export default AppleCallback;
