import React, { memo } from 'react';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { ICourse } from '../../../../../types';
import { APP_ROUTES } from '../../../../../core/router/appRoutes';
import sliderMock from '../../../../../images/background-slide-mock.jpg';
import {
  CourseWideCard,
  StyledCardSubTitle,
  StyledCardTitle,
  StyledNew,
  StyledTextContainer,
} from '../../styles';
import { getCourseLevels } from '../../../../../utils/helpers';
import { StyledCardLevel } from '../../../../common/styles';
import { useLoadImage } from '../../../../../utils/hooks/useLoadImage';
import CourseSkeleton from '../../../../loaders/CourseSkeleton';

interface ICourseProps {
  course: ICourse;
  isMobile: boolean;
}

const Course = ({ course, isMobile }: ICourseProps) => {
  const navigate = useNavigate();
  const src = useLoadImage(course.image?.url);

  return (
    src ? (
      <CourseWideCard
        onClick={() => navigate(APP_ROUTES.COURSES_ID.replace(':id', course.id))}
        key={uuid()}
        image={src ?? sliderMock}
      >
        <StyledNew key={uuid()}>
          New
        </StyledNew>
        <StyledTextContainer key={uuid()}>
          <StyledCardLevel key={uuid()}>
            {getCourseLevels(course.level)}
          </StyledCardLevel>
          <StyledCardTitle key={uuid()}>
            {course.name}
          </StyledCardTitle>
          <StyledCardSubTitle key={uuid()}>
            {course.shortDescription}
          </StyledCardSubTitle>
        </StyledTextContainer>
      </CourseWideCard>
    ) : (
      <CourseSkeleton
        isMobile={isMobile}
        webHeight={280}
        webWidth={491}
        mobileHeight={184}
        mobileWidth="100%"
      />
    )
  );
};

export default memo(Course);
