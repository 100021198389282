import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ContactFormComponent } from '../../../containers/Contact/components/Form';
import {
  APP_URL,
  CONTACT_EMAIL,
  INSTAGRAM_URL,
} from '../../../containers/Contact/constants';
import { notifyMe } from '../../../containers/Contact/services';
import { useAppDispatch } from '../../../hooks';
import { IContactForm } from '../../types';

import {
  AppStoreIcon,
  CopyrightBlock,
  FooterBackground,
  FooterBlock,
  FooterContainer,
  FooterEndBlock,
  FormBlock,
  HrLine,
  InfoContainer,
  InfoLink,
  InstagramIcon,
  LinkEmail,
  LinkInstagram,
  LinksContainer,
  TextForForm,
} from './styles';
import { APP_ROUTES } from '../../router/appRoutes';

interface IFooterProps {}

const Footer = memo<IFooterProps>(() => {
  const dispatch = useAppDispatch();
  const onSubmitHandler = useCallback((values: IContactForm) => {
    dispatch(notifyMe(values));
  }, []);

  return (
    <FooterBackground>
      <FooterContainer>
        <FooterBlock>
          <FormBlock>
            <TextForForm>Subscribe to our newsletter</TextForForm>
            <ContactFormComponent footerStyle onSubmit={onSubmitHandler} />
          </FormBlock>

          <LinksContainer>
            <LinkEmail
              to="#"
              onClick={() => {
                window.location.href = `mailto:${CONTACT_EMAIL}`;
              }}
            >
              {CONTACT_EMAIL}
            </LinkEmail>
            <LinkInstagram to={INSTAGRAM_URL}>
              Follow us
              <InstagramIcon />
            </LinkInstagram>
          </LinksContainer>
          <Link to={APP_URL}>
            <AppStoreIcon />
          </Link>
        </FooterBlock>

        <HrLine />
        <FooterEndBlock>
          <InfoContainer>
            <InfoLink to={APP_ROUTES.TERMS_OF_USE}>
              Terms and Conditions
            </InfoLink>
            <InfoLink to={APP_ROUTES.PRIVACY_POLICY}>Privacy Policy</InfoLink>
            {/* <InfoLink to={PAYMENT}>Payment</InfoLink> */}
          </InfoContainer>
          <CopyrightBlock>© Swanly, {new Date().getFullYear()}</CopyrightBlock>
        </FooterEndBlock>
      </FooterContainer>
    </FooterBackground>
  );
});

export default Footer;
