import React, { memo } from 'react';
import { ICourse, ILesson } from '../../../../types';
import { LessonsContainer, LessonsTitle } from '../../styles';
import Lesson from '../Lesson';

const Lessons = memo<{
  course: ICourse,
  isMobile: boolean,
  hasSubscription?: boolean,
  onClick:(id: string) => void,
  onFavoriteClick:(lesson: ILesson) => void,
  onShareClick:(lesson: ILesson) => void,
  isAuth: boolean,
}>((
      {
        isMobile,
        course,
        hasSubscription,
        onClick,
        onFavoriteClick,
        onShareClick,
        isAuth,
      },
    ) => (
      <LessonsContainer>
        {!isMobile && (
        <LessonsTitle>
          Classes
        </LessonsTitle>
        ) }
        {
      course.lessons.map((lesson, index) => (
        <Lesson
          isAuth={isAuth}
          onFavoriteClick={onFavoriteClick}
          onShareClick={onShareClick}
          onClick={onClick}
          isAvailable={hasSubscription}
          isFirst={hasSubscription ? false : index === 0}
          lesson={lesson}
          isMobile={isMobile}
        />
      ))
    }
      </LessonsContainer>
    ));

export default Lessons;
