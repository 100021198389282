import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  post,
} from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import {
  IErrorResponse, IOtpCodeResponse, IVerificationCodeRequest,
} from '../../../types';
import {
  IResetPasswordRequest, ISetPasswordRequest,
} from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse,
}

export const resetPassword = createAsyncThunk<
IOtpCodeResponse,
IResetPasswordRequest,
ICommonChunkConfig>(
  'resetPassword/resetPassword',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.RESET_PASSWORD, data: _data });
      return response as IOtpCodeResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const resetVerify = createAsyncThunk<IOtpCodeResponse,
IVerificationCodeRequest,
ICommonChunkConfig>(
  'resetPassword/resetVerify',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.RESET_VERIFY, data: _data });
      return response as IOtpCodeResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const setPassword = createAsyncThunk<void,
ISetPasswordRequest,
ICommonChunkConfig>(
  'resetPassword/setPassword',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.SET_PASSWORD, data: _data });
      return response as void;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);
