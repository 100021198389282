import { createSlice } from '@reduxjs/toolkit';
import {
  getErrorMessage,
} from '../../../utils/helpers';
import {
  resetPassword,
  resetVerify, setPassword,
} from '../services';

export interface IResetPasswordState {
  isLoading: boolean
  resetPasswordError: string | null
  resetVerifyError: string | null
  setPasswordError: string | null
}

const initialState: IResetPasswordState = {
  isLoading: false,
  resetPasswordError: null,
  resetVerifyError: null,
  setPasswordError: null,
};

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      resetPassword.fulfilled,
      (state) => {
        state.isLoading = false;
        state.resetPasswordError = initialState.resetPasswordError;
      },
    );
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.resetPasswordError = getErrorMessage(payload);
    });

    builder.addCase(resetVerify.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      resetVerify.fulfilled,
      (state) => {
        state.isLoading = false;
        state.resetVerifyError = initialState.resetVerifyError;
      },
    );
    builder.addCase(resetVerify.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.resetVerifyError = getErrorMessage(payload);
    });

    builder.addCase(setPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      setPassword.fulfilled,
      (state) => {
        state.isLoading = false;
        state.setPasswordError = initialState.setPasswordError;
      },
    );
    builder.addCase(setPassword.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.setPasswordError = getErrorMessage(payload);
    });
  },
});

export default resetPasswordSlice.reducer;
