import React, { ButtonHTMLAttributes, JSX, memo } from 'react';
import { StyledButtonLoader, StyledIconButton } from './styles';

interface IIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean
  icon: JSX.Element
}

export const IconButton = memo<IIconButtonProps>(({
  isLoading, children, icon, ...rest
}) => (
  <StyledIconButton {...rest}>
    { isLoading && <StyledButtonLoader />}
    { !isLoading && icon}
    {children}
  </StyledIconButton>
));
