import React from 'react';
import {
  BoldText,
  Box,
  Container,
  Subtitle,
  CodeInput,
} from './styles';
import defaultAvatar from '../../../../images/avatar-default.png';
import {
  Avatar, DefaultAvatarBox, PlusBox, PlusIcon,
} from '../../containers/Friends/style';

interface IAddFriendsProps {
  value: string;
  regenerateCode: () => void;
}

const AddFriends = ({ value, regenerateCode }: IAddFriendsProps) => (
  <Container>
    <Box>
      <DefaultAvatarBox>
        <PlusBox>
          <PlusIcon />
        </PlusBox>
        <Avatar src={defaultAvatar} alt="" />
      </DefaultAvatarBox>
      <Subtitle>To add friends to your subscription, copy this code</Subtitle>
      <CodeInput value={value} readOnly />
    </Box>
    <BoldText onClick={regenerateCode}>Regenerate code</BoldText>
  </Container>
);

export default AddFriends;
