import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILoginResponse } from '../../containers/Login/types';
import {
  ICommonChunkConfig,
  ICoursesResponse,
  IErrorResponse,
  IListRequest,
  IProfileResponse,
  IUpdateProfileRequest,
  IVerificationCodeRequest,
} from '../../types';
import {
  del, get, post, put,
} from '../axios';
import { API_ROUTES } from '../router/apiRoutes';
import { IUploadResponse } from '../../containers/Profile/types';

export const signVerify = createAsyncThunk<ILoginResponse,
IVerificationCodeRequest,
ICommonChunkConfig>(
  'verify/signVerify',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.SIGN_VERIFY, data: _data });
      return response as ILoginResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const fetchCourses = createAsyncThunk<
ICoursesResponse,
IListRequest,
ICommonChunkConfig>(
  'courses/fetchCourses',
  async (_data = { page: 1, pageSize: 10 }, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.COURSES, params: _data });
      return response as ICoursesResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const getProfile = createAsyncThunk<
IProfileResponse, void, ICommonChunkConfig>(
  'profile/fetchProfile',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.PROFILE, params: {} });
      return response as IProfileResponse;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const updateProfile = createAsyncThunk<
IProfileResponse, IUpdateProfileRequest, ICommonChunkConfig>(
  'profile/updateProfile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await put({ path: API_ROUTES.PROFILE, data });
      return response as IProfileResponse;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const uploadAvatar = createAsyncThunk<
IUploadResponse, File, ICommonChunkConfig>(
  'profile/uploadAvatar',
  async (data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.PROFILE_AVATAR, data: { file: data } }, 'multipart/form-data');
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const deleteAvatar = createAsyncThunk<
void, void, ICommonChunkConfig>(
  'profile/deleteAvatar',
  async (_data, { rejectWithValue }) => {
    try {
      return await del({ path: API_ROUTES.PROFILE_AVATAR });
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);
