import React, { memo } from 'react';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import {
  ModalContainer,
  AddAvatarIcon,
  TextContainer,
  PopperSubstitle,
  StyledCodeInput,
  SendBtn,
  ModalTitle,
} from '../../styles';

interface ISendCodeFormProps {
  isLoading: boolean;
  handleSubmit: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  code: string;
}

const SendCodeForm = memo(({
  code,
  handleChange,
  isLoading,
  handleSubmit,
}: ISendCodeFormProps) => (
  <ModalContainer>
    <AddAvatarIcon />
    <TextContainer>
      <ModalTitle>ENTER YOUR INVITATION CODE</ModalTitle>
      <PopperSubstitle>And get access to Swanly Premium</PopperSubstitle>
    </TextContainer>
    <StyledCodeInput value={code} onChange={handleChange} />
    <SendBtn isLoading={isLoading} type="submit" disabled={!code} onClick={handleSubmit}>
      <StyledDefaultButtonTitle>
        Send
      </StyledDefaultButtonTitle>
    </SendBtn>
  </ModalContainer>
));

export default SendCodeForm;
