import React, { memo, useRef } from 'react';
import LoginForm from '../LoginForm';
import GoogleSignIn from '../GoogleSignIn';
import FacebookSignIn from '../FacebookSignIn';
import AppleSignIn from '../AppleSignIn';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { ILoginRequest } from '../../types';
import AuthWallpaper from '../../../../components/wallpapers/auth';
import {
  QuestionComponentWrapper,
  QuestionStyledSpan,
  StyledContentContainer,
  StyledLogoDesktopIcon,
  StyledLogoMobileIcon,
  StyledOr,
  StyledSignUp,
  StyledSocialNetworkButtonContainer,
  StyledSocialNetworkContainer,
  StyledTitle,
} from '../../../../components/wallpapers/auth/styles';
import { IVerificationCodeRequest } from '../../../../types';
import VerificationCodeComponent from '../../../../components/wallpapers/otp';
import { getStorageValue } from '../../../../utils/helpers';
import { REDIRECT_URL_NAME } from '../../../../utils/constants';

interface ILoginProps {
  onSubmit: (values: ILoginRequest) => void
  onGoogleSignInResponse: (token: string) => void
  onFacebookSignInResponse: (token: string) => void
  loginError?: string | null
  verifyError?: string | null
  isCodePage: boolean
  onResendCode: () => void
  backButtonVerificationClick: () => void
  onVerificationSubmit: (values: IVerificationCodeRequest) => void
  isMobile: boolean
  isLoading: boolean
  email: string
}
const LoginWrapper = memo<ILoginProps>((
  {
    onSubmit,
    onGoogleSignInResponse,
    onFacebookSignInResponse,
    loginError,
    verifyError,
    isMobile,
    isLoading,
    isCodePage,
    email,
    onVerificationSubmit,
    onResendCode,
    backButtonVerificationClick,

  },
) => {
  const buttonRef = useRef<any>();
  const handleOnResendCode = () => {
    onResendCode();
    if (buttonRef?.current) {
      buttonRef.current.dispatchEvent(new Event('click', { cancelable: true, bubbles: true }));
    }
  };
  const backButtonRoute = getStorageValue(REDIRECT_URL_NAME) || APP_ROUTES.MAIN;

  return (
    <AuthWallpaper
      isMobile={isMobile}
      backButtonRoute={backButtonRoute}
    >
      <StyledContentContainer>
        {isMobile ? (
          <StyledLogoMobileIcon />
        ) : (
          <StyledLogoDesktopIcon />
        )}
        {
          isCodePage ? (
            <VerificationCodeComponent
              email={email}
              isLoading={isLoading}
              errorMessage={verifyError}
              ref={buttonRef}
              onVerificationSubmit={onVerificationSubmit}
              handleOnResendCode={handleOnResendCode}
              backButtonClick={backButtonVerificationClick}
            />
          ) : (
            <>
              <StyledSocialNetworkContainer>
                <StyledTitle>
                  Sign In
                  {' '}
                  <span>with</span>
                </StyledTitle>
                <StyledSocialNetworkButtonContainer>
                  <AppleSignIn />
                  <GoogleSignIn onGoogleSignInResponse={onGoogleSignInResponse} />
                  <FacebookSignIn onFacebookSignInResponse={onFacebookSignInResponse} />
                </StyledSocialNetworkButtonContainer>
              </StyledSocialNetworkContainer>
              <StyledOr>
                <span>or</span>
              </StyledOr>
              <LoginForm
                isLoading={isLoading}
                onSubmit={onSubmit}
                loginError={loginError}
              />
              <QuestionComponentWrapper>
                <QuestionStyledSpan>
                  {'Don\'t have an account yet?'}
                </QuestionStyledSpan>
                <StyledSignUp to={APP_ROUTES.REGISTER}>
                  Sign Up
                </StyledSignUp>
              </QuestionComponentWrapper>
            </>
          )
        }
      </StyledContentContainer>
    </AuthWallpaper>
  );
});

export default LoginWrapper;
