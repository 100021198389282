import { createAsyncThunk } from '@reduxjs/toolkit';
import { del, get, post } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { ICourse, IErrorResponse, ILesson } from '../../../types';
import { ISetFavoriteRequest, ISetFavoriteResponse, ISetProgressRequest } from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse,
}

export const removeFavorite = createAsyncThunk<
ISetFavoriteResponse,
ISetFavoriteRequest,
ICommonChunkConfig
>(
  'course/removeFavorite',
  async (_data, { rejectWithValue }) => {
    try {
      return await del({ path: API_ROUTES.FAVORITE, data: _data });
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const setFavorite = createAsyncThunk<
ISetFavoriteResponse,
ISetFavoriteRequest,
ICommonChunkConfig
>(
  'course/setFavorite',
  async (_data, { rejectWithValue }) => {
    try {
      return await post({ path: API_ROUTES.FAVORITE, data: _data });
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const setProgress = createAsyncThunk<void, ISetProgressRequest, ICommonChunkConfig>(
  'course/setProgress',
  async (_data, { rejectWithValue }) => {
    try {
      return await post({ path: API_ROUTES.PROGRESS, data: _data });
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);

export const getCourse = createAsyncThunk<
ICourse, string, ICommonChunkConfig>(
  'course/fetchCourse',
  async (id, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.COURSES_ID.replace(':id', id), params: {} });
      return response as ICourse;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const getLesson = createAsyncThunk<
ILesson, string, ICommonChunkConfig>(
  'course/fetchLesson',
  async (id, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.LESSONS_ID.replace(':id', id), params: {} });
      return response as ILesson;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);
