import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISubscriptionMapping } from '../../../Profile/types';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getIsInvitationLoadingSelector, getIsInvitationSuccessfullSelector } from '../../selectors';
import { createFriendSubscription } from '../../services';
import FriendsCard from '../FriendsCard';
import CustomModal from '../../../../components/modals/CustomModal';
import SendCodeForm from '../SendCodeForm';
import { getCurrentUserSelector } from '../../../../core/selectors';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { setIsInvitationSuccessfull } from '../../slices';
import ThankYou from '../ThankYou';
import { getProfile } from '../../../../core/services';
import { getSubscriptions } from '../../../Profile/services';
import { setStorageValue } from '../../../../utils/helpers';
import { REDIRECT_URL_NAME } from '../../../../utils/constants';

interface IFriendsCardWrapperProps {
  subscription: ISubscriptionMapping;
  description: string;
}

const FriendsCardWrapper = memo(({ subscription, description }: IFriendsCardWrapperProps) => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [code, setCode] = useState('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(getIsInvitationLoadingSelector);
  const currentUser = useAppSelector(getCurrentUserSelector);
  const isSuccess = useAppSelector(getIsInvitationSuccessfullSelector);

  const handleOpen = () => {
    if (currentUser) {
      setOpen(true);
    } else {
      setStorageValue(REDIRECT_URL_NAME, APP_ROUTES.SUBSCRIPTION);
      navigate(APP_ROUTES.LOGIN);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setCode('');
  };

  const closeThankYou = () => setIsOpen(false);
  const openThankYou = () => setIsOpen(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };
  const handleSendCode = () => {
    dispatch(createFriendSubscription({ code }));
  };
  const handleClick = () => {
    closeThankYou();
    navigate(APP_ROUTES.PROFILE_SUBSCRIPTION);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      openThankYou();
      dispatch(getProfile());
      dispatch(getSubscriptions());
    } else {
      setCode('');
    }
    dispatch(setIsInvitationSuccessfull(null));
  }, [isSuccess]);

  return (
    <>
      <FriendsCard
        handleOpen={handleOpen}
        subscription={subscription}
        description={description}
      />
      <CustomModal
        isOpen={open}
        onClose={handleClose}
        isPrimaryTheme={false}
        modalWidth={489}
      >
        <SendCodeForm
          isLoading={isLoading}
          handleChange={handleChange}
          handleSubmit={handleSendCode}
          code={code}
        />
      </CustomModal>
      <CustomModal
        isOpen={isOpen}
        onClose={closeThankYou}
        isPrimaryTheme={false}
        modalWidth={489}
      >
        <ThankYou
          onClick={handleClick}
        />
      </CustomModal>
    </>
  );
});

export default FriendsCardWrapper;
