import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import { ICourse, ILesson } from '../../../../types';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../../../utils/constants';
import PartCourses from '../../../../components/sliders/PartCourses';
import {
  LessonPlayerCaptionContainer, LessonPlayerCaptionCourseName, LessonPlayerCaptionLessonName,
  PageContainer,
} from '../../styles';
import CourseDescription from '../CourseDescription';
import CourseMainBanner from '../CourseMainBanner';
import Lessons from '../Lessons';
import Trainer from '../Trainer';
import Player from '../Player';

interface IPageWrapperProps {
  courses: ICourse[] | null
  currentCourse: ICourse
  hasSubscription?: boolean
  onLessonClick: (id: string) => void
  onClosePlayerClick: () => void
  currentLesson: ILesson | null
  isCurrentLessonLoading: boolean
  onFavoriteClick:(lesson: ILesson) => void,
  onShareClick:(lesson: ILesson) => void,
  onProgress: (entityId: string) => void
  isAuth: boolean
}

const PageWrapper = memo<
IPageWrapperProps>((
  {
    courses,
    currentCourse,
    hasSubscription,
    onLessonClick,
    onFavoriteClick,
    onShareClick,
    isAuth,
    currentLesson,
    onClosePlayerClick,
    onProgress,
  },
) => {
  const [isTrainerOpen, trainerOpen] = useState<boolean>(false);
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.mobile, [width]);
  useEffect(() => {
    if (currentLesson) {
      trainerOpen(false);
    }
  }, [currentLesson]);
  return (
    <>
      <PageContainer>
        <CourseMainBanner
          course={currentCourse}
          isMobile={isMobile}
          hasSubscription={hasSubscription}
        />
        <CourseDescription
          hasSubscription={hasSubscription}
          course={currentCourse}
          onTrainerClick={() => trainerOpen(true)}
        />
        <Lessons
          isAuth={isAuth}
          onShareClick={onShareClick}
          onFavoriteClick={onFavoriteClick}
          onClick={onLessonClick}
          isMobile={isMobile}
          course={currentCourse}
          hasSubscription={hasSubscription}
        />
        <PartCourses
          title="Most popular"
          courses={courses?.filter((course) => course.isPopular).slice(0, 3)}
          isMobile={isMobile}
        />
      </PageContainer>
      {
        isTrainerOpen && (
          <Trainer
            trainer={currentCourse.trainer}
            onCloseClick={() => trainerOpen(false)}
          />
        )
      }
      {
        currentLesson?.video.url && (
          <Player
            onProgress={onProgress}
            onCloseClick={onClosePlayerClick}
            video={currentLesson.video}
            entityId={currentLesson.id}
            title={(
              <LessonPlayerCaptionContainer>
                <LessonPlayerCaptionCourseName>{currentCourse.name}</LessonPlayerCaptionCourseName>
                <LessonPlayerCaptionLessonName>{currentLesson.name}</LessonPlayerCaptionLessonName>
              </LessonPlayerCaptionContainer>
)}
          />
        )
      }

    </>
  );
});

export default PageWrapper;
