import styled from 'styled-components';
import { IImageProps } from '../../../../../core/types';

export const TrainerContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.black100};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 3;
  padding: 32px 24px; 
  padding-top: 80px;
  overflow-y: scroll;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    right: 0;
    width: 50vw;
    padding: 124px 80px 64px 48px;
  }
`;

export const TrainerTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 40px;
  }
`;

export const TrainerTitle = styled.span`
  font: ${({ theme }) => theme.typography.h5_mobile_new.font};
  color: ${({ theme }) => theme.palette.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.h5.font};
  }
`;

export const TrainerContentContainer = styled.div`
  display: flex;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 40px;
  }
`;

export const TrainerAvatar = styled.div<IImageProps>`
  background-image: url(${((props) => props.image)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 200px;
    height: 200px;
  }
`;

export const TrainerName = styled(TrainerTitle)`
  text-transform: uppercase;
  display: flex;
  margin-bottom: 8px;
`;

export const TrainerPosition = styled.span`
  font: ${({ theme }) => theme.typography.body3_mobile.font};
  color: ${({ theme }) => theme.palette.black25};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.body3_new.font};
  }
`;

export const TrainerDescription = styled.div`
  font: ${({ theme }) => theme.typography.body2_mobile.font};
  color: ${({ theme }) => theme.palette.black25};
  ol, li {
    list-style-position: inside;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.body3_new.font};
  }
`;
