import React, { memo } from 'react';
import { Accordion } from '@szhsin/react-accordion';
import uuid from 'react-uuid';
import {
  AccordionContainer,
  PageContainer, StyledAccordionHeader, StyledAccordionItem, StyledClosedIcon, StyledTextContainer,
  Title,
} from './styles';

const faq = [
  {
    question: 'What is Swanly and how does it work?',
    answer: 'Swanly is a mental health app designed specifically for women, blending the principles of ballet and stretching. Through our guided sessions and personalized pathways, we aim to transform daily stress into balance and well-being.',
  },
  {
    question: 'Who can use Swanly?',
    answer: 'While Swanly is tailored for women seeking a holistic approach to mental health, anyone interested in weaving dance and stretching into their routine can benefit from our app.',
  },
  {
    question: 'Do I need prior ballet or dance experience?',
    answer: 'Absolutely not! Swanly is designed for all levels, from beginners to those with prior experience. Our guided sessions will take you step by step, ensuring you\'re comfortable and confident in each movement.',
  },
  {
    question: 'How often should I use Swanly for the best results?',
    answer: 'We recommend engaging with the app 2-3 times a week. However, the beauty of Swanly is its flexibility—adjust your practice to what suits your schedule and comfort level.',
  },
  {
    question: 'How soon can I see results with Swanly?',
    answer: 'While every individual\'s journey is unique, many users report feeling a positive shift in their well-being and body awareness after just a few sessions. Consistency is key to experiencing the full benefits of Swanly.',
  },
  {
    question: 'How much does Swanly cost?',
    answer: 'While we offer some free content to give users a taste of what Swanly has to offer, we have various subscription plans available to unlock the full experience. Please visit our "Pricing" page for more details.',
  },
];

const Home8 = memo(() => (
  <PageContainer>
    <Title>
      FAQ
    </Title>
    <AccordionContainer>
      <Accordion
        allowMultiple
      >
        {
          faq.map((item) => (
            <StyledAccordionItem
              key={uuid()}
              header={(
                <StyledAccordionHeader key={uuid()}>
                  {item.question}
                  <StyledClosedIcon className="chevron" />
                </StyledAccordionHeader>
              )}
            >
              <StyledTextContainer key={uuid()}>
                {item.answer}
              </StyledTextContainer>
            </StyledAccordionItem>
          ))
        }
      </Accordion>
    </AccordionContainer>
  </PageContainer>
));

export default Home8;
