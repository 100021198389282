import React, { FC, memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../../containers/Login';
import { APP_ROUTES } from './appRoutes';
import { UnregisteredUserGuard, RegisteredUserGuard } from '../guards';
import BaseLayout from '../layouts/BaseLayout';
import Contact from '../../containers/Contact';
import AppleCallback from '../../containers/AppleCallback';
import Register from '../../containers/Register';
import ResetPassword from '../../containers/ResetPassword';
import MainLayout from '../layouts/MainLayout';
import Home from '../../containers/Home';
import Courses from '../../containers/Courses';
import Course from '../../containers/Course';
import Profile from '../../containers/Profile';
import Subscription from '../../containers/Subscription';
import { IRouterProps } from '../types';
import PrivacyPolicy from '../../containers/PrivacyPolicy AndTerms/PrivacyPolicy';
import TermsOfUse from '../../containers/PrivacyPolicy AndTerms/TermsOfUse';

const Router: FC<IRouterProps> = memo(({ isReleased }) => (
  <BaseLayout>
    <Routes>
      <Route
        path={APP_ROUTES.LOGIN}
        element={(
          <UnregisteredUserGuard>
            <Login />
          </UnregisteredUserGuard>
        )}
      />
      <Route
        path={APP_ROUTES.REGISTER}
        element={(
          <UnregisteredUserGuard>
            <Register />
          </UnregisteredUserGuard>
        )}
      />
      <Route
        path={APP_ROUTES.RESET_PASSWORD}
        element={(
          <UnregisteredUserGuard>
            <ResetPassword />
          </UnregisteredUserGuard>
        )}
      />
      <Route
        path={APP_ROUTES.APPLE_CALLBACK}
        element={(
          <UnregisteredUserGuard>
            <AppleCallback />
          </UnregisteredUserGuard>
        )}
      />
      <Route path={APP_ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />
      <Route path={APP_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={APP_ROUTES.CONTACT} element={<Contact />} />
      <Route
        path={APP_ROUTES.MAIN}
        element={
          !isReleased ? (
            <Contact />
          ) : (
            <MainLayout>
              <Home />
            </MainLayout>
          )
        }
      />
      <Route
        path={APP_ROUTES.COURSES}
        element={(
          <MainLayout>
            <Courses />
          </MainLayout>
        )}
      />
      <Route
        path={APP_ROUTES.COURSES_ID}
        element={(
          <MainLayout>
            <Course />
          </MainLayout>
        )}
      />
      <Route
        path={APP_ROUTES.PROFILE_INFO}
        element={(
          <RegisteredUserGuard>
            <MainLayout>
              <Profile />
            </MainLayout>
          </RegisteredUserGuard>
        )}
      />
      <Route
        path={APP_ROUTES.PROFILE_SUBSCRIPTION}
        element={(
          <RegisteredUserGuard>
            <MainLayout>
              <Profile />
            </MainLayout>
          </RegisteredUserGuard>
        )}
      />
      <Route
        path={APP_ROUTES.SUBSCRIPTION}
        element={(
          <MainLayout>
            <Subscription />
          </MainLayout>
        )}
      />
    </Routes>
  </BaseLayout>
));

export default Router;
