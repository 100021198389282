import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import 'swiper/css';
import Home1 from './components/Home1';
import Home2 from './components/Home2';
import Home3 from './components/Home3';
import Home4 from './components/Home4';
import Home5 from './components/Home5';
import Home6 from './components/Home6';
import Home7 from './components/Home7';
import Home8 from './components/Home8';
import { StyledBlackPageWrapper } from './styles';
import useMouseScroll from '../../utils/hooks/useMouseScroll';

const Home = memo(() => {
  const [isMoved, moved] = useState<boolean>(false);

  useMouseScroll(() => {
    moved(true);
  });

  const home2Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMoved && home2Ref && home2Ref.current) {
      window.scrollTo(0, home2Ref.current.offsetTop);
    }
  }, [home2Ref, isMoved]);

  return (
    <>
      <Home1 />
      {
      isMoved && (
        <>
          <Home2 ref={home2Ref} />
          <Home3 />
          <Home4 />
          <Home5 />
          <Home6 />
          <StyledBlackPageWrapper>
            <Home7 />
            <Home8 />
          </StyledBlackPageWrapper>
        </>
      )
    }
    </>
  );
});

export default Home;
