import React, { memo } from 'react';
import { MenuLinkMobile } from '../../styles';
import { StyledMobileMenuSignInButton } from '../styles';

interface MobileMenuLinkProps {
  link: string;
  label: string;
}

const MobileMenuLink = memo<MobileMenuLinkProps>(({ link, label }) => (
  <MenuLinkMobile
    to={link}
  >
    <StyledMobileMenuSignInButton>{label}</StyledMobileMenuSignInButton>

  </MenuLinkMobile>
));

export default MobileMenuLink;
