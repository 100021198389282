import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { loginSchema } from '../../../../utils/validation/schemas';
import CustomPasswordInput from '../../../../components/inputs/CustomPasswordInput';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import { ILoginRequest } from '../../types';
import {
  CustomEmailStyledInput, ForgotPassword, PasswordInputWrapper,
  StyledErrorFormMessage, StyledForm, StyledWarningPoint, SubmitStyledButton,
} from '../../../../components/wallpapers/auth/styles';

interface ILoginFormComponentProps {
  onSubmit: (data: ILoginRequest) => void
  loginError?: string | null
  isLoading: boolean
}

const LoginForm: FC<ILoginFormComponentProps> = ({
  onSubmit, loginError, isLoading,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<ILoginRequest>({
    resolver: joiResolver(loginSchema),
    mode: 'onBlur',
  });

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
    >
      <CustomEmailStyledInput
        {...register('email')}
        label="Email"
        placeholder="Enter email"
        {...(errors.email?.message ? { error: errors?.email?.message } : {})}
        inputMode="email"
        {...(errors.email?.message ? { icon: <StyledWarningPoint /> } : {})}
      />

      <PasswordInputWrapper>
        <CustomPasswordInput
          label="Password"
          placeholder="Enter password"
          {...register('password')}
          error={errors.password?.message ?? errors?.password?.message}
        />
        <ForgotPassword
          to={APP_ROUTES.RESET_PASSWORD}
        >
          Forgot password?
        </ForgotPassword>
      </PasswordInputWrapper>
      {loginError && (<StyledErrorFormMessage>{loginError}</StyledErrorFormMessage>)}
      <SubmitStyledButton
        disabled={!isValid}
        type="submit"
        isLoading={isLoading}
      >
        <StyledDefaultButtonTitle>
          Sign In
        </StyledDefaultButtonTitle>
      </SubmitStyledButton>
    </StyledForm>
  );
};

export default LoginForm;
