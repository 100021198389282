import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetPremiumHeaderButton } from '../../../../components/buttons/DefaultButton/styles';
import { IProfileResponse } from '../../../../types';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { MenuItemSignInLink, MenuItemSignInText } from '../constants';
import { ProfileCardComponent } from '../ProfileCard';
import { EmptyProfileLogoWeb, ProfilePhoto } from '../ProfileCard/styles';
import { ProfileCardColors } from '../ProfileCard/types';
import {
  HeaderButtonsContainer, MenuLink, StyledHeaderSignInButton, StyledLogoInHeader,
} from '../styles';
import WebMenu from '../WebMenu';
import { PreviewProfile, SubscribeStatusIcon } from './styles';
import { APP_ROUTES } from '../../../router/appRoutes';

interface HeaderWebProps {
  userEnabled: boolean;
  profile: IProfileResponse | null;
}

export const HeaderWeb = memo<HeaderWebProps>(({ userEnabled, profile }) => {
  const [openCard, setOpenCard] = useState(false);
  const [width] = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  const showCard = () => {
    setOpenCard(!openCard);
  };

  const isSubscribed = useMemo(() => (
    profile?.isFriend || profile?.isSubscribed
  ), [profile]);

  const handleClickOutside = (event: MouseEvent) => {
    const specifiedElement = document.getElementById('popover');
    if (event.target && specifiedElement && openCard) {
      const isClickInside = specifiedElement.contains(event.target as Node);
      if (!isClickInside) {
        setOpenCard(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openCard]);

  useEffect(() => {
    if (openCard) {
      setOpenCard(false);
    }
  }, [width, location]);

  return (
    <>
      <WebMenu />
      <HeaderButtonsContainer>
        {!userEnabled ? (
          <MenuLink to={MenuItemSignInLink}>
            <StyledHeaderSignInButton>{MenuItemSignInText}</StyledHeaderSignInButton>
          </MenuLink>
        ) : (
          <div id="popover">
            <StyledLogoInHeader onClick={() => showCard()}>
              {profile?.avatar?.url ? (
                <ProfilePhoto src={profile.avatar.url} />
              ) : (
                <EmptyProfileLogoWeb />
              )}
              {profile && (
              <PreviewProfile $display={openCard}>
                <ProfileCardComponent
                  theme={ProfileCardColors.WHITE}
                  profile={profile}
                />
              </PreviewProfile>
              )}
              {isSubscribed && <SubscribeStatusIcon />}
            </StyledLogoInHeader>
          </div>
        )}
        {!isSubscribed && (
        <GetPremiumHeaderButton
          type="submit"
          onClick={() => navigate(APP_ROUTES.SUBSCRIPTION)}
        >
          Get Premium
        </GetPremiumHeaderButton>
        )}
      </HeaderButtonsContainer>
    </>
  );
});
