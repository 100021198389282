import { createSlice } from '@reduxjs/toolkit';
import { notifyMe } from '../services';
import { ERROR_MESSAGES_MAPPING } from '../../../utils/constants';

export interface IContactState {
  isLoading: boolean
  isSubmitSuccessful: boolean
}

const initialState: IContactState = {
  isLoading: false,
  isSubmitSuccessful: false,
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notifyMe.pending, (state) => {
      state.isLoading = true;
      state.isSubmitSuccessful = false;
    });
    builder.addCase(
      notifyMe.fulfilled,
      (state) => {
        state.isLoading = false;
        state.isSubmitSuccessful = true;
      },
    );
    builder.addCase(notifyMe.rejected, (state, { payload }) => {
      state.isLoading = false;
      if (payload?.message === ERROR_MESSAGES_MAPPING.EMAIL_EXIST) {
        state.isSubmitSuccessful = true;
      } else {
        state.isSubmitSuccessful = false;
      }
    });
  },
});

// export const { } = authSlice.actions;
export default contactSlice.reducer;
