import React, { memo, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import {
  ButtonTitle,
  PageContainer, StyledCardLevel, StyledCardSubTitle, StyledCardTitle, StyledDivider,
  StyledLink,
  StyledLinkContainer,
  StyledNavButton,
  StyledNavigateButtonsContainer, StyledNew, StyledNewContainer,
  StyledNextIcon,
  StyledPrevIcon,
  StyledSlide,
  StyledSliderContainer, StyledTextContainer,
  SubTitle,
  TextContentContainer,
  Title,
} from '../styles';
import { ICourse } from '../../../../../types';
import { APP_ROUTES } from '../../../../../core/router/appRoutes';
import sliderMock from '../../../../../images/background-slide-mock.jpg';

interface IPageWrapperProps {
  courses: ICourse[] | null
  isMobile: boolean
}

const PageWrapper = memo<IPageWrapperProps>(({ courses, isMobile }) => {
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  return (
    <>
      <PageContainer>
        <TextContentContainer>
          <Title>
            Blending ballet&apos;s grace with holistic wellness for transformative self-discovery
          </Title>
          <SubTitle>
            Swanly courses are designed for everyone,
            integrating expert ballet knowledge to make lessons accessible at every level.
            Dive in, experience ease in movement,
            and embrace your own grace with each empowering session.
          </SubTitle>
        </TextContentContainer>
        <StyledSliderContainer>
          <Swiper
            modules={[Autoplay, Navigation]}
            spaceBetween={isMobile ? 8 : 24}
            slidesPerView={isMobile ? 1.3 : 2}
            centeredSlides
            navigation={{
              enabled: !isMobile,
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            loop
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
              loop: true,
            }}
            freeMode
            speed={2000}
          >
            {
              courses?.map((course) => (
                <SwiperSlide key={course.id}>
                  <StyledSlide
                    onClick={() => navigate(APP_ROUTES.COURSES_ID.replace(':id', course.id))}
                    key={course.name}
                    image={course.image?.url ?? sliderMock}
                  >
                    <StyledNewContainer key={uuid()}>
                      <StyledNew key={uuid()}>
                        NEW
                      </StyledNew>
                    </StyledNewContainer>
                    <StyledTextContainer key={uuid()}>
                      <StyledCardLevel key={uuid()}>{course.level?.join(', ').replaceAll('all', 'all levels')}</StyledCardLevel>
                      <StyledCardTitle key={uuid()}>{course.name}</StyledCardTitle>
                      <StyledCardSubTitle
                        key={uuid()}
                      >
                        {course.shortDescription}
                      </StyledCardSubTitle>
                    </StyledTextContainer>
                  </StyledSlide>
                </SwiperSlide>
              ))
            }
          </Swiper>
          <StyledNavigateButtonsContainer>
            <StyledNavButton ref={prevRef}>
              {!isMobile && <StyledPrevIcon />}
            </StyledNavButton>
            <StyledNavButton ref={nextRef}>
              {!isMobile && <StyledNextIcon />}
            </StyledNavButton>

          </StyledNavigateButtonsContainer>
        </StyledSliderContainer>
        <StyledLinkContainer>
          <StyledLink to={APP_ROUTES.COURSES}>
            <ButtonTitle>
              Explore All Courses
            </ButtonTitle>
          </StyledLink>
        </StyledLinkContainer>
      </PageContainer>
      <StyledDivider />
    </>
  );
});

export default PageWrapper;
