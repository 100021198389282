import React from 'react';
import {
  CardWrapper, ConfirmationIcon, ConfirmationText, TextWrapper,
} from '../../styles';

const ConfirmationModal = () => (
  <CardWrapper>
    <ConfirmationIcon />
    <TextWrapper>
      <ConfirmationText>Thank you for joining!</ConfirmationText>
      <ConfirmationText>You’ve successfully subscribed to Swanly’s updates!</ConfirmationText>
    </TextWrapper>
  </CardWrapper>
);

export default ConfirmationModal;
