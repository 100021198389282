import React, { memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import banner from '../../../../images/banner.jpg';
import bannerMobile from '../../../../images/banner-mobile.jpg';
import {
  BannerBox,
  PremiumBadgeWhite,
  StyledBanner,
  StyledLogoWhite,
  Box,
  ContentBox,
  UpgradeBtn,
  BodyTextWhite,
} from '../../styles';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { BREAKPOINTS } from '../../../../utils/constants';
import useWindowSize from '../../../../utils/hooks/useWindowSize';

const Card = memo(() => {
  const navigate = useNavigate();
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.desktop, [width]);
  return (
    <StyledBanner>
      <BannerBox>
        <img src={isMobile ? bannerMobile : banner} alt="" />
        <ContentBox>
          <Box>
            <StyledLogoWhite />
            <PremiumBadgeWhite>Premium</PremiumBadgeWhite>
          </Box>
          <span>
            <BodyTextWhite>Discover the Full Premium Experience</BodyTextWhite>
            <BodyTextWhite>– Upgrade Now!</BodyTextWhite>
          </span>
          <UpgradeBtn isLoading={false} onClick={() => navigate(APP_ROUTES.SUBSCRIPTION)}>
            <StyledDefaultButtonTitle>
              Upgrage
            </StyledDefaultButtonTitle>
          </UpgradeBtn>
        </ContentBox>
      </BannerBox>
    </StyledBanner>
  );
});

export default Card;
