import React, { forwardRef, memo } from 'react';
import {
  QuestionComponentWrapper,
  QuestionStyledSpan, StyledSignUp,
  StyledSocialNetworkContainer,
  StyledSubTitle, StyledTextButton,
  StyledTitle,
} from '../auth/styles';
import VerificationCodeForm from '../../forms/VerificationCodeForm';
import CountDownButton from '../../buttons/CountDownButton';
import { APP_ROUTES } from '../../../core/router/appRoutes';
import { IVerificationCodeRequest } from '../../../types';

interface IVerificationCodeComponentProps {
  email: string;
  isLoading: boolean;
  onVerificationSubmit: (values: IVerificationCodeRequest) => void;
  handleOnResendCode: () => void;
  errorMessage?: string | null;
  backButtonClick?: () => void;
}

const VerificationCodeComponent = forwardRef<any, IVerificationCodeComponentProps>(({
  email,
  isLoading,
  onVerificationSubmit,
  errorMessage,
  backButtonClick,
  handleOnResendCode,
}, ref) => (
  <>
    <StyledSocialNetworkContainer>
      <StyledTitle>
        Enter verification code
      </StyledTitle>
      <StyledSubTitle>
        Enter the verification code we sent to
        {' '}
        {email}
      </StyledSubTitle>
    </StyledSocialNetworkContainer>
    <VerificationCodeForm
      isLoading={isLoading}
      onSubmit={onVerificationSubmit}
      errorMessage={errorMessage}
    />
    <QuestionComponentWrapper>
      <QuestionStyledSpan>
        Didn&apos;t receive a code?
      </QuestionStyledSpan>
      <CountDownButton ref={ref}>
        <StyledTextButton
          disabled={isLoading}
          onClick={handleOnResendCode}
        >
          Resend code
        </StyledTextButton>
      </CountDownButton>
    </QuestionComponentWrapper>
    {
      backButtonClick ? (
        <StyledTextButton onClick={() => backButtonClick()}>
          Back to Sign In
        </StyledTextButton>
      ) : (
        <StyledSignUp
          to={APP_ROUTES.LOGIN}
        >
          Back to Sign In
        </StyledSignUp>
      )
    }
  </>
));

export default memo(VerificationCodeComponent);
