import styled from 'styled-components';
import { AccordionItem } from '@szhsin/react-accordion';
import { ReactComponent as NextIcon } from '../../../../../icons/next-icon-button.svg';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 48px 16px 64px;
  gap: 24px;
  background-color: ${(props) => props.theme.palette.black};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 96px 80px;
    flex-direction: row;
    gap: 154px;
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_lowercase};
  color: ${(props) => props.theme.palette.white};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h2_lowercase};
  }
`;

export const AccordionContainer = styled.div`
  width: 100%;
  color: ${(props) => props.theme.palette.black10};
  border-bottom: 1px solid ${(props) => props.theme.palette.black80};
`;

export const StyledAccordionItem = styled(AccordionItem)`
  padding: 24px 0;
  border-top: 1px solid ${(props) => props.theme.palette.black80};
  background-color: transparent;
  button {
    width: 100%;
    color: ${(props) => props.theme.palette.black10};
    border: none;
    background-color: transparent;
  }
  &.szh-accordion__item--expanded {
    .chevron {
      transform: rotate(90deg);
    }
  }
  .szh-accordion__item-content {
    transition: display 0.3s ease-in-out;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 40px 0;
  }
`;

export const StyledAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme.typography.caption_mobile_2};
  text-align: left;
  gap: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body};
  }
`;

export const StyledClosedIcon = styled(NextIcon)`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  transition: transform 0.3s linear;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
  }
`;

export const StyledTextContainer = styled.p`
  margin-top: 24px;
  ${(props) => props.theme.typography.caption_mobile_2};
  color: ${(props) => props.theme.palette.black25};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body3_new};
  }
`;
