import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {
  appleSignIn,
  facebookSignIn,
  getAccessToken, getCurrentUser, googleSignIn, login, logout, resendCode,
} from '../services';
import { ILoginResponse } from '../types';
import {
  getErrorMessage, removeStorageValue, setStorageValue,
} from '../../../utils/helpers';
import { COOKIE_ACCESS_TOKEN_NAME, LOCAL_STORAGE_REFRESH_TOKEN_NAME } from '../../../utils/constants';
import {
  ICurrentUserResponse, IOtpCodeResponse, ITokenResponse, IUser,
} from '../../../types';

export interface IAuthState {
  isLoading: boolean
  accessToken: string | null
  refreshToken: string | null
  user: IUser | null
  loginError: string | null
  tempToken: string | null
}

const initialState: IAuthState = {
  isLoading: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  loginError: null,
  tempToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    setAuthTokens: (state, { payload }: PayloadAction<ILoginResponse>) => {
      const { accessToken, refreshToken } = payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      Cookies.set(COOKIE_ACCESS_TOKEN_NAME, accessToken);
      setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
    },
    setTempToken: (state, { payload }: PayloadAction<string | null>) => {
      state.tempToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      login.fulfilled,
      (state, { payload }: PayloadAction<ILoginResponse>) => {
        const { accessToken, refreshToken } = payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        Cookies.set(COOKIE_ACCESS_TOKEN_NAME, accessToken);
        setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
        state.isLoading = false;
        state.loginError = initialState.loginError;
      },
    );
    builder.addCase(login.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.loginError = getErrorMessage(payload);
    });

    builder.addCase(googleSignIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      googleSignIn.fulfilled,
      (state, { payload }: PayloadAction<ILoginResponse>) => {
        const { accessToken, refreshToken } = payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        Cookies.set(COOKIE_ACCESS_TOKEN_NAME, accessToken);
        setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
        state.isLoading = false;
      },
    );
    builder.addCase(googleSignIn.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(facebookSignIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      facebookSignIn.fulfilled,
      (state, { payload }: PayloadAction<ILoginResponse>) => {
        const { accessToken, refreshToken } = payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        Cookies.set(COOKIE_ACCESS_TOKEN_NAME, accessToken);
        setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
        state.isLoading = false;
      },
    );
    builder.addCase(facebookSignIn.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(appleSignIn.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      appleSignIn.fulfilled,
      (state, { payload }: PayloadAction<ILoginResponse>) => {
        const { accessToken, refreshToken } = payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        Cookies.set(COOKIE_ACCESS_TOKEN_NAME, accessToken);
        setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
        state.isLoading = false;
      },
    );
    builder.addCase(appleSignIn.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      logout.fulfilled,
      (state) => {
        Cookies.remove(COOKIE_ACCESS_TOKEN_NAME);
        removeStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
        state.accessToken = initialState.accessToken;
        state.refreshToken = initialState.refreshToken;
        state.user = initialState.user;
        state.isLoading = initialState.isLoading;
      },
    );
    builder.addCase(logout.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCurrentUser.fulfilled,
      (state, { payload }: PayloadAction<ICurrentUserResponse>) => {
        state.isLoading = false;
        state.user = payload;
      },
    );
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(
      resendCode.fulfilled,
      (state, { payload }: PayloadAction<IOtpCodeResponse>) => {
        state.tempToken = payload.tempToken;
        state.loginError = initialState.loginError;
      },
    );

    builder.addCase(getAccessToken.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      getAccessToken.fulfilled,
      (state, { payload }: PayloadAction<ITokenResponse>) => {
        state.isLoading = false;
        const { accessToken, refreshToken } = payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        Cookies.set(COOKIE_ACCESS_TOKEN_NAME, accessToken);
        setStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshToken);
      },
    );
    builder.addCase(getAccessToken.rejected, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        const { statusCode, message } = payload;
        if (statusCode === 400 && message === 'INVALID_REFRESH_TOKEN') {
          state.accessToken = initialState.accessToken;
          state.refreshToken = initialState.refreshToken;
          Cookies.remove(COOKIE_ACCESS_TOKEN_NAME);
          removeStorageValue(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
          state.user = initialState.user;
        }
      }
    });
  },
});

export const { setAccessToken, setAuthTokens, setTempToken } = authSlice.actions;
export default authSlice.reducer;
