import React, { ButtonHTMLAttributes, memo } from 'react';
import { FooterButtonStyled } from '../../../core/layouts/Header/ProfileCard/styles';
import { StyledButtonLoader, StyledDefaultButton } from './styles';

interface IDefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean,
}

export const DefaultButton = memo<IDefaultButtonProps>(({ isLoading, children, ...rest }) => (
  <StyledDefaultButton {...rest}>
    { isLoading && <StyledButtonLoader />}
    {children}
  </StyledDefaultButton>
));

export const FooterButton = memo<IDefaultButtonProps>(({
  isLoading, children, ...rest
}) => (
  <FooterButtonStyled {...rest}>
    { isLoading && <StyledButtonLoader />}
    {children}
  </FooterButtonStyled>
));
