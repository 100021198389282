import styled from 'styled-components';
import phoneImageDesktop from '../../../../../images/phone-desktop.png';
import phoneImageMobile from '../../../../../images/phone-mobile.png';
import { ReactComponent as AppStoreIcon } from '../../../../../images/appstore.svg';

export const StyledDivider = styled.div`
  height: 1px;
  width: calc(100% - 128px);
  margin: 0 auto;
  background-color: ${(props) => props.theme.palette.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(100% - 192px);
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 64px 0 48px 16px;
  gap: 24px;
  background-color: ${(props) => props.theme.palette.black100};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 96px 80px 24px;
    position: relative;
    min-height: 988px;
    overflow: hidden;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 40px;
  padding-right: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-right: unset;
  }
`;

export const StyledImageContainer = styled.div`
  background-image: url(${phoneImageMobile});
  width: 100%;
  height: 223px;
  background-position: top;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 520px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    background-position: right;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop_medium}) {
    background-image: url(${phoneImageDesktop});
    height: 749px;
    width: 1266px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    position: absolute;
    bottom: -60px;
    right: 0;
  }
`;

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 40px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_lowercase};
  color: ${(props) => props.theme.palette.white};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h2_lowercase};
  }
`;

export const SubTitle = styled.p`
  ${(props) => props.theme.typography.body_mobile};
  color: ${(props) => props.theme.palette.black40};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body};
    max-width: 705px;
  }
`;

export const StyledAppStoreIcon = styled(AppStoreIcon)`
  width: 180px;
  height: 52px;
`;
