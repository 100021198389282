import React, { memo } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { SocialNetworkButton, StyledGoogleIcon } from '../../../../components/wallpapers/auth/styles';

interface IGoogleSignProps {
  onGoogleSignInResponse: (token: string) => void;
}

const GoogleSignIn = memo<IGoogleSignProps>(({ onGoogleSignInResponse }) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => onGoogleSignInResponse(tokenResponse.access_token),
  });
  return (
    <SocialNetworkButton onClick={() => login()} type="button"><StyledGoogleIcon /></SocialNetworkButton>
  );
});

export default GoogleSignIn;
