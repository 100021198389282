import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  ContactForm, ContactFormInput, ContactFormText, FormErrorMessage, InputContainer,
} from '../../styles';
import { DefaultButton, FooterButton } from '../../../../components/buttons/DefaultButton';
import { IContactForm } from '../../../../core/types';
import { EMAIL_REGEXP } from '../../../../utils/constants';
import { useAppSelector } from '../../../../hooks';
import { getIsSubmitSuccessful, getNotifyMeIsLoadingSelector } from '../../selectors';
import ConfirmationModal from '../ConfirmationModal';

interface IContactFormProps {
  onSubmit: (data: IContactForm) => void,
  footerStyle?: boolean;
}

export const ContactFormComponent = memo<IContactFormProps>(({ onSubmit, footerStyle }) => {
  const {
    register, handleSubmit, formState: { errors, isValid }, reset,
  } = useForm<IContactForm>();

  const isLoading = useAppSelector(getNotifyMeIsLoadingSelector);
  const isSubmitSuccessful = useAppSelector(getIsSubmitSuccessful);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  if (isSubmitSuccessful) {
    return <ConfirmationModal />;
  }

  return (
    <ContactForm $footerStyle={footerStyle} onSubmit={handleSubmit(onSubmit)}>
      <>
        <InputContainer>
          <ContactFormInput
            $footerStyle={footerStyle}
            placeholder="Enter your email address"
            {...register('email', { required: true, pattern: EMAIL_REGEXP })
            }
          />
          {errors && errors.email?.type === 'pattern' && (
            <FormErrorMessage>
              Wrong email format
            </FormErrorMessage>

          )}
        </InputContainer>
        {footerStyle ? (
          <FooterButton disabled={!isValid || isLoading} isLoading={isLoading} type="submit">
            <ContactFormText>
              Subscribe
            </ContactFormText>
          </FooterButton>
        ) : (
          <DefaultButton disabled={!isValid || isLoading} isLoading={isLoading} type="submit">
            <ContactFormText>
              Notify me
            </ContactFormText>
          </DefaultButton>
        )}
      </>
    </ContactForm>
  );
});
