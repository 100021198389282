import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 64px 16px 70px;
  background-color: ${(props) => props.theme.palette.black100};
  min-height: 100vh;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: center;
    align-items: unset;
    padding: 0 80px;
  }
`;

export const StyledSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
  margin-bottom: 121px;
  & .swiper {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    min-height: 0;
    min-width: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin-bottom: 92px;
  }
`;

export const StyledSwiperSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
`;

interface IStyledImageProps {
  image: string
}

export const StyledImage = styled.div<IStyledImageProps>`
  background-image: url(${(props) => props.image});
  width: 264px;
  height: 264px;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 1005px;
  }
`;

export const ManifestoText = styled.span`
  font: ${(props) => props.theme.typography.caption_mobile_2.font};
  color: ${(props) => props.theme.palette.black65};
  margin-bottom: 48px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 120px;
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_lowercase};
  color: ${(props) => props.theme.palette.white};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h2_lowercase};
  }
`;

export const SubTitle = styled.p`
  ${(props) => props.theme.typography.body_mobile};
  color: ${(props) => props.theme.palette.black25};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body};
  }
`;

export const StyledProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 24px;
  }
`;

export const StyledProgressContainer = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
`;

const StyledProgressBaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

export const StyledProgressBackground = styled(StyledProgressBaseBox)`
  background: ${(props) => props.theme.palette.black95};
  width: 100%;
`;

export const StyledProgress = styled(StyledProgressBaseBox)<{ percent: number }>`
  background: ${(props) => props.theme.palette.white};
  width: ${({ percent }) => percent}%;
`;
