import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  font: inherit;
`;

export const StyleSlashSpace = styled.span`
  margin: 0 8px;
`;
