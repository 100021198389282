import React, { useMemo, useState } from 'react';
import {
  FreeTrialContainer,
  Box,
  StyledLogo,
  StyledBadge,
  SmallText,
  SubTitle,
  TextBox,
  Title,
  WrapperSmall,
  OptionsBox,
  OptionsText,
  WhiteArrowDown,
  PremiumContainer,
  BodyTextWhite,
} from '../../styles';
import {
  StyledDefaultButton,
} from '../../../Profile/styles';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import PremiumCard from '../PremiumCard';
import { SUBSCRIPTION_MAPPING } from '../../../Profile/constants';
import { SubscriptionType } from '../../constants';
import { ISubscription } from '../../types';

interface IFreeTrialProps {
  scroll: () => void;
  subscriptions: ISubscription[]
}

const FreeTrial = ({ scroll, subscriptions }: IFreeTrialProps) => {
  const [type, setType] = useState('');

  const changeSubscription = (id: string) => setType(id);

  const subscription = SUBSCRIPTION_MAPPING[type.toLowerCase()];
  const monthSubscrption = useMemo(() => subscriptions.find((
    subs,
  ) => subs.productId.toUpperCase() === SubscriptionType.MONTH), [subscriptions]);
  const yearSubscription = useMemo(() => subscriptions.find((
    subs,
  ) => subs.productId.toUpperCase() === SubscriptionType.YEAR), [subscriptions]);
  const doesSubscriptionExist = monthSubscrption || yearSubscription;

  return (
    <FreeTrialContainer>
      <Box>
        <StyledLogo />
        <StyledBadge>Premium</StyledBadge>
      </Box>
      <TextBox>
        <Title>Start your free trial</Title>
        <SubTitle>All-in-One Subscription</SubTitle>
      </TextBox>
      {doesSubscriptionExist ? (
        <PremiumContainer>
          {monthSubscrption && (
            <PremiumCard
              subscription={SUBSCRIPTION_MAPPING.month}
              type={SubscriptionType.MONTH}
              changeSubscription={changeSubscription}
              isActive={type === SubscriptionType.MONTH}
              description={monthSubscrption.description}
            />
          )}
          {yearSubscription && (
            <PremiumCard
              subscription={SUBSCRIPTION_MAPPING.year}
              type={SubscriptionType.YEAR}
              changeSubscription={changeSubscription}
              isActive={type === SubscriptionType.YEAR}
              description={yearSubscription.description}
            />
          )}
        </PremiumContainer>
      ) : <BodyTextWhite>No subscriptions available</BodyTextWhite>}
      <WrapperSmall>
        {type ? (
          <span>
            <SmallText>{`First ${subscription.trialDays} days free,`}</SmallText>
            <SmallText>{`then $${subscription.price}/${subscription.period}, billed $${subscription.price} ${subscription.text}`}</SmallText>
          </span>
        ) : null}
        {doesSubscriptionExist ? (
          <StyledDefaultButton
            type="button"
            isLoading={false}
          >
            <StyledDefaultButtonTitle>
              Start Your Free Trial
            </StyledDefaultButtonTitle>
          </StyledDefaultButton>
        ) : null}
        <OptionsBox onClick={scroll}>
          <OptionsText>Special Pricing Options</OptionsText>
          <WhiteArrowDown />
        </OptionsBox>
      </WrapperSmall>
    </FreeTrialContainer>
  );
};

export default FreeTrial;
