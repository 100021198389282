import React, { memo } from 'react';

import {
  ButtonTitle,
  PageContainer, StyledContentContainer, StyledImageContainer,
  StyledLink,
  SubTitle,
  TextContentContainer,
  Title,
} from './styles';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

const Home1 = memo(() => (
  <PageContainer>
    <StyledContentContainer>
      <TextContentContainer>
        <Title>
          Finding Balance
          <br />
          in Ballet and Beyond
        </Title>
        <SubTitle>
          Dive into a world where movement meets mindfulness. At Swanly,
          we blend the grace of ballet with holistic practices to shape not just your body,
          but your soul. Begin a transformative journey today.
        </SubTitle>
      </TextContentContainer>
      <StyledLink to={APP_ROUTES.COURSES}>
        <ButtonTitle>
          Start Free Trial
        </ButtonTitle>
      </StyledLink>
    </StyledContentContainer>
    <StyledImageContainer />
  </PageContainer>
));

export default Home1;
