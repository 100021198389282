import React, { memo } from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import config from '../../../../config';
import { SocialNetworkButton, StyledFacebookIcon } from '../../../../components/wallpapers/auth/styles';

interface IFacebookSignProps {
  onFacebookSignInResponse: (token: string) => void;
}

const FacebookSignIn = memo<IFacebookSignProps>(({ onFacebookSignInResponse }) => (
  <FacebookLogin
    appId={config.facebookClientId}
    fields="name,email"
    render={({ onClick }) => (
      <SocialNetworkButton onClick={onClick}>
        <StyledFacebookIcon />
      </SocialNetworkButton>
    )}
    onSuccess={(response) => {
      if ('accessToken' in response) {
        onFacebookSignInResponse(response?.accessToken);
      }
    }}
  />
));

export default FacebookSignIn;
