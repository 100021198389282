import React, {
  forwardRef,
  memo,
  useState,
  VFC,
} from 'react';
import {
  ClosedEye,
  OpenedEye,
  CustomPasswordStyledInput,
} from './style';
import { ICustomPasswordInputProps } from './types';

const CustomPasswordInput: VFC<ICustomPasswordInputProps> = memo(
  forwardRef<HTMLInputElement, ICustomPasswordInputProps>((props, ref) => {
    const [showSymbols, setShowSymbols] = useState(false);
    const changeSymbols = () => {
      setShowSymbols(!showSymbols);
    };

    return (
      <CustomPasswordStyledInput
        {...props}
        ref={ref}
        type={showSymbols ? 'text' : 'password'}
        icon={!showSymbols ? <OpenedEye onClick={changeSymbols} />
          : <ClosedEye onClick={changeSymbols} />}
      />
    );
  }),
);

export default CustomPasswordInput;
