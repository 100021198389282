import React from 'react';
import {
  ManagerContainer, SecondaryTitle, Divider, TitleAvatar, SubstitleAvatar, Avatar, AvatarContainer,
} from '../../containers/Friends/style';
import defaultAvatar from '../../../../images/avatar-default.png';
import { IProfileResponse } from '../../../../types';

interface IManagerProps {
  profile: IProfileResponse | null
}

const Manager = ({ profile }: IManagerProps) => {
  const currentProfile = (profile?.avatar && profile?.avatar.url) || defaultAvatar;
  const firstName = profile?.firstName ? `${profile?.firstName} (you)` : 'You';

  return (
    <ManagerContainer>
      <SecondaryTitle>Manager</SecondaryTitle>
      <Divider />
      <AvatarContainer>
        <Avatar src={currentProfile} alt="" />
        <span>
          <TitleAvatar>{firstName}</TitleAvatar>
          <SubstitleAvatar>{profile?.email}</SubstitleAvatar>
        </span>
      </AvatarContainer>
    </ManagerContainer>
  );
};

export default Manager;
