import React, { memo, useMemo } from 'react';
import Breadcrumbs from '../../../../components/breadcrumbs';
import { BreadcrumbsContainer, PageContainer } from '../../../../components/common/styles';
import { ICourse } from '../../../../types';
import HeadBanner from '../HeadBanner';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../../../utils/constants';
import SliderClasses from '../SliderClasses';
import SubscriptionBlock from '../SubscriptionBlock';
import PartCourses from '../../../../components/sliders/PartCourses';
import SliderCourses from '../../../../components/sliders/SliderCourses';
import { CourseCategory } from '../../constants';

interface IPageWrapperProps {
  courses: ICourse[] | null
  isSubscribed: boolean | undefined
}

const PageWrapper = memo<
IPageWrapperProps>((
  { courses, isSubscribed },
) => {
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.mobile, [width]);
  const newCourses = useMemo(() => courses?.filter((course) => course.isNew).slice(-3), [courses]);
  const cardio = useMemo(
    () => courses?.filter((course) => course.category === CourseCategory.CARDIO),
    [courses],
  );
  const stretching = useMemo(
    () => courses?.filter((course) => course.category === CourseCategory.STRETCHING),
    [courses],
  );
  const mostPopular = useMemo(
    () => courses?.filter((course) => course.isPopular).slice(0, 3),
    [courses],
  );
  return (
    <PageContainer>
      <BreadcrumbsContainer>
        <Breadcrumbs />
      </BreadcrumbsContainer>
      {courses && courses.length > 0 && (
        <HeadBanner courses={courses} isMobile={isMobile} />
      )}
      {newCourses && newCourses?.length > 0 && (
      <PartCourses
        hasDivider
        title="new courses"
        courses={newCourses}
        isMobile={isMobile}
      />
      )}
      {cardio && cardio.length > 0 && (
      <SliderCourses
        title="cardio"
        isMobile={isMobile}
        courses={cardio}
      />
      )}
      {stretching && stretching.length > 0 && (
      <SliderCourses
        title="stretching"
        isMobile={isMobile}
        courses={stretching}
      />
      )}
      {courses && courses.length > 0 && (
      <SliderClasses
        title="new classes"
        isMobile={isMobile}
        courses={courses}
      />
      )}
      {!isSubscribed && <SubscriptionBlock />}
      {mostPopular && mostPopular.length > 0 && (
      <PartCourses
        title="Most popular"
        isMobile={isMobile}
        courses={mostPopular}
      />
      )}
    </PageContainer>
  );
});

export default PageWrapper;
