import { joiResolver } from '@hookform/resolvers/joi';
import React, {
  FC, memo, useEffect, useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { profileSchema } from '../../../../utils/validation/schemas';
import { IProfileForm, IProfileInfoProps } from '../../types';
import {
  EmailLabel,
  FormContainer,
  StyledForm,
  StyledEmailContainer,
  EmailText,
  StyledInput,
  StyledDefaultButton,
  DisabledInput,
} from '../../styles';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import CustomDatepicker from '../../../../components/inputs/CustomDatepicker';
import { IProfile } from '../../../../types';
import { ChangePasswordForm } from '../ChangePasswordForm';
import { useAppSelector } from '../../../../hooks';
import { getIsGlobalProfileLoadingSelector } from '../../../../core/selectors';

const initialValues: IProfile = {
  firstName: '',
  birthday: null,
  avatar: null,
  email: '',
  id: '',
};

const ProfileInfoForm: FC<IProfileInfoProps> = memo(({
  onSubmit,
  profile,
  profileError,
  onPasswordSubmit,
}) => {
  const isLoading = useAppSelector(getIsGlobalProfileLoadingSelector);
  const [isOpen, setIsOpen] = useState(false);
  const currProfile = profile || initialValues;
  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: {
      errors, isDirty, isValid, isSubmitted,
    },
  } = useForm<IProfileForm>({
    resolver: joiResolver(profileSchema),
    mode: 'onBlur',
    defaultValues: {
      firstName: currProfile.firstName || '',
      birthday: currProfile.birthday ? new Date(currProfile.birthday) : null,
    },
  });

  useEffect(() => {
    if (isSubmitted) {
      if (!profileError) {
        reset({
          firstName: currProfile.firstName || '',
          birthday: currProfile.birthday ? new Date(currProfile.birthday) : null,
        });
      } else {
        reset();
      }
    }
  }, [profile]);

  const submit = (data: IProfileForm) => {
    onSubmit(data);
  };
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <>
      <StyledForm onSubmit={handleSubmit(submit)}>
        <FormContainer>
          {currProfile.email && (
          <StyledEmailContainer>
            <EmailLabel>Connected with Email</EmailLabel>
            <EmailText>{currProfile.email}</EmailText>
          </StyledEmailContainer>
          )}
          <StyledInput
            {...register('firstName')}
            label="Name"
            placeholder="Enter name"
            error={errors.firstName?.message}
          />
          <Controller
            name="birthday"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <CustomDatepicker
                onChange={onChange}
                selected={value}
                dateFormat="MMMM d, yyyy"
                placeholder="Select date"
                label="Birthday"
                error={errors.birthday?.message}
                onBlur={onBlur}
                maxDate={new Date()}
                minDate={new Date('01/01/1919')}
              />
            )}
          />
          <DisabledInput
            label="Password"
            placeholder="Enter password"
            type="password"
            readOnly
            value="password"
            onClick={handleOpen}
          />
        </FormContainer>

        <StyledDefaultButton
          disabled={!isValid || !isDirty}
          type="submit"
          isLoading={isLoading}
        >
          <StyledDefaultButtonTitle>
            Save Changes
          </StyledDefaultButtonTitle>
        </StyledDefaultButton>
      </StyledForm>
      <ChangePasswordForm
        onSubmit={onPasswordSubmit}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </>
  );
});

export default ProfileInfoForm;
