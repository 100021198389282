import React, { memo, useMemo } from 'react';
import { ICourse } from '../../../../types';
import {
  ProgressBarContainer,
  ProgressCount,
  ProgressCountContainer,
  ProgressPercents, ProgressText,
  StyledProgressBar,
} from './styles';

interface IProgressBarProps {
  course: ICourse
}

const ProgressBar = memo<IProgressBarProps>(({ course }) => {
  const currentProgress = useMemo(() => {
    const doneCount = course.lessons.filter((lesson) => lesson.isDone).length;
    const totalCount = course.lessons.length;
    const percent = Math.round((doneCount / totalCount) * 100);
    return {
      percent: !Number.isNaN(percent) ? percent : 0,
      doneCount,
      totalCount,
    };
  }, [course]);
  return (
    <ProgressBarContainer>
      <ProgressCountContainer>
        <ProgressCount>
          {currentProgress.doneCount}
          /
          {currentProgress.totalCount}
        </ProgressCount>
        <ProgressText>
          classes completed
        </ProgressText>
      </ProgressCountContainer>
      <ProgressCountContainer>
        <StyledProgressBar
          value={currentProgress.doneCount}
          max={currentProgress.totalCount}
        />
        <ProgressPercents>
          {currentProgress.percent}
          %
        </ProgressPercents>
      </ProgressCountContainer>
    </ProgressBarContainer>
  );
});

export default ProgressBar;
