export const APP_ROUTES = {
  MAIN: '/',
  CONTACT: '/contact',
  COURSES: '/courses',
  COURSES_ID: '/courses/:id',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password',
  LOGIN: '/login',
  APPLE_CALLBACK: '/apple/callback',
  SUBSCRIPTION: '/subscription',
  PROFILE_INFO: '/profile/info',
  PROFILE_SUBSCRIPTION: '/profile/subscription',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
};

export const APP_ROUTE_NAME = {
  MAIN: 'Main',
  CONTACT: 'Contact',
  COURSES: 'Courses',
  REGISTER: 'Register',
  RESET_PASSWORD: 'Reset Password',
  LOGIN: 'Login',
};
