import React, { useCallback, useMemo } from 'react';
import uuid from 'react-uuid';
import CustomModal from '../../../../components/modals/CustomModal';
import {
  ContainerHeader,
  SecondaryTitle,
  SmallText,
  InfoIcon,
  Divider,
  GroupFriends,
  DefaultFriendsContainer,
  DefaultAvatarBox,
  PlusBox,
  PlusIcon,
  TitleAvatar,
  SubstitleAvatar,
  Avatar,
  FriendWrapper,
  Block,
  AvatarContainer,
  ArrowIcon,
} from '../../containers/Friends/style';
import Friend from '../Friend';
import PopperContents from '../Popper';
import {
  PopperBox, PopperContainer, PopperHeader, Arrow,
} from '../Popper/styles';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { IFriend } from '../../../../types';
import defaultAvatar from '../../../../images/avatar-default.png';
import { BREAKPOINTS } from '../../../../utils/constants';

interface IContainerProps {
  isOpen: boolean;
  toggle: (open: boolean) => void;
  getCode: () => void;
  toggleAddFriends: (open: boolean) => void;
  friends: IFriend[];
  removeFriend: (id: string) => void
}

const FriendsContainer = ({
  isOpen, toggle, friends, toggleAddFriends, getCode, removeFriend,
}: IContainerProps) => {
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.mobile, [width]);
  const invitationsCount = friends.length >= 5 ? 0 : 5 - friends.length;

  const onCloseModalHandler = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    toggle(false);
  }, []);

  return (
    <FriendWrapper>
      <ContainerHeader>
        <SecondaryTitle>Members</SecondaryTitle>
        <Block onClick={() => isMobile && toggle(true)}>
          <SmallText>How to use invitations</SmallText>
          <InfoIcon />
          {isMobile ? (
            <CustomModal
              title="HOW TO USE INVITATIONS"
              isOpen={isOpen}
              onClose={onCloseModalHandler}
              isPrimaryTheme={false}
              isTitleCented
              headerSm
            >
              <PopperContents />
            </CustomModal>
          ) : (
            <PopperBox className="popper-custom">
              <PopperContainer>
                <PopperHeader>HOW TO USE INVITATIONS</PopperHeader>
                <PopperContents />
              </PopperContainer>
              <Arrow />
            </PopperBox>
          )}
        </Block>
      </ContainerHeader>
      <Divider />
      <GroupFriends>
        {friends.length >= 5 ? null : (
          <DefaultFriendsContainer
            $friendsExit={friends.length > 0}
            onClick={() => {
              getCode();
              toggleAddFriends(true);
            }}
          >
            <AvatarContainer>
              <DefaultAvatarBox>
                <PlusBox>
                  <PlusIcon />
                </PlusBox>
                <Avatar src={defaultAvatar} alt="" />
              </DefaultAvatarBox>
              <span>
                <TitleAvatar>Invite friends members</TitleAvatar>
                <SubstitleAvatar>{`${invitationsCount} invitations left`}</SubstitleAvatar>
              </span>
            </AvatarContainer>
            <ArrowIcon />
          </DefaultFriendsContainer>
        )}
        {friends.map((friend, i) => (
          <Friend
            key={uuid()}
            isFirst={i === 0}
            friend={friend}
            onRemove={removeFriend}
          />
        ))}
      </GroupFriends>
    </FriendWrapper>
  );
};

export default FriendsContainer;
