import React, { memo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { ICourse } from '../../../../types';
import { getCourseLevels } from '../../../../utils/helpers';
import {
  HeadBannerBackDrop,
  HeadBannerPageContainer,
  HeadBannerSlideCardLevel, HeadBannerSlideCardSubTitle,
  HeadBannerSlideCardTitle,
  HeadBannerSlideContainer,
  StyledDivider,
} from '../../styles';
import verticalMockImage from '../../../../images/vertical-mock.jpg';
import {
  StyledNavButton,
  StyledNavigateButtonsContainer,
  StyledNextIcon,
  StyledPrevIcon,

} from '../../../Home/components/Home3/styles';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

const HeadBanner = memo<
{
  courses: ICourse[] | null,
  isMobile: boolean
}
>((
  {
    courses,
    isMobile,
  },
) => {
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  return (
    <>
      <HeadBannerPageContainer>
        <Swiper
          modules={[Pagination, Navigation]}
          pagination={{
            enabled: true,
            dynamicBullets: true,
          }}
          loop
          slidesPerView={isMobile ? 1 : 1.5}
          spaceBetween={isMobile ? 0 : 24}
          navigation={{
            enabled: !isMobile,
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          centeredSlides
        >
          {
            courses?.map((course) => (
              <SwiperSlide key={uuid()}>
                <HeadBannerBackDrop onClick={() => navigate(APP_ROUTES.COURSES_ID.replace(':id', course.id))}>
                  <HeadBannerSlideCardLevel key={uuid()}>
                    {getCourseLevels(course.level)}
                  </HeadBannerSlideCardLevel>
                  <HeadBannerSlideCardTitle key={uuid()}>
                    {course.name}
                  </HeadBannerSlideCardTitle>
                  <HeadBannerSlideCardSubTitle key={uuid()}>
                    {course.shortDescription}
                  </HeadBannerSlideCardSubTitle>
                </HeadBannerBackDrop>
                <HeadBannerSlideContainer
                  key={uuid()}
                  image={
                      isMobile
                        ? course.coursePreview?.url
                        ?? verticalMockImage
                        : course.image?.url
                        ?? verticalMockImage
                    }
                />
              </SwiperSlide>
            ))
          }

        </Swiper>
        <StyledNavigateButtonsContainer>
          <StyledNavButton ref={prevRef}>
            {!isMobile && <StyledPrevIcon />}
          </StyledNavButton>
          <StyledNavButton ref={nextRef}>
            {!isMobile && <StyledNextIcon />}
          </StyledNavButton>
        </StyledNavigateButtonsContainer>
      </HeadBannerPageContainer>
      <StyledDivider />
    </>
  );
});

export default HeadBanner;
