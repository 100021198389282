import { useEffect } from 'react';

const useMouseScroll = (callback: (event: Event) => void) => {
  useEffect(() => {
    const handleScroll = (event: Event) => {
      callback(event);
    };

    document.addEventListener('wheel', handleScroll);
    document.addEventListener('touchmove', handleScroll);

    return () => {
      document.removeEventListener('wheel', handleScroll);
      document.removeEventListener('touchmove', handleScroll);
    };
  }, [callback]);
};

export default useMouseScroll;
