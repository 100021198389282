import styled from 'styled-components';
import { IImageProps } from '../../../core/types';
import { ReactComponent as CloseIcon } from '../../../icons/close-icon.svg';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0 0 0;
  & .swiper {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    min-height: 0;
    min-width: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px 0 0 0;
  }
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 80px;
  a {
    text-decoration: none;
  }
  ${(props) => props.theme.typography.subtitle3_new};
  color: ${(props) => props.theme.palette.black80};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

export const StyledEllipsisSpan = styled.span`
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCardLevel = styled.span`
  font: ${(props) => props.theme.typography.overline_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.subtitle4.font};
    text-transform: uppercase;
  }
`;

export const SliderCourseCard = styled.div<IImageProps>`
  background-image: url(${(props) => props.image});
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-width: 320px;
  min-height: 417px;
  padding: 16px;
  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 491px;
    min-height: 280px;
  }
`;

export const SliderLessonCardCourseNew = styled.div`
  padding: 2px 8px;
  text-align: center;
  position: absolute;
  text-transform: uppercase;
  top: 8px;
  left: 8px;
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.h5_mobile.font};
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.transparentGray30};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 4px 12px;
    font: ${(props) => props.theme.typography.h4_mobile.font};
    left: 12px;
    top: 12px;
  }
`;

export const StyledClose = styled(CloseIcon)`
  width: 32px;
  height: 32px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 48px;
    height: 48px;
  }
`;
