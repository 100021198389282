import React, {
  JSX, memo, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { ChildrenContainer, MainLayoutContainer } from './styles';
import { useAppSelector } from '../../../hooks';
import { getCurrentUserSelector, getGlobalProfileSelector } from '../../selectors';
import Header from '../Header';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../../utils/constants';
import { MobileMenu } from '../Header/MobileMenu';
import Footer from '../Footer';

interface IMainLayoutProps {
  children: JSX.Element,
}

const MainLayout = memo<IMainLayoutProps>(({ children }) => {
  const currentUser = useAppSelector(getCurrentUserSelector);
  const currentUserProfile = useAppSelector(getGlobalProfileSelector);
  const [width] = useWindowSize();
  const location = useLocation();
  const isMobile = width <= BREAKPOINTS.mobile;
  const [menu, setMenu] = useState(false);
  const handleSubmit = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    setMenu(false);
  }, [isMobile, location]);

  return (
    <MainLayoutContainer>
      <>
        <Header
          profile={currentUserProfile}
          userEnabled={!!currentUser}
          handleSubmit={handleSubmit}
          blackTheme={menu}
        />
        {
          menu && (<MobileMenu profile={currentUserProfile} userEnabled={!!currentUser} />)
        }
        <ChildrenContainer>{children}</ChildrenContainer>
        <Footer />
      </>
    </MainLayoutContainer>
  );
});

export default MainLayout;
