import React, { memo, useRef } from 'react';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import AuthWallpaper from '../../../../components/wallpapers/auth';
import { IRegisterRequest } from '../../types';
import RegisterForm from '../RegisterForm';
import {
  QuestionComponentWrapper,
  QuestionStyledSpan,
  StyledLogoDesktopIcon,
  StyledSignUp,
  StyledSocialNetworkContainer,
  StyledTitle,
} from '../../../../components/wallpapers/auth/styles';
import { StyledRegisterContentContainer, StyledRegisterLogoMobileIcon } from '../../styles';
import { IVerificationCodeRequest } from '../../../../types';
import VerificationCodeComponent from '../../../../components/wallpapers/otp';
import { getStorageValue } from '../../../../utils/helpers';
import { REDIRECT_URL_NAME } from '../../../../utils/constants';

interface IRegisterProps {
  onSubmit: (values: IRegisterRequest) => void
  onVerificationSubmit: (values: IVerificationCodeRequest) => void
  onResendCode: () => void
  registerError?: string | null
  verifyError?: string | null
  isMobile: boolean
  isCodePage: boolean
  isLoading: boolean
  email: string
}
const RegisterWrapper = memo<IRegisterProps>((
  {
    onSubmit,
    onVerificationSubmit,
    onResendCode,
    registerError,
    verifyError,
    isCodePage,
    isMobile,
    isLoading,
    email,
  },
) => {
  const buttonRef = useRef<any>();
  const handleOnResendCode = () => {
    onResendCode();
    if (buttonRef?.current) {
      buttonRef.current.dispatchEvent(new Event('click', { cancelable: true, bubbles: true }));
    }
  };
  const backButtonRoute = getStorageValue(REDIRECT_URL_NAME) || APP_ROUTES.MAIN;

  return (
    <AuthWallpaper
      isMobile={isMobile}
      backButtonRoute={backButtonRoute}
    >
      <StyledRegisterContentContainer>
        {isMobile ? (
          <StyledRegisterLogoMobileIcon />
        ) : (
          <StyledLogoDesktopIcon />
        )}
        {isCodePage ? (
          <VerificationCodeComponent
            errorMessage={verifyError}
            email={email}
            isLoading={isLoading}
            ref={buttonRef}
            onVerificationSubmit={onVerificationSubmit}
            handleOnResendCode={handleOnResendCode}
          />
        ) : (
          <>
            <StyledSocialNetworkContainer>
              <StyledTitle>
                Sign Up
                {' '}
                <span>with email</span>
              </StyledTitle>
            </StyledSocialNetworkContainer>
            <RegisterForm
              isLoading={isLoading}
              onSubmit={onSubmit}
              registerError={registerError}
            />
            <QuestionComponentWrapper>
              <QuestionStyledSpan>
                Already have an account?
              </QuestionStyledSpan>
              <StyledSignUp to={APP_ROUTES.LOGIN}>
                Sign In
              </StyledSignUp>
            </QuestionComponentWrapper>
          </>
        )}
      </StyledRegisterContentContainer>
    </AuthWallpaper>
  );
});

export default RegisterWrapper;
