import Joi from 'joi';

export const passwordSchema = Joi
  .string()
  .pattern(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]){4,50}$/)
  .message('Password must contain at least 1 letter, 1 number, minimum of 4 characters')
  .messages({ 'string.empty': 'Password is required' });

export const confirmPassword = Joi.any().equal(Joi.ref('password'))
  .required()
  .label('Confirm password')
  .messages({ 'any.only': '{{#label}} does not match' });

export const emailSchema = Joi
  .string()
  .email({ tlds: { allow: false } })
  .message('This email address doesn\'t look right');

export const nameSchema = Joi
  .string()
  .pattern(/^([a-zA-Z"'\-`]){2,50}$/)
  .required()
  .messages({ 'string.empty': 'Name is required', 'string.pattern.base': 'First name is invalid' });

export const birthdaySchema = Joi
  .date()
  .required()
  .messages({
    'date.base': 'Date is invalid',
    'date.empty': 'Date is required',
    'any.required': 'Date is required',
  });

export const loginSchema = Joi.object({
  email: emailSchema,
  password: passwordSchema,
});

export const registerSchema = Joi.object({
  email: emailSchema,
  password: passwordSchema,
  confirmPassword,
});

export const changePasswordSchema = Joi.object({
  currentPassword: passwordSchema,
  password: passwordSchema,
  confirmPassword,
});

export const profileSchema = Joi.object({
  firstName: nameSchema,
  birthday: birthdaySchema,
});

export const verificationCodeSchema = Joi.object({
  code: Joi
    .string()
    .pattern(/^\d{4}$/)
    .message('Invalid code'),
});

export const resetPasswordSchema = Joi.object({
  email: emailSchema,
});

export const setPasswordSchema = Joi.object({
  password: passwordSchema,
  confirmPassword,
});
