import React, { memo, ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  CoursesContainer,
  NewCoursesPageContainer,
  PageTitle,
  StyledDivider,
} from './styles';

import { ICourse } from '../../../types';
import Course from './components/Course';

interface ISliderCoursesProps {
  title: ReactNode;
  courses: ICourse[] | null | undefined;
  isMobile: boolean;
}

const SliderCourses = memo<ISliderCoursesProps>(
  (
    {
      title,
      courses,
      isMobile,
    },
  ) => (
    <>
      <NewCoursesPageContainer>
        <PageTitle>
          {title}
        </PageTitle>
        <CoursesContainer>
          <Swiper
            slidesPerView={isMobile ? 1 : 3}
            spaceBetween={24}
            freeMode
          >
            {courses?.map((course) => (
              <SwiperSlide>
                <Course course={course} isMobile={isMobile} />
              </SwiperSlide>
            ))}
          </Swiper>
        </CoursesContainer>
      </NewCoursesPageContainer>
      <StyledDivider />
    </>
  ),
);

export default SliderCourses;
