import React, { forwardRef, useMemo } from 'react';
import {
  OptionsContainer,
  BenefitsTitle,
  CardsContainer,
  BodyTextWhite,
} from '../../styles';
import StudentsCard from '../StudentsCard';
import { SUBSCRIPTION_MAPPING } from '../../../Profile/constants';
import { ISubscription } from '../../types';
import { SubscriptionType } from '../../constants';
import FriendsCardWrapper from '../FriendsCardWrapper';

interface IOptionsProps {
  subscriptions: ISubscription[]
}

const SpecialPricingOptions = forwardRef<HTMLDivElement, IOptionsProps>(
  ({ subscriptions }, ref) => {
    const friendSubscription = useMemo(() => subscriptions.find((
      subs,
    ) => subs.productId.toUpperCase() === SubscriptionType.FRIENDS), [subscriptions]);
    const studentSubscription = useMemo(() => subscriptions.find((
      subs,
    ) => subs.productId.toUpperCase() === SubscriptionType.STUDENTS), [subscriptions]);

    return (
      <OptionsContainer ref={ref}>
        <BenefitsTitle>Special pricing options</BenefitsTitle>
        {friendSubscription || studentSubscription ? (
          <CardsContainer>
            {friendSubscription && (
              <FriendsCardWrapper
                subscription={SUBSCRIPTION_MAPPING.friends}
                description={friendSubscription.description}
              />
            )}
            {studentSubscription && (
              <StudentsCard
                subscription={SUBSCRIPTION_MAPPING.students}
                description={studentSubscription.description}
              />
            )}
          </CardsContainer>
        )
          : <BodyTextWhite>No subscriptions available</BodyTextWhite>}
      </OptionsContainer>
    );
  },
);

export default SpecialPricingOptions;
