import React, { memo } from 'react';

import {
  PageContainer, StyledAppStoreIcon,
  StyledContentContainer, StyledDivider,
  StyledImageContainer,
  SubTitle,
  TextContentContainer,
  Title,
} from './styles';

const Home7 = memo(() => (
  <>
    <PageContainer>
      <StyledContentContainer>
        <TextContentContainer>
          <Title>
            Practice anytime, anywhere
          </Title>
          <SubTitle>
            With Swanly, your transformative journey fits right in your pocket.
            Dive into a session during a work break, at the park, or right at home.
            The world is your studio.
          </SubTitle>
        </TextContentContainer>
        <a href="/">
          <StyledAppStoreIcon />
        </a>
      </StyledContentContainer>
      <StyledImageContainer />
    </PageContainer>
    <StyledDivider />
  </>
));

export default Home7;
