import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/helpers';
import {
  getSubscriptions,
  createFriendSubscription,
} from '../services';
import { ISubscription } from '../types';

export interface ISubscriptionState {
  isLoading: boolean;
  isInvitationSuccessfull: boolean | null;
  isInvitationLoading: boolean;
  subscriptions: ISubscription[] | null;
}

const initialState: ISubscriptionState = {
  isLoading: false,
  isInvitationSuccessfull: null,
  isInvitationLoading: false,
  subscriptions: null,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setIsInvitationSuccessfull: (state, { payload }: PayloadAction<boolean | null>) => {
      state.isInvitationSuccessfull = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSubscriptions.pending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      getSubscriptions.fulfilled,
      (state, { payload }) => {
        state.subscriptions = payload;
        state.isLoading = false;
      },
    );
    builder.addCase(getSubscriptions.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(getErrorMessage(payload));
    });
    builder.addCase(
      createFriendSubscription.pending,
      (state) => {
        state.isInvitationLoading = true;
      },
    );
    builder.addCase(
      createFriendSubscription.fulfilled,
      (state) => {
        state.isInvitationSuccessfull = true;
        state.isInvitationLoading = false;
      },
    );
    builder.addCase(createFriendSubscription.rejected, (state, { payload }) => {
      state.isInvitationSuccessfull = false;
      state.isInvitationLoading = false;
      toast.error(getErrorMessage(payload));
    });
  },
});
export const { setIsInvitationSuccessfull } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
