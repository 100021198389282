import React, {
  memo,
  useCallback,
} from 'react';
import {
  InputFieldWarning,
  ReactStyledCodeInput,
  StyledInputLabel,
  StyledInputWrapper,
} from './style';
import { IInputProps } from './types';

const CustomCodeInput = memo<IInputProps>(({
  label,
  onChange,
  disabled,
  error,
  name,
  className,
}) => {
  const onHandleChange = useCallback((value: string) => {
    onChange(value);
  }, []);

  return (
    <StyledInputWrapper className={className}>
      <StyledInputLabel
        {...(error ? { error: true } : {})}
        disabled={disabled}
      >
        {label}
      </StyledInputLabel>
      <ReactStyledCodeInput
        onChange={(value) => {
          onHandleChange(value);
        }}
        {...(error ? { error: true } : {})}
        inputMode="numeric"
        name={name}
        type="text"
        disabled={disabled}
      />

      <InputFieldWarning>{error}</InputFieldWarning>
    </StyledInputWrapper>
  );
});

export default CustomCodeInput;
