import React, {
  memo, FC, useState, useEffect,
} from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { IGuard } from './types';
import { useAppSelector } from '../../hooks';
import { getCurrentUserSelector/* , getLoadingUserSelector */ } from '../selectors';
import { APP_ROUTES } from '../router/appRoutes';
import { COOKIE_ACCESS_TOKEN_NAME, REDIRECT_URL_NAME } from '../../utils/constants';
import { getStorageValue } from '../../utils/helpers';

export const RegisteredUserGuard: FC<IGuard> = memo(({
  children,
}: IGuard) => {
  const [isRendered, rendered] = useState<boolean | null>(null);
  const currentUser = useAppSelector(getCurrentUserSelector);
  // const isLoading = useAppSelector(getLoadingUserSelector);
  const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN_NAME);

  useEffect(() => {
    if (currentUser || !accessToken) {
      rendered(true);
    }
  }, [currentUser, accessToken]);

  // if (isLoading || currentUser === undefined) {
  //   return <> Loading... </>;
  // }

  if (!isRendered) {
    return null;
  }

  return currentUser ? children : <Navigate to={APP_ROUTES.LOGIN} />;
});

export const UnregisteredUserGuard: FC<IGuard> = memo(({
  children,
}: IGuard) => {
  const redirectPath = getStorageValue(REDIRECT_URL_NAME) || APP_ROUTES.MAIN;
  const currentUser = useAppSelector(getCurrentUserSelector);
  // const isLoading = useAppSelector(getLoadingUserSelector);
  // if (isLoading || currentUser === undefined) {
  //   return <> Loading... </>;
  // }
  return currentUser === null ? children : <Navigate to={redirectPath} />;
});
