import React, { memo } from 'react';
import { StyledClose } from '../../../../components/common/styles';
import { ITrainer } from '../../../../types';
import {
  TrainerAvatar,
  TrainerContainer,
  TrainerContentContainer, TrainerDescription,
  TrainerName, TrainerPosition,
  TrainerTitle,
  TrainerTitleContainer,
} from './styles';

interface ITrainerProps {
  trainer: ITrainer,
  onCloseClick: () => void,
}

const Trainer = memo<ITrainerProps>(({ trainer, onCloseClick }) => (
  <TrainerContainer>
    <TrainerTitleContainer>
      <TrainerTitle>Trainer</TrainerTitle>
      <StyledClose onClick={() => onCloseClick()} />
    </TrainerTitleContainer>
    <TrainerContentContainer>
      <TrainerAvatar
        image={`${trainer?.avatar?.url}`}
      />
      <div>
        <TrainerName>
          {trainer?.name}
        </TrainerName>
        <TrainerPosition>
          {trainer?.position}
        </TrainerPosition>
      </div>
    </TrainerContentContainer>
    <TrainerDescription
      dangerouslySetInnerHTML={{
        __html: trainer?.description,
      }}
    />
  </TrainerContainer>
));

export default Trainer;
