import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  StyledDivider,
  StyledLogo,
  StyledLogoContainer,
  StyledPremium,
  StyledSubscriptionBenefitsButton,
  StyledSubscriptionBlockContainer,
  StyledSubscriptionButton,
  StyledSubscriptionContainer, StyledSubscriptionContentContainer,
  StyledSubscriptionSubTitle,
  StyledSubscriptionTitle,
} from '../../styles';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

const SubscriptionBlock = memo(() => {
  const navigate = useNavigate();
  return (
    <>
      <StyledSubscriptionContainer>
        <StyledSubscriptionBlockContainer>
          <StyledSubscriptionContentContainer>
            <StyledLogoContainer>
              <StyledLogo />
              <StyledPremium>
                Premium
              </StyledPremium>
            </StyledLogoContainer>
            <StyledSubscriptionTitle>
              Courses for Everyone
            </StyledSubscriptionTitle>
            <StyledSubscriptionSubTitle>
              At Swanly, our courses are carefully designed with your needs in mind.
              Blending the beauty of ballet with the benefits of stretching,
              each course helps you connect body and mind.
              Whether you&apos;re just starting out or looking to dive deeper,
              our courses have something for everyone.
              Step into a journey that uplifts, refreshes, and brings balance to your life.
            </StyledSubscriptionSubTitle>
            <StyledSubscriptionButton onClick={() => navigate(APP_ROUTES.SUBSCRIPTION)}>
              Get Premium
            </StyledSubscriptionButton>
            <StyledSubscriptionBenefitsButton>
              Swanly Premium benefits
            </StyledSubscriptionBenefitsButton>
          </StyledSubscriptionContentContainer>
        </StyledSubscriptionBlockContainer>
      </StyledSubscriptionContainer>
      <StyledDivider />
    </>
  );
});

export default SubscriptionBlock;
