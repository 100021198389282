import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
  gap: 10px;
`;

export const StyledProgressBar = styled.progress`
  width: 100%;
  height: 8px;
  border-radius: 8px;
  border: none;
  background-color: ${({ theme }) => theme.palette.black10};
  &::-moz-progress-bar {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: ${({ theme }) => theme.palette.red};
  }
  &::-webkit-progress-bar {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.black10};
  }
  &::-webkit-progress-value {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: ${({ theme }) => theme.palette.red};
  }
`;

export const ProgressCountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const ProgressCount = styled.span`
  color: ${({ theme }) => theme.palette.black95};
  font: ${({ theme }) => theme.typography.h5_mobile_new.font};
  text-transform: uppercase;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.h5.font};
  }
`;

export const ProgressText = styled.span`
  color: ${({ theme }) => theme.palette.black95};
  font: ${({ theme }) => theme.typography.body3_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.body3_new.font};
  }
`;

export const ProgressPercents = styled(ProgressText)`
  color: ${({ theme }) => theme.palette.black100};
`;
