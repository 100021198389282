import React, { memo, useMemo } from 'react';
import uuid from 'react-uuid';
import { ILesson } from '../../../../types';
import {
  LessonCardMobileContainer,
  LessonCardMobileContent,
  LessonCardMobileDescription,
  LessonCardImage,
  LessonCardNew,
  LessonDoneContainer,
  DoneText,
  StyledDoneIcon,
  AvailableMark,
  StyledBlockedIcon,
  LessonCardTitle,
  LessonCardDuration,
  LessonCardLineDivider,
  ShareActionButtonContainer,
  LessonCardMobileActionContainer,
  StyledFavoriteIcon,
  StyledLessonShareIcon,
  LessonCardDesktopContainer,
  LessonCardDesktopActionContainer,
  LessonCardDesktopDescription,
  LessonCardDesktopActionWrapper,
  LessonCardDesktopNameWrapper,
  LessonProgressBar,
  LessonProgressValue, LessonImageRadiusCopper,
} from '../../styles';
import { getMinutes } from '../../../../utils/helpers';
import { LOCAL_STORE_VIDEO_PERCENT } from '../../../../utils/constants';
import { useLoadImage } from '../../../../utils/hooks/useLoadImage';
import CourseSkeleton from '../../../../components/loaders/CourseSkeleton';

const ActionButtonsComponent = (
  {
    isAuth,
    lesson,
    onFavoriteClick,
    onShareClick,
  }: {
    isAuth: boolean,
    lesson: ILesson,
    onFavoriteClick: (lesson: ILesson) => void,
    onShareClick: (lesson: ILesson) => void,
  },
) => (
  <ShareActionButtonContainer>
    {
      isAuth && (
        <StyledFavoriteIcon
          favorite={lesson.isFavorite}
          onClick={() => onFavoriteClick(lesson)}
        />
      )
    }
    <StyledLessonShareIcon
      onClick={() => onShareClick(lesson)}
    />
  </ShareActionButtonContainer>
);

const LessonCardImageComponent = (
  {
    lesson,
    isAvailable,
    isFirst,
    onClick,
    progress,
    isMobile,
  } : {
    lesson: ILesson,
    isAvailable?: boolean,
    isFirst: boolean,
    onClick: (id: string) => void,
    progress?: string | null;
    isMobile: boolean;
  },
) => {
  const src = useLoadImage(lesson?.image?.url);
  return (
    src ? (
      <LessonImageRadiusCopper>
        <LessonCardImage
          image={`${lesson?.image?.url}`}
          available={isAvailable || isFirst}
          onClick={() => (isAvailable || isFirst ? onClick(lesson.id) : '')}
        >
          {
        !lesson.isDone ? (
          <LessonCardNew>
            new
          </LessonCardNew>
        ) : (
          <div />
        )
      }
          {
        lesson.isDone ? (
          <LessonDoneContainer>
            <DoneText>
              done
            </DoneText>
            <StyledDoneIcon />
          </LessonDoneContainer>
        ) : (
          <>
            {
              !isAvailable && isFirst && (
                <AvailableMark>
                  available for you
                </AvailableMark>
              )
            }
            {
              !isAvailable && !isFirst && (
                <StyledBlockedIcon />
              )
            }
          </>
        )
      }

          {progress && (
          <LessonProgressBar>
            <LessonProgressValue percent={progress} />
          </LessonProgressBar>
          )}
        </LessonCardImage>
      </LessonImageRadiusCopper>
    ) : (
      <CourseSkeleton
        isMobile={isMobile}
        webHeight={236}
        webWidth={408}
        mobileHeight={96}
        mobileWidth={164}
        webRadius={20}
        mobileRadius={12}
      />
    )
  );
};

const Lesson = memo<
{
  lesson: ILesson,
  isMobile: boolean,
  isAuth: boolean,
  isAvailable?: boolean
  isFirst: boolean
  onClick:(id: string) => void,
  onFavoriteClick:(lesson: ILesson) => void,
  onShareClick:(lesson: ILesson) => void,
}>((
      {
        isMobile,
        lesson,
        isAvailable,
        onClick,
        isFirst,
        onFavoriteClick,
        onShareClick,
        isAuth,
      },
    ) => {
      const savedProgressPercent = useMemo(
        () => localStorage.getItem(
          `${LOCAL_STORE_VIDEO_PERCENT}${lesson?.video?.id}`,
        ),
        [],
      );

      return (isMobile ? (
        <LessonCardMobileContainer key={uuid()}>
          <LessonCardMobileContent key={uuid()}>
            <LessonCardImageComponent
              key={uuid()}
              lesson={lesson}
              isAvailable={isAvailable}
              isFirst={isFirst}
              onClick={onClick}
              progress={savedProgressPercent}
              isMobile={isMobile}
            />
            <LessonCardMobileActionContainer>
              <LessonCardTitle>
                {lesson.name}
              </LessonCardTitle>
              <LessonCardDuration>
                {getMinutes(lesson.duration ?? 0)}
                {' '}
                min
              </LessonCardDuration>
              <LessonCardLineDivider />
              <ActionButtonsComponent
                isAuth={isAuth}
                lesson={lesson}
                onShareClick={onShareClick}
                onFavoriteClick={onFavoriteClick}
              />
            </LessonCardMobileActionContainer>
          </LessonCardMobileContent>
          <LessonCardMobileDescription
            key={uuid()}
          >
            {lesson.description}
          </LessonCardMobileDescription>
        </LessonCardMobileContainer>
      ) : (
        <LessonCardDesktopContainer key={uuid()}>
          <LessonCardImageComponent
            key={uuid()}
            lesson={lesson}
            isAvailable={isAvailable}
            isFirst={isFirst}
            onClick={onClick}
            progress={savedProgressPercent}
            isMobile={isMobile}
          />
          <LessonCardDesktopActionContainer key={uuid()}>
            <LessonCardDesktopActionWrapper key={uuid()}>
              <LessonCardDesktopNameWrapper key={uuid()}>
                <LessonCardTitle key={uuid()}>
                  {lesson.name}
                </LessonCardTitle>
                <LessonCardDuration key={uuid()}>
                  {getMinutes(lesson.duration ?? 0)}
                  {' '}
                  min
                </LessonCardDuration>
              </LessonCardDesktopNameWrapper>
              <ActionButtonsComponent
                key={uuid()}
                isAuth={isAuth}
                lesson={lesson}
                onShareClick={onShareClick}
                onFavoriteClick={onFavoriteClick}
              />
            </LessonCardDesktopActionWrapper>
            <LessonCardLineDivider />
            <LessonCardDesktopDescription key={uuid()}>
              {lesson.description}
            </LessonCardDesktopDescription>
          </LessonCardDesktopActionContainer>
        </LessonCardDesktopContainer>
      ));
    });

export default Lesson;
