import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  get,
  post,
} from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import {
  IErrorResponse, IFriendsReponse,
} from '../../../types';
import { IFriendRequest, ISubscription } from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse,
}

export const getSubscriptions = createAsyncThunk<
ISubscription[], void, ICommonChunkConfig>(
  'subscription/getSubscriptions',
  async (data, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.SUBSCRIPTION, params: {} });
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const createFriendSubscription = createAsyncThunk<
IFriendsReponse, IFriendRequest, ICommonChunkConfig>(
  'subscription/createFriendSubscription',
  async (data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.FRIENDS, data });
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);
