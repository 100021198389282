import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import {
  ArrowContainer,
  ArrowIcon,
  Options,
  SubscriptionContainer,
  Title,
} from '../../styles';
import Card from '../../components/SubscriptionCard';
import PremiumCard from '../../components/SubscriptionCardPremium';
import { IProfileResponse, ISubscription } from '../../../../types';
import Friends from '../Friends';
import { APP_ROUTES } from '../../../../core/router/appRoutes';

interface ISubscriptionsProps {
  profile: IProfileResponse | null,
  subscriptions: ISubscription[],
}

const Subscription = memo(({ profile, subscriptions }: ISubscriptionsProps) => {
  const [isFriendsOpen, setIsFriendsOpen] = useState(false);
  const navigate = useNavigate();
  const isSubscribed = useMemo(() => (
    profile?.isFriend || profile?.isSubscribed
  ), [profile]);

  const handleOpen = useCallback((isOpen: boolean) => {
    setIsFriendsOpen(isOpen);
  }, []);

  return (
    !isFriendsOpen ? (
      <SubscriptionContainer>
        {isSubscribed ? (
          <>
            <Title>Your current plan</Title>
            {subscriptions.map((subscription) => (
              <PremiumCard key={uuid()} subscription={subscription} setOpen={setIsFriendsOpen} />
            ))}
            <ArrowContainer onClick={() => navigate(APP_ROUTES.SUBSCRIPTION)}>
              <Options>Special Pricing Options</Options>
              <ArrowIcon />
            </ArrowContainer>
          </>
        ) : (
          <>
            <Title>Availible plan</Title>
            <Card />
          </>
        )}
      </SubscriptionContainer>
    ) : (
      <Friends setOpen={handleOpen} />
    )
  );
});

export default Subscription;
