export const COOKIE_ACCESS_TOKEN_NAME = 'accessToken';

export const LOCAL_STORAGE_REFRESH_TOKEN_NAME = 'refreshToken';

export const SOMETHING_WENT_WRONG = 'Something went wrong';

export const REDIRECT_URL_NAME = 'redirectName';

export const BREAKPOINTS = {
  mobile: 767,
  desktop: 1020,
};

export const fontFamily = 'Labil Grotesk Trial, sans-serif';

export const EMAIL_REGEXP = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/g;

export const LOCAL_STORE_VIDEO_KEY = 'videoProgress_entity_';
export const LOCAL_STORE_VIDEO_PERCENT = 'videoProgress_percent_';

export const ERROR_MESSAGES_MAPPING = {
  EMAIL_EXIST: 'EMAIL_EXIST',
};
