import { RootState } from '../../store';

export const getCurrentUserSelector = (state: RootState) => state.auth.user;
export const getAccessTokenSelector = (state: RootState) => state.auth.accessToken;
export const getTempTokenSelector = (state: RootState) => state.auth.tempToken;
export const getLoadingUserSelector = (state: RootState) => state.auth.isLoading;
export const getLoginError = (state: RootState) => state.auth.loginError;
export const getVerifyError = (state: RootState) => state.verify.verifyError;
export const getCoursesSelector = (state: RootState) => state.courses.courses;
export const getCoursesLoadingSelector = (state: RootState) => state.courses.isLoading;
export const getLessonsSelector = (state: RootState) => state
  .courses
  .courses?.map((course) => course.lessons).flat() || null;
export const getCoursesErrorSelector = (state: RootState) => state.courses.coursesError;

export const getIsGlobalProfileLoadingSelector = (
  state: RootState,
) => state.globalProfile.isProfileLoading;
export const getGlobalProfileSelector = (state: RootState) => state.globalProfile.profile;
export const getGlobalProfileErrorSelector = (state: RootState) => state.globalProfile.profileError;
export const getGlobalProfileUrlSelector = (state: RootState) => state.globalProfile.profileUrl;
