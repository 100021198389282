import styled from 'styled-components';
import { ReactComponent as Icon } from '../../../../icons/back-black.svg';
import { ReactComponent as Info } from '../../../../icons/info-icon.svg';
import { ReactComponent as Plus } from '../../../../icons/plus-icon.svg';
import { ReactComponent as Delete } from '../../../../icons/delete.svg';

export const Block = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font: ${(props) => props.theme.typography.premiumButtonWeb.font};
  color: ${(props) => props.theme.palette.black100};
  cursor: pointer;
  position: relative;

  &:hover {
    & .popper-custom {
      display: flex;
      align-items: center;
    }
  }
`;

export const BackIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

export const DeleteIcon = styled(Delete)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const ArrowIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  rotate: calc(180deg);
`;

export const InfoIcon = styled(Info)`
  width: 16px;
  height: 16px;
`;

export const PlusIcon = styled(Plus)`
  width: 14px;
  height: 14px;
`;

export const MainBlock = styled.div`
  display: flex;
  width: 1100px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;  

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const SecondayBlock = styled(MainBlock)`
  gap: 56px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 43px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 48px;
    height: fit-content;
  }
`;

export const Title = styled.h2`
  font: ${(props) => props.theme.typography.h2.font};
  color: ${(props) => props.theme.palette.black100};
`;

export const SubTitle = styled.p`
  font: ${(props) => props.theme.typography.body4_new.font};
  color: ${(props) => props.theme.palette.black100};
`;

export const SmallText = styled.p`
  font: ${(props) => props.theme.typography.button_s.font};
  color: ${(props) => props.theme.palette.black100};
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const ManagerContainer = styled(PersonContainer)`
  width: 30%;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const FriendWrapper = styled(PersonContainer)`
  width: 70%;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Divider = styled.span`
  width: 100%;
  border: 0.5px solid ${(props) => props.theme.palette.black80};
`;

export const SecondaryTitle = styled.p`
  font: ${(props) => props.theme.typography.subtitle2_new.font};
  color: ${(props) => props.theme.palette.black100};
`;

export const AvatarContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const DefaultFriendsContainer = styled(AvatarContainer)<{ $friendsExit: boolean }>`
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.palette.black10};
  border-width: ${({ $friendsExit }) => ($friendsExit ? '0px 0px 1px 0px' : '0px 0px 0px 0px')};
  padding-bottom: ${({ $friendsExit }) => ($friendsExit ? '16px' : '0px')};
`;

export const GroupFriends = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FriendBox = styled(AvatarContainer)<{ $isFirst: boolean }>`
  justify-content: space-between;
  padding-top: ${({ $isFirst }) => ($isFirst ? '0px' : '16px')};
  border: 1px solid ${(props) => props.theme.palette.black10};
  border-width: ${({ $isFirst }) => ($isFirst ? '0px 0px 0px 0px' : '1px 0px 0px 0px')};
`;

export const Avatar = styled.img`
  border-radius: 50%;
  width: 56px;
  height: 56px;
`;

export const DefaultAvatarBox = styled.div`
  position: relative;
`;

export const PlusBox = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${(props) => props.theme.palette.red};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -3px;
`;

export const TitleAvatar = styled.p`
  font: ${(props) => props.theme.typography.body4_mobile.font};
  color: ${(props) => props.theme.palette.black80};
`;

export const SubstitleAvatar = styled(SubTitle)`
  color: ${(props) => props.theme.palette.black80};
`;
