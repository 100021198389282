import styled from 'styled-components';
import { ReactComponent as PrevIcon } from '../../../../../icons/prev-icon-button.svg';
import { ReactComponent as NextIcon } from '../../../../../icons/next-icon-button.svg';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 64px 16px;
  position: relative;
  background-color: ${(props) => props.theme.palette.white};
  gap: 64px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 96px 80px;
  }
`;

export const TextContentContainer = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_lowercase};
  color: ${(props) => props.theme.palette.black100};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h2_lowercase};
    max-width: 1094px;
  }
`;

export const StyledSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100vw;
  //width: 100%;
  //min-height: 100vh;
  overflow: hidden;
  padding: 0 16px;
  & .swiper {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    min-height: 0;
    min-width: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 80px;
  }
`;

export const StyledSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    gap: 57px;
    align-items: center;
  }
`;

interface IStyledImageProps {
  image: string
}

export const StyledAvatar = styled.div<IStyledImageProps>`
  background-image: url(${(props) => props.image});
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 200px;
    height: 200px;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  color: ${(props) => props.theme.palette.black100};
`;

export const StyledNameContainer = styled.div`
`;

export const StyledName = styled.span`
  ${(props) => props.theme.typography.subtitle1};
`;

export const StyledLocation = styled.span`
  ${(props) => props.theme.typography.body2_new};
  color: ${(props) => props.theme.palette.black65};
`;

export const SubTitle = styled.p`
  ${(props) => props.theme.typography.body_mobile_new};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body1_new};
    max-width: 1040px;
  }
`;

export const StyledNavigateButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 257px;
  }
`;

export const StyledNavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  svg {
    path, rect {
      stroke: ${(props) => props.theme.palette.black100};
    }
  }
`;

export const StyledPrevIcon = styled(PrevIcon)`
  width: 64px;
  height: 64px;
`;

export const StyledNextIcon = styled(NextIcon)`
  width: 64px;
  height: 64px;
`;
