import styled from 'styled-components';
import { ReactComponent as Icon } from '../../../icons/white-back-btn.svg';
import { ReactComponent as AddAvatar } from '../../../icons/add-avatar.svg';
import { ReactComponent as Done } from '../../../icons/done-check-icon.svg';
import { ReactComponent as Logo } from '../../../images/white-text-swanly.svg';
import { ReactComponent as OneText } from '../../../icons/one-subscription-text.svg';
import { ReactComponent as TwelveText } from '../../../icons/twelve-subscription-text.svg';
import {
  CardContentLeft, PremiumBadge, Price, PriceContainer, RedBadge, StyledDefaultButton,
} from '../../Profile/styles';
import { CodeInput } from '../../Profile/components/AddFriends/styles';

export const Wrapper = styled.div`
  width: 100%;
  padding: 64px 80px 80px 80px;
  background-color: ${(props) => props.theme.palette.black};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: fit-content;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    padding: 32px 16px;
  }
`;

export const Block = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.premiumButtonWeb.font};
  cursor: pointer;
`;

export const WhiteArrow = styled(Icon)`
  width: 16px;
  height: 16px;
`;

export const WhiteArrowDown = styled(Icon)`
  width: 16px;
  height: 16px;
  rotate: calc(-90deg);
`;

export const WhiteArrowForward = styled(Icon)`
  width: 16px;
  height: 16px;
  rotate: calc(-180deg);
`;

export const FreeTrialContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-bottom: 88px;
  border-bottom: 1px solid ${(props) => props.theme.palette.black80};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-bottom: 48px;
  }
`;

export const Box = styled.span`
  display: flex;
  gap: 12px;
  align-items: center;
  width: fit-content;
  height: fit-content;
`;

export const StyledLogo = styled(Logo)`
  width: 146px;
  height: 22px;
`;

export const StyledBadge = styled(PremiumBadge)`
  color: ${(props) => props.theme.palette.white};
  box-shadow: none;
`;

export const TextBox = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h1_mobile.font};
  }
`;

export const Title = styled.h2`
  font: ${(props) => props.theme.typography.h2_lowercase.font};
  color: ${(props) => props.theme.palette.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h1_mobile.font};
  }
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.body3_new.font};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.body3_mobile.font};
  }
`;

export const SmallText = styled.p`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.body5_mobile.font};
`;

export const WrapperSmall = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
`;

export const OptionsBox = styled(Block)`
  padding-top: 24px;
`;

export const OptionsText = styled.p`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.button_s.font};
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
`;

export const PremiumContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 16px;
  }
`;

export const OptionsCardsContainer = styled(CardsContainer)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const Card = styled.div<{ isActive: boolean }>`
  width: 362px;
  height: 238px;
  border-radius: 16px;
  background: linear-gradient(45deg, rgba(35, 35, 35, 0.75) 10%, rgba(162, 162, 162, 0) 90%);
  border: 1.5px solid ${({ isActive, theme }) => (isActive ? theme.palette.red : theme.palette.black95)};
  position: relative;
  padding: 30px 16px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 163px;
    height: 200px;
    padding: 16px;
  }
`;

export const BenefitsTitle = styled.h5`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.h5.font};
  text-align: center;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 88px;
  padding: 88px 0px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.palette.black80};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0px;
    gap: 48px;
  }
`;

export const CardsWrapper = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    gap: 64px;
  }
`;

export const BenefitsCard = styled.div`
  width: 362px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const SecondBenefitsCard = styled(BenefitsCard)`
  width: 236px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const BenefitCardTitle = styled.p`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.subtitle2_new.font};
`;

export const SecondTitle = styled(BenefitCardTitle)`
  margin-bottom: 10px;
`;

export const BenefitsImg = styled.img`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const BenefitCardSubTitle = styled.p`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.body4_new.font};
  opacity: 0.5;
`;

export const IconsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 162px;
`;

export const OptionsContainer = styled(BenefitsContainer)`
  border: none;
`;

export const OneTextIcon = styled(OneText)`
  position: absolute;
  right: 24px;
  top: 16px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: fit-content;
    height: 46px;
    right: 17px;
  }
`;
export const TwelveTextIcon = styled(TwelveText)`
  position: absolute;
  right: 24px;
  top: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: fit-content;
    height: 46px;
    right: 13px;
  }
`;

export const WhiteBox = styled.div`
  padding: 4px 12px;
  background-color:  ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.black100};
  font: ${(props) => props.theme.typography.subtitle2_new.font};
  position: absolute;
  border-radius: 80px;
  top: -20px;
  left: 110px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2px 10px;
    font: ${(props) => props.theme.typography.body4_mobile.font};
    top: -10px;
    left: 28px;
  }
`;

export const PriceWhite = styled(Price)`
  color: ${(props) => props.theme.palette.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h3_mobile.font};
  }
`;

export const BodyTextWhite = styled.p`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.body4_new.font};
`;

export const PriceContainerStyled = styled(PriceContainer)`
  align-items: baseline;
`;

export const CardContentLeftStyled = styled(CardContentLeft)`
  justify-content: flex-end;
`;

export const BodyHidden = styled(BodyTextWhite)`
  opacity: 0.7;
`;

export const SpecialCardContainer = styled.div`
  width: 491px;
  height: fit-content;
  border-radius: 16px;
  background: linear-gradient(45deg, rgba(35, 35, 35, 0.75) 10%, rgba(162, 162, 162, 0) 90%);
  border: 1.5px solid ${({ theme }) => theme.palette.black95};
  position: relative;
  padding: 30px 16px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const RedBadgeStyled = styled(RedBadge)`
  width: fit-content;
`;

export const SmStyledLogo = styled(Logo)`
  width: 96px;
  height: 15px;
`;

export const TypographyBox = styled.span`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 11px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  align-items: center;
`;

export const AddAvatarIcon = styled(AddAvatar)`
  width: 64px;
  height: 64px;
`;

export const DoneIcon = styled(Done)`
  width: 56px;
  height: 56px;
`;

export const TextContainer = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  width: 80%;
`;

export const PopperSubstitle = styled.p`
  font: ${(props) => props.theme.typography.caption_mobile_2.font};
  color: ${(props) => props.theme.palette.black25};
`;

export const StyledCodeInput = styled(CodeInput)`
  input {
    color: ${(props) => props.theme.palette.white};
  }
`;

export const SendBtn = styled(StyledDefaultButton)`
  min-width: fit-content;
  &:disabled {
    background-color: ${(props) => props.theme.palette.black95};
  }
`;

export const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
`;

export const ModalTitle = styled.h5`
  font: ${(props) => props.theme.typography.h5.font};
  color: ${(props) => props.theme.palette.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h5_popper.font};
  }
`;
