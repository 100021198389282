import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CONTACT_EMAIL } from '../../../../containers/Contact/constants';
import { logout } from '../../../../containers/Login/services';
import { useAppDispatch } from '../../../../hooks';
import { IProfileResponse } from '../../../../types';
import { APP_ROUTES } from '../../../router/appRoutes';
import {
  CreditCardIcon,
  CreditCardIconBlack,
  EmptyProfileLogo,
  LinkStyled,
  LinkText,
  LogoutIcon,
  LogoutIconBlack,
  LogoutSpan,
  ProfileAvatar, ProfileCard, ProfileCardHrLine, ProfileContainer,
  ProfileEmail, ProfileLinksBlock,
  ProfileName, ProfileNameBlock, ProfilePhotoSquare, SecondaryButton,
  SupportIcon, SupportIconBlack, VectorElement,
} from './styles';
import { ProfileCardColors } from './types';
import { resetGlobalProfile } from '../../../slices/profile';
import { resetLocalProfile } from '../../../../containers/Profile/slices';

interface ProfileCardProps {
  profile: IProfileResponse,
  theme: ProfileCardColors,
}

export const ProfileCardComponent = memo<ProfileCardProps>(({ profile, theme }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutProfile = async () => {
    dispatch(logout());
    dispatch(resetGlobalProfile());
    dispatch(resetLocalProfile());
    navigate(APP_ROUTES.MAIN);
  };
  return (
    <ProfileCard blackTheme={theme === ProfileCardColors.DARK}>
      <ProfileContainer>
        {profile.avatar?.url ? (
          <ProfileAvatar blackTheme={theme === ProfileCardColors.DARK}>
            <ProfilePhotoSquare src={profile.avatar.url} />
          </ProfileAvatar>
        )
          : (
            <ProfileAvatar blackTheme={theme === ProfileCardColors.DARK}>
              <EmptyProfileLogo />
            </ProfileAvatar>
          )}
        <ProfileNameBlock>
          <ProfileName blackTheme={theme === ProfileCardColors.DARK}>
            {profile.firstName}
          </ProfileName>
          <ProfileEmail>{profile.email}</ProfileEmail>
          <SecondaryButton
            blackTheme={theme === ProfileCardColors.DARK}
            onClick={() => navigate(APP_ROUTES.PROFILE_INFO)}
          >
            Edit profile
          </SecondaryButton>
        </ProfileNameBlock>
      </ProfileContainer>
      {theme === ProfileCardColors.WHITE && <ProfileCardHrLine />}
      <ProfileLinksBlock>
        <LinkStyled to={APP_ROUTES.PROFILE_SUBSCRIPTION}>
          {theme === ProfileCardColors.DARK ? <CreditCardIcon /> : <CreditCardIconBlack />}
          <LinkText blackTheme={theme === ProfileCardColors.DARK}>My subscription</LinkText>
        </LinkStyled>
        <LinkStyled
          to="#"
          onClick={() => {
            window.location.href = `mailto:${CONTACT_EMAIL}`;
          }}
        >
          {theme === ProfileCardColors.DARK ? <SupportIcon /> : <SupportIconBlack />}
          <LinkText blackTheme={theme === ProfileCardColors.DARK}>Help & Support</LinkText>
        </LinkStyled>
        {theme === ProfileCardColors.WHITE && <ProfileCardHrLine $forLinks />}
        <LogoutSpan onClick={() => logoutProfile()}>
          {theme === ProfileCardColors.DARK ? <LogoutIcon /> : <LogoutIconBlack />}
          <LinkText blackTheme={theme === ProfileCardColors.DARK}>Log out</LinkText>
        </LogoutSpan>
      </ProfileLinksBlock>
      {theme === ProfileCardColors.WHITE && <VectorElement /> }
    </ProfileCard>
  );
});
