import React, {
  JSX, memo, useEffect, useRef, useState,
} from 'react';
import { PlayerBackDrop, PlayerContainer } from './styles';
import { IMedia } from '../../../../types';
import { StyledClose } from '../../../../components/common/styles';
import { LOCAL_STORE_VIDEO_KEY, LOCAL_STORE_VIDEO_PERCENT } from '../../../../utils/constants';

interface IPlayer {
  video: IMedia
  title?: string | JSX.Element
  poster?: IMedia
  entityId: string
  onCloseClick: () => void
  onProgress: (entityId: string) => void
}

const Player = memo<IPlayer>((
  {
    video,
    entityId,
    poster,
    onCloseClick,
    onProgress,
    title,
  },
) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isProgressSend, progressSend] = useState<boolean>(false);

  useEffect(() => {
    // Получаем сохраненный прогресс из localStorage
    const savedProgress = localStorage.getItem(`${LOCAL_STORE_VIDEO_KEY}${video?.id}`);
    if (videoRef?.current) {
      if (savedProgress) {
        videoRef.current.currentTime = Number(savedProgress);
      }
    }
  }, []);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      const percentWatched = (currentTime / duration) * 100;

      const savedProgress = localStorage.getItem(`${LOCAL_STORE_VIDEO_KEY}${video?.id}`);

      if (!savedProgress || (parseInt(savedProgress, 10) < currentTime)) {
        localStorage.setItem(`${LOCAL_STORE_VIDEO_KEY}${video?.id}`, String(currentTime));
        localStorage.setItem(`${LOCAL_STORE_VIDEO_PERCENT}${video?.id}`, String(Math.round(percentWatched)));
      }

      if (percentWatched >= 60 && !isProgressSend) {
        onProgress(entityId);
        progressSend(true);
      }
    }
  };

  return (
    <PlayerContainer>
      <PlayerBackDrop>
        {title}
        <StyledClose onClick={() => onCloseClick()} />
      </PlayerBackDrop>
      <video
        onTimeUpdate={handleTimeUpdate}
        disablePictureInPicture
        controlsList="nodownload"
        ref={videoRef}
        controls
        autoPlay
        height="100%"
        poster={poster?.url}
        width="100%"
        src={video.url}
      >
        <track kind="captions" src="" default />
      </video>
    </PlayerContainer>
  );
});

export default Player;
