import styled from 'styled-components';
import { Link } from 'react-router-dom';
import introImageMobile from '../../../../../images/intro-mobile-background.jpg';
import introImageDesktop from '../../../../../images/intro-desktop-background.jpg';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 16px 0;
  min-height: calc(100vh - 48px);
  background-image: url(${introImageMobile});
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background-image: url(${introImageDesktop});
    padding: 0 0 0 80px;
    flex-direction: row;
    justify-content: center;
    min-height: calc(100vh - 60px);
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 48px;
    align-items: flex-start;
  }
`;

export const StyledImageContainer = styled.div`
  width: 100%;
  height: 50vh;
  background-position: top;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
`;

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 24px;
    align-items: flex-start;
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_lowercase};
  color: ${(props) => props.theme.palette.black100};
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h2_lowercase};
    text-align: left;
    max-width: 666px;
  }
`;

export const SubTitle = styled.p`
  ${(props) => props.theme.typography.body_mobile};
  color: ${(props) => props.theme.palette.black100};
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body};
    max-width: 624px;
    text-align: left;
  }
`;

export const ButtonTitle = styled.span`
  color: ${(props) => props.theme.palette.black100};
  font: ${(props) => props.theme.typography.button.font};
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 32px;
  height: 48px;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.palette.black100};
  border-radius: 80px;
  text-decoration: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: unset;
    padding: 0 48px;
    height: 64px;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: ${(props) => props.theme.palette.white};
`;
