import styled, { keyframes } from 'styled-components';
import { ReactComponent as SwanIcon } from '../../../icons/swan-icon.svg';

export const StyledIconButton = styled.button`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  color: ${(props) => props.theme.palette.white};
  border-radius: 80px;
  border: 1px solid ${(props) => props.theme.palette.white};
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  gap: 8px;
;
`;

const rotate = keyframes`
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
`;

export const StyledButtonLoader = styled(SwanIcon)`
  width: 26px;
  height: 21px;
  fill: ${(props) => props.theme.palette.white};
  animation: ${rotate} 0.75s linear infinite;
  path {
    fill: ${(props) => props.theme.palette.white};
  }
`;
