export interface ITokenResponse {
  accessToken:string;
  refreshToken:string;
}

export interface IOtpCodeResponse {
  tempToken: string;
}

export interface IVerificationCodeRequest {
  code: string;
  tempToken: string;
}

export interface IResendCodeRequest {
  email: string;
}

export interface IErrorResponse {
  message: string | string[];
  statusCode: number;
}

export interface ICommonChunkConfig {
  rejectValue: IErrorResponse,
}

export enum Role {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  USER = 'user',
}

export interface IUser {
  email: string;
  roles: Role[];
  id: string;
}

export interface IProfile {
  email: string;
  id: string;
  firstName: string;
  birthday: Date | null;
  avatar: IAvatar | null;
}

export interface IProfileResponse extends IUserProfile {
  email: string,
}

export interface ICurrentUserResponse extends IUser {}

export interface IListResponse<T> {
  list: T[],
  count: number
}

export interface IRefreshTokenRequest {
  refreshToken: string;
}

export interface IListRequest {
  page: number,
  pageSize: number,
}

export interface IAvatar {
  id: string,
  path: string,
  size?: number | null,
  url?: string | null
}

export enum SubscriptionCategory {
  SIMPLE = 'simple',
  PREMIUM = 'premium',
}

export enum ProductID {
  MONTH = 'Month',
  YEAR = 'Year',
  FRIENDS = 'Friends',
  STUDENTS = 'Students',
}

export interface ISubscription {
  id: string;
  appAccountToken: string;
  originalTransactionId: string;
  productId: ProductID;
  purchaseDate: string | null;
  expiresDate: string | null;
  created: string | null;
  updated: string | null;
}

export interface IStudent {
  isVerified: boolean,
  programId: string;
}

export interface IUserProfile {
  id: string,
  firstName?: string | null,
  birthday?: Date | null,
  avatar: IAvatar,
  isFriend: boolean,
  isSubscribed: boolean,
  subscriptions: ISubscription[],
  student: IStudent,
}

export interface IUserResponse {
  id: string,
  email: string,
  isVerified: boolean,
  code: number,
  roles: Role[],
  profile: IUserProfile,
  lastLogin: Date | null,
}

export interface IMedia {
  id: string,
  path: string,
  size: number,
  url: string
}

export interface IVideo extends IMedia {
  duration: number,
}

export interface IMediaCardEntity {
  position: number,
  name: string,
  description: string,
  id: string,
  image: IMedia,
  previewOption: 'image' | 'video'
  isFavorite: boolean
}

export interface ILesson extends IMediaCardEntity {
  duration: number,
  isDone: boolean,
  lessonPreview: IMedia,
  lessonVideoPreview: IVideo,
  video: IVideo
}

export interface ITrainer {
  id: string,
  name: string,
  position: string,
  description: string,
  avatar: IMedia
}

export interface ICourse extends IMediaCardEntity {
  level: string[],
  category: string,
  shortDescription: string,
  isDraft: boolean,
  isNew: boolean,
  isPopular: boolean,
  lessons: ILesson[],
  coursePreview: IMedia,
  courseVideoPreview: IVideo,
  trainer: ITrainer,
  duration: number
}

export interface ICoursesResponse {
  courses: ICourse[],
  count: number
}

export interface IUpdateProfileRequest {
  firstName: string;
  birthday: Date | null;
}

export interface IFriend {
  id: string;
  email: string;
  avatar: IAvatar;
  firstName: string;
  birthday: Date | null;
}

export interface IFriendsReponse {
  friends: IFriend[];
  manager: IFriend;
}

export interface IFriendsRequest {
  showError: boolean
}

export interface IFriendCodeReponse {
  id: string;
  code: string;
  created: Date | null
}

export interface IRemoveFriendRequest {
  userId: string
}
