import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  //height: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //min-height: 100vh;
  //overflow-y: hidden;
`;
