import React, { memo } from 'react';
import { MenuLink, StyledWebLinksButton } from '../../styles';

interface WebMenuLinkProps {
  link: string;
  label: string;
}

const WebMenuLink = memo<WebMenuLinkProps>(({ link, label }) => (
  <MenuLink to={link}><StyledWebLinksButton>{label}</StyledWebLinksButton></MenuLink>
));

export default WebMenuLink;
