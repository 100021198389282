import React, { memo } from 'react';
import {
  SpecialCardContainer,
  BodyHidden,
  BodyTextWhite,
  Box,
  CardContentLeftStyled,
  PriceContainerStyled,
  PriceWhite,
  StyledBadge,
  SmStyledLogo,
  WhiteArrowForward,
  Block,
  OptionsText,
  TypographyBox,
} from '../../styles';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import { Divider } from '../../../Profile/containers/Friends/style';
import { StyledDefaultButton } from '../../../Profile/styles';
import { ISubscriptionMapping } from '../../../Profile/types';

interface IFriendsCardProps {
  subscription: ISubscriptionMapping;
  description: string;
  handleOpen: () => void;
}

const FriendsCard = memo(({
  subscription,
  description,
  handleOpen,
}: IFriendsCardProps) => {
  const { period, price, text } = subscription;

  return (
    <SpecialCardContainer>
      <CardContentLeftStyled>
        <Box>
          <SmStyledLogo />
          <StyledBadge>Friends</StyledBadge>
        </Box>
        <TypographyBox>
          <BodyHidden>{`billed ${text}`}</BodyHidden>
          <PriceContainerStyled>
            <PriceWhite>{`$${price}`}</PriceWhite>
            <BodyTextWhite>{`/ ${period}`}</BodyTextWhite>
          </PriceContainerStyled>
        </TypographyBox>
        <BodyTextWhite>{description}</BodyTextWhite>
        <StyledDefaultButton
          type="button"
          isLoading={false}
        >
          <StyledDefaultButtonTitle>
            Get Premium
          </StyledDefaultButtonTitle>
        </StyledDefaultButton>
        <Divider />
        <Block onClick={handleOpen}>
          <OptionsText>I have an invitation</OptionsText>
          <WhiteArrowForward />
        </Block>
      </CardContentLeftStyled>
    </SpecialCardContainer>
  );
});

export default FriendsCard;
