import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import FreeTrial from './components/FreeTrial';
import Benefits from './components/Benefits';
import PageWrapper from './components/PageWrapper';
import SpecialPricingOptions from './components/SpecialPricingOptions';
import useMouseScroll from '../../utils/hooks/useMouseScroll';
import { getSubscriptions } from './services';
import { getIsLoadingSelector, getSubscriptionsSelector } from './selectors';
import { useAppDispatch, useAppSelector } from '../../hooks';
import PageLoader from '../../components/loaders/PageLoader';

const Subscription = memo(() => {
  const [isMoved, moved] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const subscriptions = useAppSelector(getSubscriptionsSelector);
  const isLoading = useAppSelector(getIsLoadingSelector);
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!subscriptions) {
      dispatch(getSubscriptions());
    }
  }, [subscriptions]);

  useMouseScroll(() => {
    if (isMoved) return;
    moved(true);
  });

  useEffect(() => {
    if (isMoved && ref && ref.current) {
      window.scrollTo(0, ref.current.offsetTop - 88);
    }
  }, [ref, isMoved]);

  const scrollToOptions = () => {
    if (ref2 && ref2.current) {
      window.scrollTo(0, ref2.current.offsetTop - 88);
    }
  };

  if (isLoading) return <PageLoader />;

  return (
    <PageWrapper>
      <FreeTrial scroll={scrollToOptions} subscriptions={subscriptions || []} />
      <Benefits ref={ref} />
      <SpecialPricingOptions ref={ref2} subscriptions={subscriptions || []} />
    </PageWrapper>
  );
});

export default Subscription;
