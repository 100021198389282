import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  getFriendCodeSelector,
  getProfileFriendsSelector,
} from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  getFriends, regenerateFriendCode, deleteFriend, getFriendCode, generateFriendCode,
} from '../../services';
import Manager from '../../components/Manager';
import FriendsWrapper from '../../components/FriendsWrapper';
import FriendsContainer from '../../components/FriendsContainer';
import { getGlobalProfileSelector } from '../../../../core/selectors';

interface IFriendsProps {
  setOpen: (isOpen: boolean) => void;
}

const Friends = ({ setOpen }: IFriendsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAddFriendsOpen, setIsAddFriendsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const friends = useAppSelector(getProfileFriendsSelector);
  const code = useAppSelector(getFriendCodeSelector);
  const profile = useAppSelector(getGlobalProfileSelector);

  useEffect(() => {
    dispatch(getFriends({ showError: false }));
  }, []);

  const toggleAddFriends = useCallback((isAddOpen: boolean) => {
    setIsAddFriendsOpen(isAddOpen);
  }, []);

  const toggleIsOpen = useCallback((isOpenParam: boolean) => {
    setIsOpen(isOpenParam);
  }, []);

  const getCode = useCallback(() => {
    dispatch(getFriendCode()).then(({ payload }) => {
      if (!payload) {
        dispatch(generateFriendCode());
      }
    });
  }, []);

  const regenerateCode = useCallback(() => {
    dispatch(regenerateFriendCode());
  }, []);

  const removeFriend = useCallback((userId: string) => {
    dispatch(deleteFriend({ userId })).then(() => dispatch(getFriends({ showError: false })));
  }, []);

  return (
    <FriendsWrapper
      regenerateCode={regenerateCode}
      code={code}
      isAddFriendsOpen={isAddFriendsOpen}
      setOpen={setOpen}
      toggle={toggleAddFriends}
    >
      <Manager profile={profile} />
      <FriendsContainer
        removeFriend={removeFriend}
        isOpen={isOpen}
        getCode={getCode}
        toggle={toggleIsOpen}
        toggleAddFriends={toggleAddFriends}
        friends={friends}
      />
    </FriendsWrapper>
  );
};

export default Friends;
