export interface IContactForm {
  email: string;
}

export interface IRouterProps {
  isReleased: boolean
}

export interface INotifyMeResponse {

}

export interface IErrorResponse {
  message: string | string[];
  statusCode: number;
}

export const CustomErrors: Record<string, string> = {
  EMAIL_EXIST: 'Email is already exist',
  WRONG_CREDENTIALS: 'Email or password is incorrect',
  CODE_DOES_NOT_MATCH: 'Incorrect code',
  PASSWORD_DOES_NOT_MATCH: 'Password does not match',
  FRIEND_NOT_EXIST: 'Friends do not exist',
  FRIEND_CODE_NOT_EXIST: 'Code does not exist',
  FRIEND_EXIST: 'Friend exists',
};

export enum FavoriteContentType {
  COURSE = 'course',
  LESSON = 'lesson',
}

export interface IImageProps {
  image: string
}
