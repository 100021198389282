import styled from 'styled-components';
import ReactCodeInput from 'react-code-input';
import { ILabelProps } from './types';

export const InputFieldWarning = styled.span`
  color: ${(props) => props.theme.palette.red};
  margin-top: 0;
  font: ${(props) => props.theme.typography.body4.font};
  // @media(max-width: ${({ theme }) => theme.breakpoints.mobile}) {
  //   font-size: ${(props) => props.theme.typography.body2.fontSize};
  //   line-height: ${(props) => props.theme.typography.body2.lineHeight};
  // }
`;

export const StyledInputLabel = styled.label<ILabelProps>`
  color: ${({ disabled, error, theme }) => {
    if (error) {
      return theme.palette.red;
    }
    return disabled ? theme.palette.black10 : theme.palette.black10;
  }};
  font: ${(props) => props.theme.typography.caption2.font};
  // @media(max-width: 320px) {
  //   font-size: ${(props) => props.theme.typography.body2.fontSize};
  //   line-height: ${(props) => props.theme.typography.body2.lineHeight};
  // }
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ReactStyledCodeInput = styled(ReactCodeInput)<ILabelProps>`
  display: flex !important;
  justify-content: space-around;
  align-self: center;
  align-items: center;
  gap: 16px;
  position: relative;
  margin: 8px 0 8px 0;
  input {
    height: 56px;
    width: 48px;
    border-radius: 12px;
    padding: 16px;
    font: ${(props) => props.theme.typography.subtitle2.font};
    color: ${(props) => props.theme.palette.black5};
    border: 1px solid ${(props) => props.theme.palette.black40};
    background: transparent;
    box-shadow: unset;
    &:focus {
      border: 1px solid ${(props) => props.theme.palette.black40};
      transition: border-color 0.3s ease-in-out;
      outline: 0;
    }
    border-color: ${({ disabled, error, theme }) => {
    if (error) {
      return theme.palette.red;
    }
    return disabled ? theme.palette.black10 : theme.palette.black10;
  }};
  }
  
`;
