import styled from 'styled-components';
import { StyledInput } from '../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: center;
`;

export const Box = styled(Container)`
  gap: 16px;
  text-align: center;
`;

export const Subtitle = styled.p`
  width: 70%;
  font: ${(props) => props.theme.typography.subtitle3_new.font};
`;

export const Text = styled.p`
  font: ${(props) => props.theme.typography.subtitle2.font};
  color:  ${(props) => props.theme.palette.black65};
`;

export const BoldText = styled.p`
  font: ${(props) => props.theme.typography.premiumButtonWeb.font};
  cursor: pointer;
`;

export const CodeInput = styled(StyledInput)`
  input {
    font: ${(props) => props.theme.typography.subtitle2.font};
    border: 1px solid ${(props) => props.theme.palette.black80};
    text-align: center;
  }
`;
