import { createSlice } from '@reduxjs/toolkit';
import {
  getErrorMessage,
} from '../../../utils/helpers';
import { fetchCourses } from '../../services';
import { ICourse } from '../../../types';

export interface ICoursesState {
  isLoading: boolean
  courses: ICourse[] | null
  coursesError: string | null
}

const initialState: ICoursesState = {
  isLoading: false,
  courses: null,
  coursesError: null,
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCourses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchCourses.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.courses = payload.courses;
      },
    );
    builder.addCase(fetchCourses.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.coursesError = getErrorMessage(payload);
    });
  },
});

export default coursesSlice.reducer;
