export const CONTACT_DESCRIPTION = 'Discover the rhythm of life through dance, a blend of grace, balance, and powerful mental healing in an ever-evolving world.';
export const CONTACT_TITLE = 'JOIN THE MOVEMENT';
export const COMING_SOON = 'Coming soon...';
export const CONTACT_ADDRESS = '2972 Westheimer Rd. Santa Ana, Illinois 85486';
export const CONTACT_EMAIL = 'hello@swanlymethod.com';
export const PRIVACY_POLICY_URL = 'https://www.app-privacy-policy.com/live.php?token=lPlrrFveqXn0cEfEPq46tI8FMM3SNY6N';
export const INSTAGRAM_URL = 'https://instagram.com/swanlymethod?igshid=MzRlODBiNWFlZA==';
export const TERMS = 'https://www.app-privacy-policy.com/live.php?token=xPfA4XYXa3cTcMTfQKJ8pRKFPToArwnH';
// fake links
export const APP_URL = 'https://appstore.com/';
export const PAYMENT = 'https://payment.com/';
