import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CustomStyledInput from '../../../components/inputs/CustomStyledInput';
import { DefaultButton } from '../../../components/buttons/DefaultButton';
import { CancelButton } from '../../../components/modals/CustomModal/style';
import { ReactComponent as Arrow } from '../../../icons/chevron-right.svg';
import { ReactComponent as Logo } from '../../../icons/swanly-text-icon.svg';
import { ReactComponent as LogoWhite } from '../../../icons/swanly-text-white.svg';

export const ArrowIcon = styled(Arrow)`
  width: 20px;
  height: 20px;
`;

export const ArrowContainer = styled.span`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  cursor: pointer;
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const TabsContainer = styled.div`
  width: 90%;
  height: 100%;
  margin-top: 16px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 56px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 150px;
    margin-top: 60px;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  gap: 24px;
  border-bottom: 1px solid ${(props) => props.theme.palette.black10};
`;

export const Tab = styled.span`
  width: 50%;
  min-width: fit-content;
  padding: 10px 0px;
  color: ${({ theme }) => theme.palette.black100};
  font: ${({ theme }) => theme.typography.subtitle10.font};
  text-align: center;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-width: 220px;
    width: fit-content;
  }
`;

export const TabActive = styled(Tab)`
  border-bottom: 2px solid ${({ theme }) => theme.palette.black100};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: flex-start;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 100px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 46px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 748px;
  }
`;

export const StyledEmailContainer = styled.span`
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.palette.black10};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const EmailLabel = styled.label`
  font: ${({ theme }) => theme.typography.body6_mobile.font};
`;

export const EmailText = styled.p`
  font: ${({ theme }) => theme.typography.body4_new.font};
`;

export const StyledButton = styled(Link)`
  font: ${(props) => props.theme.typography.subtitle3.font};
  color: ${(props) => props.theme.palette.black5};
  text-decoration: none;
`;

export const StyledInput = styled(CustomStyledInput)`
  input {
    color: ${(props) => props.theme.palette.black100};
    border-color: ${(props) => props.theme.palette.black25};
    ${({ error, theme }) => error && `
      border-color: ${theme.palette.red};
  `};
  }
  label {
    color: ${(props) => props.theme.palette.black100};
  }
`;

export const DisabledInput = styled(StyledInput)`
  input {
    cursor: pointer;
  }
`;

export const StyledDefaultButton = styled(DefaultButton)`
  &:disabled {
    color: ${(props) => props.theme.palette.black40};
    background-color: ${(props) => props.theme.palette.black25};
    box-shadow: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const StyledPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const AvatarContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 162px;
  gap: 24px;
`;

export const AvatarButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AvatarImg = styled.img`
  width: 162px;
  height: 162px;
  border-radius: 56px;
`;

export const UploadBtn = styled(CancelButton)`
  height: 32px;
  padding: 0;
  width: 100%;
  min-width: 160px;
  &:disabled {
    cursor: initial;
  }
`;

export const DeleteBtn = styled(UploadBtn)`
  border: none;
`;

export const SubscriptionContainer = styled.div`
  width: 1100px;
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font: ${(props) => props.theme.typography.h2.font};
`;

export const SubTitle = styled.p`
  font: ${(props) => props.theme.typography.button_s.font};
`;

export const Options = styled(SubTitle)`
  margin-top: 1px;
`;

export const SubscriptionCard = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.black100};
  padding: 24px;
  display: flex;
  justify-content: space-between;
  height: 275px;
  border-radius: 16px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    height: fit-content;
    gap: 32px;
  }
`;

export const CardContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
`;

export const CardContentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;


  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: flex-start;
    gap: 32px;
  }
`;

export const Box = styled.span`
  display: flex;
  gap: 12px;
  align-items: center;
  width: fit-content;
  height: fit-content;
`;

export const PriceBox = styled.span`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

export const BodyText = styled.p`
  font: ${(props) => props.theme.typography.body4_new.font};
`;

export const BodyTextWhite = styled(BodyText)`
  color:${(props) => props.theme.palette.white};
`;

export const PremiumBadge = styled.span`
  padding: 6px 10px;
  border: 1px solid ${(props) => props.theme.palette.red};
  box-shadow: ${(props) => props.theme.shadow.primaryButtonGlowSm};
  text-align: center;
  border-radius: 16px;
  font: ${(props) => props.theme.typography.button_s.font};
`;

export const CancelSubscriptionText = styled.p`
  font: ${(props) => props.theme.typography.subtitle3_new.font};
  color: ${(props) => props.theme.palette.red};
  cursor: pointer;
`;

export const TransparentBtn = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  &:focus,
  &:active {
    outline: none;
  }
`;

export const RedBadge = styled.div`
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.red};
  color: ${(props) => props.theme.palette.white};
  padding: 4px 10px;
  font: ${(props) => props.theme.typography.caption2.font};
`;

export const StyledLogo = styled(Logo)`
  width: 96px;
  height: 15px;
`;

export const StyledLogoWhite = styled(LogoWhite)`
  width: 96px;
  height: 15px;
`;

export const TextBreak = styled(BodyText)`
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 300px;
    text-align: end;
  }
`;

export const Price = styled.h1`
  font: ${(props) => props.theme.typography.h1_mobile.font}; 
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  flex-direction: row;
`;

export const StyledBanner = styled(SubscriptionCard)`
  padding: 0;
  border: none;
  height: fit-content;
`;

export const BannerBox = styled.div`
  position: relative;
  width: 100%;
`;

export const PremiumBadgeWhite = styled(PremiumBadge)`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.body3.font}; 
`;

export const UpgradeBtn = styled(StyledDefaultButton)`
  height: 64px;
  width: 181px;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;

  position: absolute;
  top: 40px;
  left: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    top: 24px;
    left: 20px;
  }
`;

export const SmallBlackBtn = styled(CancelButton)`
  height: 32px;
  padding: 0px 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    position: absolute;
    top: 24px;
  }
  span {
    font: ${(props) => props.theme.typography.button_s.font};
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: flex-start;
  }
`;
