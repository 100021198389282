import React, {
  FC, PropsWithChildren, useMemo,
} from 'react';
import Modal from 'react-modal';
import {
  CancelButton,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalStyles,
  OverlayStyles,
  StyledClose,
  Title,
} from './style';
import { StyledDefaultButtonTitle } from '../../buttons/DefaultButton/styles';
import { StyledDefaultButton } from '../../../containers/Profile/styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../../utils/constants';
import { IModalProps, IModalStyleProps } from './types';

Modal.setAppElement('#root');

const ModalStyle = (props: React.ComponentPropsWithRef<'div'>, children: React.ReactNode, modalProps: IModalStyleProps) => (
  <ModalStyles {...props} {...modalProps}>{children}</ModalStyles>
);
const OverlayStyle = (props: React.ComponentPropsWithRef<'div'>, contentElement: React.ReactElement, modalProps: IModalStyleProps) => (
  <OverlayStyles {...props} {...modalProps}>{contentElement}</OverlayStyles>
);

const CustomModal: FC<PropsWithChildren<IModalProps>> = ({
  children,
  isOpen,
  onClose,
  title,
  submitText = 'Save Changes',
  cancelText = 'Cancel',
  isSubmitDisabled = false,
  onCancelButtonClick,
  onSubmitClick,
  isLoading = false,
  isTitleCented = false,
  isPrimaryTheme = true,
  headerSm = false,
  modalWidth = 589,
  isBtnsCented = false,
}) => {
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.mobile, [width]);

  const cancelBtn = onCancelButtonClick ? (
    <CancelButton type="button" onClick={onCancelButtonClick}>
      <StyledDefaultButtonTitle>
        {cancelText}
      </StyledDefaultButtonTitle>
    </CancelButton>
  ) : null;

  const submitBtn = onSubmitClick ? (
    <StyledDefaultButton
      disabled={isSubmitDisabled}
      type="submit"
      onClick={onSubmitClick}
      isLoading={isLoading}
    >
      <StyledDefaultButtonTitle>
        {submitText}
      </StyledDefaultButtonTitle>
    </StyledDefaultButton>
  ) : null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="_"
      overlayClassName="_"
      contentElement={(props, ch) => ModalStyle(
        props,
        ch,
        { $isPrimaryTheme: isPrimaryTheme, $modalWidth: modalWidth },
      )}
      overlayElement={(props, ce) => OverlayStyle(
        props,
        ce,
        { $isPrimaryTheme: isPrimaryTheme },
      )}
    >
      <StyledClose onClick={onClose} />
      <ModalContainer>
        {title ? (
          <ModalHeader $isTitleCented={isTitleCented}>
            <Title $headerSm={headerSm} $isPrimary={isPrimaryTheme}>{title}</Title>
          </ModalHeader>
        ) : null}
        {children}
        {submitBtn || cancelBtn ? (
          <ModalFooter $isBtnsCented={isBtnsCented}>
            {isMobile ? submitBtn : cancelBtn}
            {isMobile ? cancelBtn : submitBtn}
          </ModalFooter>
        ) : null}
      </ModalContainer>
    </Modal>
  );
};

export default CustomModal;
