import React from 'react';
import {
  PopperSubTitle,
  PopperTitle,
  TextCenter,
  TextContainer,
  CircleArrow,
} from './styles';

const PopperContents = () => (
  <TextContainer>
    <TextCenter>
      <PopperTitle>Send an invitation code to your friend</PopperTitle>
      <PopperSubTitle>
        Сopy the code or share it via social networks
      </PopperSubTitle>
    </TextCenter>
    <CircleArrow />
    <TextCenter>
      <PopperTitle>Your friend will need to go to the subscription section</PopperTitle>
      <PopperSubTitle>
        And go to special pricing options
      </PopperSubTitle>
    </TextCenter>
    <CircleArrow />
    <TextCenter>
      <PopperTitle>
        In Friends subscription click on &quot;I have an invite&quot; and enter the code
      </PopperTitle>
      <PopperSubTitle>
        Your friend will have access to swanly premium
      </PopperSubTitle>
    </TextCenter>
  </TextContainer>
);

export default PopperContents;
