import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { IChangePasswordForm, IChangePasswordProps } from '../../types';
import { changePasswordSchema } from '../../../../utils/validation/schemas';
import { StyledInput, StyledPasswordForm } from '../../styles';
import CustomModal from '../../../../components/modals/CustomModal';
import {
  getIsPasswordLoadingSelector,
  getIsSubmitSuccessfullSelector,
} from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setIsSubmitSuccessfull } from '../../slices';

export const ChangePasswordForm: FC<IChangePasswordProps> = ({
  onSubmit, isOpen, handleClose,
}) => {
  const isLoading = useAppSelector(getIsPasswordLoadingSelector);
  const isSubmitSuccessfull = useAppSelector(getIsSubmitSuccessfullSelector);
  const dispatch = useAppDispatch();
  const {
    reset,
    register,
    formState: {
      errors, isDirty, isValid,
    },
    getValues,
  } = useForm<IChangePasswordForm>({
    resolver: joiResolver(changePasswordSchema),
    mode: 'onBlur',
    defaultValues: {
      password: '',
      confirmPassword: '',
      currentPassword: '',
    },
  });

  const submit = () => {
    const values = getValues();
    onSubmit(values);
  };

  const closeModal = () => {
    handleClose();
    reset();
  };

  useEffect(() => {
    if (isSubmitSuccessfull) {
      closeModal();
      dispatch(setIsSubmitSuccessfull(false));
    }
  }, [isSubmitSuccessfull, isOpen]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={closeModal}
      title="Change password"
      onCancelButtonClick={closeModal}
      isSubmitDisabled={!isDirty || !isValid}
      onSubmitClick={submit}
      isLoading={isLoading}
    >
      <StyledPasswordForm>
        <StyledInput
          {...register('currentPassword')}
          label="Current password"
          placeholder="Enter current password"
          error={errors.currentPassword?.message}
          type="password"
        />
        <StyledInput
          {...register('password')}
          label="New password"
          placeholder="Create new password"
          error={errors.password?.message}
          type="password"
        />
        <StyledInput
          {...register('confirmPassword')}
          label="Confirm new password"
          placeholder="Confirm new password"
          error={errors.confirmPassword?.message}
          type="password"
        />
      </StyledPasswordForm>
    </CustomModal>
  );
};
