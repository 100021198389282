import React from 'react';
import {
  Header,
  PageContainer,
  Paragraph,
  SubHeader,
  UnorderedList,
} from './styles';

export default function PrivacyPolicy() {
  return (
    <PageContainer>
      <Header>Privacy Policy</Header>
      <SubHeader>LAST UPDATED: JULY 01, 2024</SubHeader>
      <Paragraph>
        Swanly Inc. (&quot;Swanly&quot;) provides this Privacy Policy to
        demonstrate our firm commitment to your privacy and privacy-related
        concerns. This Privacy Policy may be updated from time to time for any
        reason; each version will apply to information collected while it was in
        place. Updates and modifications may be made available on{' '}
        <a href="https://swanlymethod.com">swanlymethod.com</a>, and you are
        advised to consult this Privacy Policy regularly for any changes.{' '}
      </Paragraph>
      <Paragraph>
        If you have any questions or comments about this Privacy Policy or our
        use of your personally identifiable information, please contact us at{' '}
        <a href="mailto:support@swanlymethod.com">support@swanlymethod.com</a>.
      </Paragraph>
      <SubHeader>Information Collection and Use </SubHeader>
      <Paragraph>
        Swanly does not collect personal information -- names, addresses, phone
        numbers, e-mail addresses, and specific locations -- about you except
        when you specifically provide such information voluntarily; for example,
        when you provide Swanly with information in connection with a website
        registration.
      </Paragraph>
      <SubHeader>A Special Note About Children: </SubHeader>
      <Paragraph>
        Children are not eligible to use our services, and we ask that minors do
        not submit any personal information to us. If you are a minor, you can
        use this service only in conjunction with your parents or guardians. If
        we become aware that a child under 13 has provided us with personally
        identifiable information, we will delete such information from our
        files. We use your email address and, in rare situations, your mailing
        address or phone number, to contact you regarding administrative notices
        from time to time.
      </Paragraph>
      <Paragraph>
        As a valued visitor to Swanly, we will occasionally contact you,
        typically by e-mail (or any other way you prefer) to notify you of
        special promotions and up-to-date news, custom-tailored to your
        interests. If you do not wish to receive this personalized information,
        you can always let us know by emailing us at{' '}
        <a href="mailto:support@swanlymethod.com">support@swanlymethod.com</a>,
        and we will happily abide by your wishes.
      </Paragraph>
      <Paragraph>
        Swanly uses your Internet Protocol (IP) address to help diagnose
        problems with our computer server and to administer our website. Your IP
        address is used to help identify you and to gather broad demographic
        data. Your IP address contains no personal information about you.
      </Paragraph>
      <Paragraph>
        To ensure that all of your personal information is correct and
        up-to-date, at any time you may visit your profile via our Swanly
        application. Through your profile, you may: (a) review and update your
        personal information that Swanly has already collected; (b) choose
        whether or not you wish us to share some of your personal information in
        Swanly&rsquo;s community.
      </Paragraph>
      <Paragraph>
        Users of this website must promptly notify Swanly if personally
        identifiable information is lost, stolen, or used without permission. In
        such an event, we can help you reset your password, hold or cancel your
        account, and update our records accordingly.
      </Paragraph>
      <Paragraph>
        Swanly will require permission to access your camera, which is used to
        take photos when you voluntarily share your photos in the Swanly
        Community. Please make sure you have the right to share these pictures
        before sharing.
      </Paragraph>
      <Paragraph>
        Location permission is required for geotagging your posts. If enabled,
        your location will be shown to others when you make a post in the Swanly
        Community.{' '}
      </Paragraph>
      <Paragraph>
        Swanly requires permission to read your Phone State, such as Device ID,
        to bind your device with your Swanly account and Network Type to help
        avoid unnecessary data consumption.{' '}
      </Paragraph>
      <Paragraph>
        To request the deletion of your personal data that we have in the Swanly
        App, please find the &quot;Delete Account&quot; option in the Profile
        Settings, or you can email us at{' '}
        <a href="mailto:support@swanlymethod.com">support@swanlymethod.com</a>{' '}
        to request the deletion, and we will respond to your request in a
        reasonable timeframe. Upon your request, Swanly will permanently and
        irrevocably anonymize your data such that it can never be reconstructed
        to identify you as an individual.
      </Paragraph>{' '}
      <Paragraph>
        When you participate in the active rankings, Swanly will use your IP
        address to obtain country and region information. Your IP address
        contains no personal information about you. You can still reset your
        region information on the Profile page.
      </Paragraph>
      <SubHeader>Data Protection </SubHeader>{' '}
      <Paragraph>
        Protecting users&apos; personal information is our top priority. To
        ensure the security of sensitive data, we have implemented the following
        measures:
      </Paragraph>
      <UnorderedList>
        <li>
          <b>Data Encryption:</b> We use advanced encryption technology to
          secure the transmission and storage of sensitive data.
        </li>
        <li>
          <b>Access Control:</b> Only authorized personnel can access sensitive
          data due to strict access control measures.
        </li>
        <li>
          <b>Secure Data Storage: </b>Sensitive data is stored in secure servers
          and protected environments to prevent unauthorized access.{' '}
        </li>
      </UnorderedList>
      <Paragraph>
        We collect data to enhance the functionality of the application, improve
        user experience, customize features, and optimize performance. Our data
        collection practices comply with applicable data protection laws and
        regulations.
      </Paragraph>{' '}
      <SubHeader>Cookies</SubHeader>{' '}
      <Paragraph>
        We use &quot;cookie&quot; technology to collect non-personal website
        usage data and to improve the Site and our service. Cookies are files a
        website can use to recognize repeat users and allow a website to track
        web usage behavior. Cookies take up minimal room on your computer and
        cannot damage your computer&rsquo;s files. Swanly may use both session
        cookies and persistent cookies to better understand how you interact
        with the <a href="https://swanlymethod.com">swanlymethod.com</a> website
        and services, monitor aggregate usage by our users and web traffic
        routing on the website, and improve the website and our services. A
        session cookie enables certain features of the site and our service and
        is deleted from your computer when you disconnect from or leave{' '}
        <a href="https://swanlymethod.com">swanlymethod.com</a>. A persistent
        cookie remains after you close your browser and may be used by your
        browser on subsequent visits to{' '}
        <a href="https://swanlymethod.com">swanlymethod.com</a>. Persistent
        cookies can be removed by following your web browser&apos;s help file
        directions. Most Internet browsers automatically accept cookies. You can
        instruct your browser, by editing its options, to stop accepting cookies
        or to prompt you before accepting a cookie from the websites you visit.
      </Paragraph>
      <SubHeader>Information Sharing and Disclosure</SubHeader>{' '}
      <Paragraph>
        Swanly does not sell or rent personal information about its customers to
        any third parties at this time. In the event Swanly does engage in such
        commerce in the future, you will have an opportunity to remove yourself
        from any such lists before your inclusion therein by emailing us at{' '}
        <a href="mailto:support@swanlymethod.com">support@swanlymethod.com</a>.
        Please exercise caution when deciding to disclose your personal
        information. This website also contains links to other websites. Swanly
        is not responsible for the privacy practices or the content of such
        websites.
      </Paragraph>{' '}
      <Paragraph>
        Swanly uses third-party advertising companies to serve ads, and the
        third-party advertising companies may use non-personally identifiable
        information about a user&rsquo;s visits to{' '}
        <a href="https://swanlymethod.com">swanlymethod.com</a> to provide
        advertisements about goods and services that may be of interest to
        users. Users of <a href="https://swanlymethod.com">swanlymethod.com</a>{' '}
        can find more information about these practices and their choices
        concerning these practices at networkadvertising.org.{' '}
      </Paragraph>
      <SubHeader>Compliance with Laws and Law Enforcement</SubHeader>{' '}
      <Paragraph>
        Swanly cooperates with all law enforcement inquiries and with all third
        parties to enforce their intellectual property or other rights. We may
        disclose any information about you to government or law enforcement
        officials or private parties as we, in our sole discretion, believe
        necessary or appropriate to respond to claims and legal processes
        (including subpoenas), to protect the property and rights of Swanly Inc.
        or a third party, the safety of the public or any person, to prevent or
        stop any illegal, unethical, or legally actionable activity, or to
        comply with the law. Swanly takes your privacy concerns very seriously.
        If you still have concerns about privacy issues after reading this
        Privacy Policy, please contact us at{' '}
        <a href="mailto:support@swanlymethod.com">support@swanlymethod.com</a>.
        This Privacy Policy is effective as of January 25, 2024. This Privacy
        Policy is not intended to and does not create any contractual or other
        legal rights in or on behalf of any party. We reserve the right, at any
        time and without notice, to add to, change, update, or modify this
        Privacy Policy, simply by posting such change, update, or modification
        on the Site. Any such change, update, or modification will be effective
        immediately upon posting on the Site.
      </Paragraph>
    </PageContainer>
  );
}
