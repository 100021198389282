import React, { memo } from 'react';
import ContentLoader from 'react-content-loader';
import { ISkeletonProps } from './types';
import { theme } from '../../../utils/theme';

const CourseSkeleton = ({
  isMobile,
  webHeight,
  webWidth,
  mobileHeight,
  mobileWidth,
  mobileRadius = 12,
  webRadius = 12,
}: ISkeletonProps) => {
  const { width, height, radius } = isMobile
    ? { width: mobileWidth, height: mobileHeight, radius: mobileRadius }
    : { width: webWidth, height: webHeight, radius: webRadius };

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={theme.palette.black5}
      foregroundColor={theme.palette.black8}
    >
      <rect width={width} height={height} rx={radius} ry={radius} />
    </ContentLoader>
  );
};

export default memo(CourseSkeleton);
