import React, { memo, useMemo } from 'react';
import { IProfileResponse } from '../../../types';
import { BREAKPOINTS } from '../../../utils/constants';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import HeaderMobile from './HeaderMobile';
import { HeaderWeb } from './HeaderWeb';
import {
  HeaderBackdrop,
  HeaderBackground, HeaderContainer,
} from './styles';

interface IHeaderProps {
  userEnabled: boolean;
  profile: IProfileResponse | null;
  handleSubmit: () => void;
  blackTheme: boolean;
}

const Header = memo<IHeaderProps>(({
  userEnabled, handleSubmit, blackTheme, profile,
}) => {
  const [width] = useWindowSize();
  const isMobile = width <= BREAKPOINTS.mobile;
  const isSubscribed = useMemo(() => (
    profile?.isFriend || profile?.isSubscribed
  ), [profile]);

  return (
    <>
      <HeaderBackdrop />
      <HeaderBackground blackTheme={blackTheme || false}>
        <HeaderContainer>
          {isMobile ? (
            <HeaderMobile
              subscribeStatus={isSubscribed}
              isMobile={isMobile}
              blackTheme={blackTheme}
              handleSubmit={handleSubmit}
            />
          ) : (
            <HeaderWeb
              profile={profile}
              userEnabled={userEnabled}
            />
          )}
        </HeaderContainer>
      </HeaderBackground>
    </>
  );
});

export default Header;
