import React, { ReactNode } from 'react';
import CustomModal from '../../../../components/modals/CustomModal';
import {
  MainBlock, BackIcon, SecondayBlock, TextBox, Row, Block,
} from '../../containers/Friends/style';
import { Title, SubTitle } from '../../styles';
import AddFriends from '../AddFriends';

interface IFriendsWrapperProps {
  children: ReactNode;
  setOpen: (open: boolean) => void;
  code: string;
  regenerateCode: () => void;
  toggle: (open: boolean) => void;
  isAddFriendsOpen: boolean
}

const FriendsWrapper = ({
  children,
  setOpen,
  regenerateCode,
  toggle,
  isAddFriendsOpen,
  code,
}: IFriendsWrapperProps) => (
  <>
    <MainBlock>
      <Block onClick={() => setOpen(false)}>
        <BackIcon />
        <p>Back</p>
      </Block>
      <SecondayBlock>
        <TextBox>
          <Title>Friends</Title>
          <SubTitle>You can add up to 5 accounts</SubTitle>
        </TextBox>
        <Row>
          {children}
        </Row>
      </SecondayBlock>
    </MainBlock>
    <CustomModal
      isOpen={isAddFriendsOpen}
      onClose={() => toggle(false)}
      modalWidth={489}
    >
      <AddFriends value={code} regenerateCode={regenerateCode} />
    </CustomModal>
  </>
);

export default FriendsWrapper;
