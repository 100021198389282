import React, { memo } from 'react';
import { ICourse } from '../../../../types';
import {
  DescriptionContainer, DescriptionContentContainer,
  DescriptionText,
  TrainerAvatar,
  TrainerContainer,
  TrainerContentContainer,
  TrainerName, TrainerPosition,
  TrainerTitle,
} from '../../styles';
import { ReactComponent as RightShevron } from '../../../../icons/right-small.svg';
import ProgressBar from '../ProgressBar';

const CourseDescription = memo<{
  course: ICourse,
  hasSubscription?: boolean
  onTrainerClick:() => void
}>((
      {
        course,
        hasSubscription,
        onTrainerClick,
      },
    ) => (
      <DescriptionContainer>
        <DescriptionContentContainer>
          {
          hasSubscription && <ProgressBar course={course} />
        }
          <DescriptionText dangerouslySetInnerHTML={{
            __html: `${course?.description}`,
          }}
          />
        </DescriptionContentContainer>
        <TrainerContainer onClick={() => onTrainerClick()}>
          <TrainerTitle>
            Trainer
          </TrainerTitle>
          <TrainerContentContainer>
            <TrainerAvatar
              image={`${course?.trainer?.avatar?.url}`}
            />
            <div>
              <TrainerName>
                {course?.trainer?.name}
                {' '}
                <RightShevron />
              </TrainerName>
              <TrainerPosition>
                {course?.trainer?.position}
              </TrainerPosition>
            </div>
          </TrainerContentContainer>
        </TrainerContainer>
      </DescriptionContainer>
    ));

export default CourseDescription;
