import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as PrevIcon } from '../../../../../icons/prev-icon-button.svg';
import { ReactComponent as NextIcon } from '../../../../../icons/next-icon-button.svg';

export const StyledDivider = styled.div`
  height: 1px;
  width: calc(100% - 128px);
  margin: 0 auto;
  background-color: ${(props) => props.theme.palette.black100};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(100% - 192px);
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 64px 0;
  background-color: ${(props) => props.theme.palette.white};
  gap: 40px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 96px 0;
  }
`;

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 16px 24px;
  gap: 40px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    padding: 0 0 24px 80px;
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_lowercase};
  color: ${(props) => props.theme.palette.black100};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h2_lowercase};
    max-width: 1094px;
  }
`;

export const SubTitle = styled.p`
  ${(props) => props.theme.typography.body_mobile};
  color: ${(props) => props.theme.palette.black100};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body};
    max-width: 890px;
  }
`;

export const ButtonTitle = styled.span`
  color: ${(props) => props.theme.palette.black100};
  font: ${(props) => props.theme.typography.button.font};
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.palette.black100};
  border-radius: 80px;
  text-decoration: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: unset;
    padding: 0 48px;
    height: 64px;
  }
`;

export const StyledSliderContainer = styled.div`
  width: 100vw;
  height: 184px;
  overflow: hidden;
  position: relative;
  //& {
  //  .swiper-wrapper{
  //    transition-timing-function : linear;
  //  }
  //}
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-height: 540px;
  }
`;

export const StyledSliderContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 184px;
`;

interface IStyledSlideProps {
  image: string
}

export const StyledSlide = styled.div<IStyledSlideProps>`
  background-image: url(${(props) => props.image});
  //width: 296px;
  height: 184px;
  border-radius: 12px;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-height: 540px;
    border-radius: 32px;
    padding: 32px;
    //min-width: 869px;
  }
`;

export const StyledNewContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

export const StyledNew = styled.div`
  padding: 3px 12px;
  text-align: center;
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.h6_mobile.font};
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.transparentGray30};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 8px 16px;
    border-radius: 6px;
    font: ${(props) => props.theme.typography.h6.font};
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: ${(props) => props.theme.palette.white};
`;

export const StyledCardLevel = styled.span`
  font: ${(props) => props.theme.typography.overline_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.subtitle4.font};
    text-transform: uppercase;
  }
`;
export const StyledCardTitle = styled.span`
  font: ${(props) => props.theme.typography.h2_mobile.font};
  margin-bottom: 4px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h2_lowercase.font};
    margin-bottom: 8px;
  }
`;
export const StyledCardSubTitle = styled.span`
  font: ${(props) => props.theme.typography.body5.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.body3_new.font};
  }
`;

export const StyledNavigateButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: calc(100% / 2 - 32px);
`;

export const StyledNavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  svg {
    path, rect {
      stroke: ${(props) => props.theme.palette.black10};
    }
  }
`;

export const StyledPrevIcon = styled(PrevIcon)`
  width: 64px;
  height: 64px;
`;

export const StyledNextIcon = styled(NextIcon)`
  width: 64px;
  height: 64px;
`;
