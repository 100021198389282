import React, { forwardRef } from 'react';
import {
  BenefitsContainer,
  BenefitsTitle,
  CardsWrapper,
  BenefitsCard,
  BenefitCardTitle,
  BenefitCardSubTitle,
  SecondBenefitsCard,
  SecondTitle,
  BenefitsImg,
} from '../../styles';
import benefit1 from '../../../../images/benefit-first.png';
import benefit2 from '../../../../images/benefit-second.png';
import benefit3 from '../../../../images/benefit-third.png';

const Benefits = forwardRef<HTMLDivElement>((_props, ref) => (
  <BenefitsContainer ref={ref}>
    <BenefitsTitle>Benefits await inside</BenefitsTitle>
    <CardsWrapper>
      <BenefitsCard>
        <BenefitsImg src={benefit1} alt="" />
        <BenefitCardTitle>Unlimited Access</BenefitCardTitle>
        <BenefitCardSubTitle>
          Dive into a comprehensive collection of courses, spotlighting our unique ballet,
          stretching, and cardio content designed for holistic well-being.
        </BenefitCardSubTitle>
      </BenefitsCard>
      <SecondBenefitsCard>
        <BenefitsImg src={benefit2} alt="" />
        <SecondTitle>Personalized Favorites</SecondTitle>
        <BenefitCardSubTitle>
          Handpick and save videos that resonate,
          building your own serene sanctuary
        </BenefitCardSubTitle>
      </SecondBenefitsCard>
      <BenefitsCard>
        <BenefitsImg src={benefit3} alt="" />
        <BenefitCardTitle>Community Connection</BenefitCardTitle>
        <BenefitCardSubTitle>
          Be among the pioneers in our dance and mental health-oriented growing community.
          Embrace, learn, and thrive together.
        </BenefitCardSubTitle>
      </BenefitsCard>
    </CardsWrapper>
  </BenefitsContainer>
));

export default Benefits;
