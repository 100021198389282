import React, { memo } from 'react';
import AppleLogin from 'react-apple-login';
import { SocialNetworkButton, StyledAppleIcon } from '../../../../components/wallpapers/auth/styles';
import config from '../../../../config';

const AppleSignIn = memo(() => (
  <AppleLogin
    clientId={config.appleClientId}
    responseType="id_token code"
    scope="email name openid"
    responseMode="form_post"
    redirectURI={`${config.appleRedirectUrl}`}
    state="web_login"
    render={({ onClick }) => (
      <SocialNetworkButton
        onClick={onClick}
        type="button"
      >
        <StyledAppleIcon />
      </SocialNetworkButton>
    )}
  />
));

export default AppleSignIn;
