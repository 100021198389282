import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as EmptyProfileIcon } from '../../../../icons/empty-profile.svg';
import { ReactComponent as CreditCard } from '../../../../icons/credit-card.svg';
import { ReactComponent as CreditCardBlack } from '../../../../icons/credit-card-black.svg';
import { ReactComponent as Support } from '../../../../icons/support.svg';
import { ReactComponent as SupportBlack } from '../../../../icons/support-black.svg';
import { ReactComponent as Logout } from '../../../../icons/logout.svg';
import { ReactComponent as LogoutBlack } from '../../../../icons/logout-black.svg';
import { ReactComponent as VectorIcon } from '../../../../icons/vector.svg';

export const ProfileContainer = styled.div`
    display: flex;
    width: 274px;
    `;

export const ProfileAvatar = styled.div<{ blackTheme?: boolean; }>`
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    background-color: ${(props) => (props.blackTheme ? 'white' : props.theme.palette.black10)};
    justify-content: center;
    border-radius: 32px;
    `;

export const ProfileNameBlock = styled.div`
    width: 154px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-evenly;
    margin-left: 24px;
    `;

export const ProfileLinksBlock = styled.div<{ blackTheme?: boolean; }>`
    width: 100%;
    height: 108px;
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => (props.blackTheme ? '32px' : '24px')};
    `;

export const ProfileCardHrLine = styled.hr<{ $forLinks?: boolean; }>`
    margin-top: ${(props) => (props.$forLinks ? '0' : '24px')};
    margin-bottom: ${(props) => (props.$forLinks ? '24px' : '0')};
    margin-right: 16px;
    border: 1px solid ${(props) => props.theme.palette.black10};
    `;

export const ProfileName = styled.span<{ blackTheme?: boolean; }>`
    font: ${(props) => props.theme.typography.body4_new.font};
    color: ${(props) => (props.blackTheme ? props.theme.palette.white : props.theme.palette.black)};
    word-break: break-all;
    `;

export const ProfileEmail = styled.span`
    font: ${(props) => props.theme.typography.body5.font};
    color: ${(props) => props.theme.palette.black40};
    word-break: break-all;
    `;

export const VectorElement = styled(VectorIcon)`
    width: 16px;
    height: 6px;
    position: absolute;
    top: -5px;
    right: 60px;
    `;

export const EmptyProfileLogo = styled(EmptyProfileIcon)`
    width: 50px;
    height: 50px;
    `;

export const EmptyProfileLogoWeb = styled(EmptyProfileIcon)`
    width: 18px;
    height: 18px;
    `;

export const CreditCardIcon = styled(CreditCard)`
    width: 16px;
    height: 16px;
    `;

export const CreditCardIconBlack = styled(CreditCardBlack)`
    width: 16px;
    height: 16px;
    `;

export const SupportIcon = styled(Support)`
    width: 16px;
    height: 16px;
    `;

export const SupportIconBlack = styled(SupportBlack)`
    width: 16px;
    height: 16px;
    `;

export const LogoutIcon = styled(Logout)`
    width: 16px;
    height: 16px;
    `;

export const LogoutIconBlack = styled(LogoutBlack)`
    width: 16px;
    height: 16px;
    `;

export const LinkText = styled.span<{ blackTheme?: boolean; }>`
    margin-left: 16px;
    font: ${(props) => (props.blackTheme ? props.theme.typography.body16_20.font : props.theme.typography.subtitle3_new.font)};    
    color: ${(props) => (props.blackTheme ? props.theme.palette.white : props.theme.palette.black)};
    `;

export const LinkStyled = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: left;
    text-decoration: none;
    color: white;
    cursor: pointer;
    margin-bottom: 24px;
    `;

export const LogoutSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: left;
    text-decoration: none;
    color: white;
    cursor: pointer;
    margin-bottom: 24px;
    `;

export const SecondaryButton = styled.button<{ blackTheme?: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 118px;
    height: 32px;
    border-radius: 80px;
    border: 1px solid;
    border-color: ${(props) => (props.blackTheme ? props.theme.palette.white : props.theme.palette.black)};
    color: ${(props) => (props.blackTheme ? props.theme.palette.white : props.theme.palette.black)};
    background-color: ${(props) => (props.blackTheme ? props.theme.palette.black : props.theme.palette.white)};
    font: ${(props) => props.theme.typography.premiumButtonMobile.font};
    margin-top: 16px;
    cursor: pointer;
    `;

export const FooterButtonStyled = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border-radius: 80px;
    border: 1px solid;
    box-shadow: ${(props) => (props.theme.shadow.footerButtonShadow)};
    border-color: ${(props) => (props.theme.palette.white)};
    color: ${(props) => (props.theme.palette.white)};
    background-color: ${(props) => (props.theme.palette.black)};
    font: ${(props) => props.theme.typography.premiumButtonMobile.font};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 0;
        width: 164px;
    }
    &:disabled {
        color: ${(props) => (props.theme.palette.black80)};
        background-color: ${(props) => (props.theme.palette.black95)};
        box-shadow: none;
      }
    `;

export const ProfileCard = styled.div<{ blackTheme?: boolean; }>`
    background-color: ${(props) => (props.blackTheme ? props.theme.palette.black : props.theme.palette.white)};
    width: ${(props) => (props.blackTheme ? '274px' : '306px')};
    height: ${(props) => (props.blackTheme ? '236px' : '322px')};
    padding-top: ${(props) => (props.blackTheme ? '40px' : '16px')};
    padding-left: 16px;
    box-shadow: ${(props) => (props.blackTheme ? 'none' : props.theme.shadow.profileCardShadow)};
    border-radius: ${(props) => (props.blackTheme ? '0' : '16px')};
    position: relative;
`;

export const ProfilePhoto = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    `;

export const ProfilePhotoSquare = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 32px;
    `;

export const HeaderBackground = styled.div<{ blackTheme?: boolean; }>`
    background-color: ${(props) => (props.blackTheme ? props.theme.palette.black : props.theme.palette.white)};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background-color: ${(props) => (props.blackTheme ? props.theme.palette.black : props.theme.palette.white)};
  }
`;
