import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as InstagramIcon } from '../../../../../icons/instagram.svg';
import { ReactComponent as LogoItem } from '../../../../../images/s-logo-vector.svg';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 64px 0 48px;
  background-color: ${(props) => props.theme.palette.black100};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row-reverse;
    justify-content: center;
    padding: 0 80px;
  }
`;

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 16px 48px;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: 94px;
    gap: 40px;
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_lowercase};
  color: ${(props) => props.theme.palette.white};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h2_lowercase};
  }
`;

export const SubTitle = styled.p`
  ${(props) => props.theme.typography.body_mobile};
  color: ${(props) => props.theme.palette.black40};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body};
  }
`;

export const ButtonTitle = styled.span`
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.button.font};
`;

export const StyledInstagramIcon = styled(InstagramIcon)`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.palette.white};
  path {
    fill: ${(props) => props.theme.palette.white};
  }
`;

export const StyledSliderContainer = styled.div`
  width: 100vw;
  height: 464px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  & {
    .swiper-wrapper{
      transition-timing-function : linear;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-width: 740px;
    min-height: 914px;
    height: 100vh;
    flex-direction: row;
    gap: 40px;
  }
`;

export const StyledSliderMobileContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  opacity: 0.5;
`;

export const StyledSliderDesktopContentContainer = styled.div`
  opacity: 0.5;
  width: 100%;
  height: 100%;
  & {
    .swiper-wrapper{
      transition-timing-function : linear;
    }
  }
`;

export const StyledLogoItem = styled(LogoItem)`
  width: 324px;
  height: 296.26px;
  position: absolute;
  top: 95px;
  left: 16px;
  z-index: 2;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 584px;
    height: 534px;
    top: 190px;
    left: 48px;
  }
`;

interface IStyledSlideProps {
  image: string
}

export const StyledMobileSlide = styled.div<IStyledSlideProps>`
  background-image: url(${(props) => props.image});
  width: 169px;
  height: 220px;
  border-radius: 12px;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDesktopSlide = styled.div<IStyledSlideProps>`
  background-image: url(${(props) => props.image});
  width: 350px;
  height: 455px;
  border-radius: 24px;
  //width: 28vw;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`;

export const StyledInstaLink = styled(Link)`
  text-decoration: none;
`;
