import React, { memo } from 'react';
import { ICourse } from '../../../../types';
import verticalMock from '../../../../images/vertical-mock.jpg';
import sliderMock from '../../../../images/background-slide-mock.jpg';
import {
  BannerContentContainer,
  BannerLevel,
  BannerTitle,
  Button,
  ButtonContainer,
  ButtonSubTitle,
  ButtonTitle, HeadBannerBackDrop,
  HeadBannerContainer,
  ShareButton,
  StyledBreadcrumbsContainer,
  StyledShareIcon,
} from '../../styles';
import Breadcrumbs from '../../../../components/breadcrumbs';
import { getCourseLevels } from '../../../../utils/helpers';

const CourseMainBanner = memo<
{
  course: ICourse,
  isMobile: boolean,
  hasSubscription?: boolean
}>((
  {
    course,
    isMobile,
    hasSubscription,
  },
) => (
  <HeadBannerContainer
    image={
      isMobile
        ? course?.coursePreview?.url ?? verticalMock
        : course?.image?.url ?? sliderMock
    }
  >
    <HeadBannerBackDrop>
      <StyledBreadcrumbsContainer>
        {(course && course.name) && (
          <Breadcrumbs match={{
            [course.id]: course.name,
          }}
          />
        )}

      </StyledBreadcrumbsContainer>
      <BannerContentContainer>
        <BannerLevel>
          {getCourseLevels(course?.level)}
        </BannerLevel>
        <BannerTitle>
          {course?.name}
        </BannerTitle>
        <ButtonContainer>
          <Button>
            <ButtonTitle>
              Get Started
            </ButtonTitle>
            {
              !hasSubscription && (
                <ButtonSubTitle>
                  with Swanly Premium
                </ButtonSubTitle>
              )
            }
          </Button>
          <ShareButton>
            <StyledShareIcon />
          </ShareButton>
        </ButtonContainer>
      </BannerContentContainer>
    </HeadBannerBackDrop>
  </HeadBannerContainer>
));

export default CourseMainBanner;
