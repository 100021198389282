import styled from 'styled-components';
import { IImageProps } from '../../../core/types';
import { ReactComponent as ShareIcon } from '../../../icons/share-icon.svg';
import { BreadcrumbsContainer, SliderLessonCardCourseNew } from '../../../components/common/styles';
import { ReactComponent as DoneIcon } from '../../../icons/done-icon.svg';
import { ReactComponent as BlockedIcon } from '../../../icons/blocked-icon.svg';
import { ReactComponent as FavoriteIcon } from '../../../icons/favorite-icon.svg';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeadBannerContainer = styled.div<IImageProps>`
  background-image: url(${((props) => props.image)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-height: 503px;
  color: ${({ theme }) => theme.palette.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-height: 896px;
  }
`;

export const HeadBannerBackDrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 16px 34px;
  min-height: 503px;
  color: ${({ theme }) => theme.palette.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 64px 80px 40px;
    min-height: 896px;
  }
`;

export const StyledBreadcrumbsContainer = styled(BreadcrumbsContainer)`
  color: ${({ theme }) => theme.palette.white};
  padding: 0;
`;

export const BannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
`;

export const BannerLevel = styled.span`
  font: ${({ theme }) => theme.typography.subtitle8.font};
  text-transform: uppercase;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.subtitle1.font};
  }
`;

export const BannerTitle = styled.span`
  font: ${({ theme }) => theme.typography.h1_mobile_new.font};
  text-transform: uppercase;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.h1_new.font};
    letter-spacing: 0.3px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 24px;
  justify-content: flex-start;
  align-items: center;
`;

export const Button = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  height: 64px;
  min-width: 108px;
  padding: 0 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 80px;
  background-color: ${({ theme }) => theme.palette.red100};
  box-shadow: ${({ theme }) => theme.shadow.primaryButtonGlow};
  cursor: pointer;
  color: ${({ theme }) => theme.palette.white};
`;

export const ButtonTitle = styled.span`
  font: ${({ theme }) => theme.typography.button_xl.font};
`;

export const ButtonSubTitle = styled.span`
  font: ${({ theme }) => theme.typography.body3.font};
`;

export const ShareButton = styled.button`
  cursor: pointer;
  display: flex;
  width: 64px;
  height: 64px;
  padding: 12.8px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${({ theme }) => theme.palette.transparentGray50};
  backdrop-filter: blur(3.076923131942749px);
`;

export const StyledShareIcon = styled(ShareIcon)`
  width: 38.4px;
  height: 38.4px;
`;

export const DescriptionContainer = styled.div`
  padding: 40px 16px 0;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font: ${({ theme }) => theme.typography.body3_mobile.font};
  color: ${({ theme }) => theme.palette.black100};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.body3_new.font};
    padding: 64px 80px 0;
    gap: 74px;
    flex-direction: row;
  }
`;

export const DescriptionContentContainer = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 1004px;
  }
`;

export const DescriptionText = styled.div`
  max-width: 1004px;
  ol, li {
    list-style-position: inside;
  }
`;

export const TrainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const TrainerContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;
export const TrainerTitle = styled.span`
  font: ${({ theme }) => theme.typography.subtitle2_mobile.font};
`;
export const TrainerName = styled(TrainerTitle)`
  display: flex;
  align-items: end;
`;

export const TrainerAvatar = styled.div<IImageProps>`
  background-image: url(${((props) => props.image)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

export const TrainerPosition = styled.span`
  font: ${({ theme }) => theme.typography.body4_mobile.font};
`;

export const LessonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 40px 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 80px;
    gap: 32px;
  }
`;

export const LessonsTitle = styled.span`
  font: ${({ theme }) => theme.typography.h2.font};
  color: ${({ theme }) => theme.palette.black100};
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const LessonCardMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const LessonCardMobileContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

interface ILessonImageProps extends IImageProps {
  available: boolean
}

export const LessonCardImage = styled.div<ILessonImageProps>`
  background-image: url(${((props) => props.image)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  width: 164px;
  height: 96px;
  padding: 8px;
  justify-content: space-between;
  position: relative;
  align-items: flex-start;
  ${(({ available }) => (available ? {
    opacity: 1,
    cursor: 'pointer',
  } : {
    opacity: 0.35,
    cursor: 'not-allowed',
  }))};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 12px;
    width: 408px;
    height: 236px;
  }
`;

export const LessonProgressBar = styled.div`
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.black80};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 8px;
  }
`;

export const LessonProgressValue = styled.div<{ percent: string }>`
  width: ${({ percent }) => `${percent}%`};
  height: 100%;
  background-color: ${({ theme }) => theme.palette.red100};
`;

export const LessonImageRadiusCopper = styled.div`
  width: 164px;
  height: 96px;
  border-radius: 12px;
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    border-radius: 20px;
    width: 408px;
    height: 236px;
  }
`;

export const LessonCardNew = styled(SliderLessonCardCourseNew)`
  position: unset;
`;

export const LessonDoneContainer = styled.span`
  display: flex;
  height: 16px;
  padding: 8px 1px 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.palette.transparentGray30};
  backdrop-filter: blur(5.667638778686523px);
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 32px;
    padding: 8px 12px;
    gap: 8px;
  }
`;

export const DoneText = styled.span`
  font: ${({ theme }) => theme.typography.button_xs.font};
  color: ${({ theme }) => theme.palette.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.button_s.font};
  }
`;

export const StyledDoneIcon = styled(DoneIcon)``;

export const AvailableMark = styled.span`
  height: 16px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${({ theme }) => theme.typography.button_xs.font};
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.red};
  border-radius: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 32px;
    padding: 8px 12px;
    font: ${({ theme }) => theme.typography.button_s.font};
  }
`;

export const StyledBlockedIcon = styled(BlockedIcon)`
  width: 32px;
  height: 32px;
`;

export const LessonCardMobileDescription = styled.span`
  font: ${({ theme }) => theme.typography.body5_mobile.font};
  color: ${({ theme }) => theme.palette.black100};
`;

export const LessonCardMobileActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 164px;
`;

export const LessonCardTitle = styled.span`
  font: ${({ theme }) => theme.typography.subtitle10.font};
  color: ${({ theme }) => theme.palette.black100};
  margin-bottom: 4px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.subtitle2_new.font};
    margin-bottom: unset;
  }
`;

export const LessonCardDuration = styled.span`
  font: ${({ theme }) => theme.typography.body6_mobile.font};
  color: ${({ theme }) => theme.palette.black80};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.body3_new.font};
  }
`;

export const LessonCardLineDivider = styled.hr`
  width: 100%;
  height: 1.5px;
  margin: 12px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 16px 0;
  }
`;

export const ShareActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 34px;
  }
`;

export const StyledLessonShareIcon = styled(ShareIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  path {
    stroke: ${({ theme }) => theme.palette.black100};
  }
`;
export const StyledFavoriteIcon = styled(FavoriteIcon)<{ favorite?: boolean }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  ${(({ favorite, theme }) => (favorite ? {
    fill: theme.palette.red,
    path: {
      stroke: theme.palette.red,
    },
  } : ''))};
`;

export const LessonCardDesktopContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
`;

export const LessonCardDesktopActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 573px;
`;

export const LessonCardDesktopDescription = styled.span`
  font: ${({ theme }) => theme.typography.body4_new.font};
  color: ${({ theme }) => theme.palette.black100};
`;

export const LessonCardDesktopActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const LessonCardDesktopNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const LessonPlayerCaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const LessonPlayerCaptionCourseName = styled.span`
  font: ${({ theme }) => theme.typography.h5_mobile_new.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.h5.font};
  }
`;

export const LessonPlayerCaptionLessonName = styled.span`
  font: ${({ theme }) => theme.typography.body3_mobile.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${({ theme }) => theme.typography.body3_new.font};
  }
`;
