import styled from 'styled-components';
import contactMobileBackground from '../../../images/mobile-background.jpg';
import contactDesktopBackground from '../../../images/desktop-background.jpg';
import { ReactComponent as SwanlyLogo } from '../../../icons/swanly-logo-new.svg';
import { ReactComponent as ConfirmationLogo } from '../../../icons/confirmation-icon.svg';

export const ContactContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url("${contactMobileBackground}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 375px;
  min-height: 1219px;
  color: ${(props) => props.theme.palette.black100};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background-image: url("${contactDesktopBackground}");
    min-width: unset;
    min-height: 1072px;
    padding: 64px 80px 53px;
    justify-content: space-between;
  }
`;

export const ContactHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  min-height: 481px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-width: unset;
    min-height: unset;
    padding: 0;
  }
`;

export const ContactHeaderNewLogo = styled(SwanlyLogo)`
  min-width: 192px;
  width: 192px;
  height: 112px;
`;
export const ContactMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: unset;
    max-width: 960px;
    margin-top: 96px;
  }
`;

export const ContactTitle = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_arizona};
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h1_arizona};
  }
`;

export const ContactDescription = styled.p`
  ${(props) => props.theme.typography.body_mobile_helvetica};
  color: ${(props) => props.theme.palette.black100};
  text-align: center;
  padding: 0 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body_helvetica};
    max-width: 754px;
  }
`;

export const ComingSoon = styled.p`
  ${(props) => props.theme.typography.subtitle1_helvetica};
  color: ${(props) => props.theme.palette.black100};
  text-align: center;
  padding: 0 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 30px 0px;
  }
`;

export const ContactForm = styled.form<{ $footerStyle?: boolean; }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    gap: 32px;
    justify-content: ${(props) => (props.$footerStyle ? 'left' : 'center')};
    margin-bottom: 270px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: unset;
    align-items: flex-start;
    position: relative;
  }
`;

export const ContactFormInput = styled.input<{ $footerStyle?: boolean; }>`
  width: 100%;
  height: 48px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.palette.black80};
  padding: 16px;
  box-sizing: border-box;
  background: transparent;
  color: ${(props) => (props.$footerStyle ? props.theme.palette.white : 'default')};
  &::placeholder {
    ${(props) => props.theme.typography.body_mobile_helvetica}
    color: ${(props) => props.theme.palette.black80}
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 360px;
  }
`;

export const ContactFormText = styled.span`
  ${(props) => props.theme.typography.button_helvetica}
`;

export const FormErrorMessage = styled.span`
  ${(props) => props.theme.typography.body_mobile};
  color: ${(props) => props.theme.palette.red100};
  
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
  width: 360px;
    position: absolute;
    top: 56px;
  }
`;

export const ButtonTitle = styled.p`
  margin: 0;
  padding: 0;
  ${(props) => props.theme.typography.button};
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin-top: 56px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ContactAddressContainer = styled.div`
  ${(props) => props.theme.typography.body2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
`;

export const TextContainer = styled.p`
  padding: 0;
  margin: 0;
  ${(props) => props.theme.typography.body_mobile_helvetica}
`;

export const SocialNetworkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 16px;
  }
`;

export const StyledLink = styled.a`
  text-transform: none;
  text-decoration: none;
  color: ${(props) => props.theme.palette.black100};
  ${(props) => props.theme.typography.body_mobile_helvetica}
  &:active, &:focus {
    color: ${(props) => props.theme.palette.black100};
  }
`;

export const SocialNetworkLink = styled(StyledLink)`
  width: 19.2px;
  height: 19.2px;
  
`;

export const PrivacyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  ${(props) => props.theme.typography.body_mobile_helvetica}
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
  }
`;

export const CardWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 24px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  box-shadow: ${(props) => props.theme.shadow.profileCardShadow};
  margin-bottom: 170px;
`;

export const ConfirmationIcon = styled(ConfirmationLogo)`
  width: 48px;
  height: 48px;
`;

export const ConfirmationText = styled.p`
  ${(props) => props.theme.typography.subtitle2_helvetica}
`;

export const TextWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
