import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  post, get, put, del,
} from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import {
  IErrorResponse, IFriendCodeReponse, IFriendsReponse, IFriendsRequest, IRemoveFriendRequest,
} from '../../../types';
import { ISubscriptionsResponse, IUpdatePassword } from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse,
}

export const updatePassword = createAsyncThunk<
void, IUpdatePassword, ICommonChunkConfig>(
  'profile/updatePassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await put({ path: API_ROUTES.SET_PASSWORD, data });
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const getSubscriptions = createAsyncThunk<
ISubscriptionsResponse, void, ICommonChunkConfig>(
  'profile/getSubscriptions',
  async (data, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.PROFILE_SUBCSRIPTIONS, params: {} });
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const getFriends = createAsyncThunk<
IFriendsReponse, IFriendsRequest, ICommonChunkConfig>(
  'profile/getFriends',
  async ({ showError }, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.FRIENDS, params: {}, showError });
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const getFriendCode = createAsyncThunk<
IFriendCodeReponse, void, ICommonChunkConfig>(
  'profile/getFriendCode',
  async (data, { rejectWithValue }) => {
    try {
      const response = await get({ path: API_ROUTES.FRIEND_CODE, params: {} });
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const generateFriendCode = createAsyncThunk<
IFriendCodeReponse, void, ICommonChunkConfig>(
  'profile/generateFriendCode',
  async (data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.FRIEND_CODE, data: {} });
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const regenerateFriendCode = createAsyncThunk<
IFriendCodeReponse, void, ICommonChunkConfig>(
  'profile/regenerateFriendCode',
  async (data, { rejectWithValue }) => {
    try {
      const response = await put({ path: API_ROUTES.FRIEND_CODE, data: {} });
      return response;
    } catch (error) {
      const errorResponse = error as IErrorResponse;
      return rejectWithValue(errorResponse);
    }
  },
);

export const deleteFriend = createAsyncThunk<
void, IRemoveFriendRequest, ICommonChunkConfig>(
  'profile/deleteFriend',
  async (data, { rejectWithValue }) => {
    try {
      return await del({ path: API_ROUTES.FRIENDS, data });
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);
