import React, { memo } from 'react';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import {
  ModalBox, TextContainer, PopperSubstitle, SendBtn, DoneIcon, ModalTitle,
} from '../../styles';

interface IThankYouProps {
  onClick: () => void;
}

const ThankYou = memo(({
  onClick,
}: IThankYouProps) => (
  <ModalBox>
    <DoneIcon />
    <TextContainer>
      <ModalTitle>Thank you for joining Swanly Premium!</ModalTitle>
      <PopperSubstitle>You now have full access to all our courses</PopperSubstitle>
    </TextContainer>
    <SendBtn isLoading={false} type="button" onClick={onClick}>
      <StyledDefaultButtonTitle>
        {'Let\'s go!'}
      </StyledDefaultButtonTitle>
    </SendBtn>
  </ModalBox>
));

export default ThankYou;
