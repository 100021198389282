import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getErrorMessage } from '../../../utils/helpers';
import {
  ICourse, ILesson,
} from '../../../types';
import {
  getCourse, getLesson, removeFavorite, setFavorite, setProgress,
} from '../services';
import { ISetFavoriteResponse } from '../types';
import { FavoriteContentType } from '../../../core/types';

export interface ICourseState {
  isLoading: boolean
  isProgressLoading: boolean
  isFavoriteLoading: boolean
  isLessonLoading: boolean
  currentCourse: ICourse | null
  currentLesson: ILesson | null
  courseError: string | null
  lessonError: string | null
}

const initialState: ICourseState = {
  isLoading: false,
  isProgressLoading: false,
  isFavoriteLoading: false,
  isLessonLoading: false,
  currentCourse: null,
  currentLesson: null,
  courseError: null,
  lessonError: null,
};

const getFavoriteLessons = (
  lessonId: string,
  state: ICourseState,
  isFavorite: boolean,
) => state
  .currentCourse?.lessons
  .map((lesson) => (
    {
      ...lesson,
      isFavorite: lesson.id === lessonId
        ? isFavorite
        : lesson.isFavorite,
    })) ?? [];

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCurrentCourse: (state, { payload }: PayloadAction<ICourse | null>) => {
      state.currentCourse = payload;
    },
    setCurrentLesson: (state, { payload }: PayloadAction<ILesson | null>) => {
      state.currentLesson = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCourse.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCourse.fulfilled,
      (state, { payload }: PayloadAction<ICourse>) => {
        state.isLoading = false;
        state.currentCourse = payload;
      },
    );
    builder.addCase(getCourse.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.courseError = getErrorMessage(payload);
    });

    builder.addCase(getLesson.pending, (state) => {
      state.isLessonLoading = true;
    });
    builder.addCase(
      getLesson.fulfilled,
      (state, { payload }: PayloadAction<ILesson>) => {
        state.isLessonLoading = false;
        state.currentLesson = payload;
      },
    );
    builder.addCase(getLesson.rejected, (state, { payload }) => {
      state.isLessonLoading = false;
      state.lessonError = getErrorMessage(payload);
    });

    builder.addCase(setProgress.pending, (state) => {
      state.isProgressLoading = true;
    });
    builder.addCase(
      setProgress.fulfilled,
      (state) => {
        state.isProgressLoading = false;
      },
    );
    builder.addCase(setProgress.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(setFavorite.pending, (state) => {
      state.isFavoriteLoading = true;
    });
    builder.addCase(
      setFavorite.fulfilled,
      (state, { payload }: PayloadAction<ISetFavoriteResponse>) => {
        const { contentType, contentId } = payload;
        state.isFavoriteLoading = false;
        if (state.currentCourse && contentType === FavoriteContentType.LESSON) {
          state.currentCourse.lessons = getFavoriteLessons(contentId, state, true);
        }
      },
    );
    builder.addCase(setFavorite.rejected, (state) => {
      state.isFavoriteLoading = false;
    });

    builder.addCase(removeFavorite.pending, (state) => {
      state.isFavoriteLoading = true;
    });
    builder.addCase(
      removeFavorite.fulfilled,
      (state, { meta: { arg: { contentId } } }) => {
        state.isFavoriteLoading = false;
        if (state.currentCourse) {
          state.currentCourse.lessons = getFavoriteLessons(contentId, state, false);
        }
      },
    );
    builder.addCase(removeFavorite.rejected, (state) => {
      state.isFavoriteLoading = false;
    });
  },
});
export const { setCurrentCourse, setCurrentLesson } = courseSlice.actions;
export default courseSlice.reducer;
