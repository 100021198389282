import styled, { keyframes } from 'styled-components';
import { ReactComponent as SwanIcon } from '../../../icons/swan-icon.svg';

export const StyledDefaultButton = styled.button`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 44px;
  color: ${(props) => props.theme.palette.white};
  border-radius: 80px;
  border: none;
  background-color: ${(props) => props.theme.palette.red};
  min-width: 170px;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.shadow.dropShadow_white};
  cursor: pointer;
  gap: 8px;
  &:disabled {
    color: ${(props) => props.theme.palette.red10};
    background-color: ${(props) => props.theme.palette.red65};
  }
;
`;

export const GetPremiumHeaderButton = styled(StyledDefaultButton)`
  height: 32px;
  width: 132px;
  min-width: 132px;
  font: ${({ theme }) => theme.typography.premiumButtonMobile.font};
  padding: 0;
  margin: 0;
  box-shadow: ${(props) => props.theme.shadow.primaryButtonGlow};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 174px;
    height: 40px;
    padding: 0 32px;
    margin-left: 40px;
    font: ${({ theme }) => theme.typography.premiumButtonWeb.font};
  }
`;

const rotate = keyframes`
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
`;

export const StyledButtonLoader = styled(SwanIcon)`
  width: 26px;
  height: 21px;
  fill: ${(props) => props.theme.palette.white};
  animation: ${rotate} 0.75s linear infinite;
  path {
    fill: ${(props) => props.theme.palette.white};
  }
`;

export const StyledDefaultButtonTitle = styled.span`
  ${(props) => props.theme.typography.button}
`;
