import { RootState } from '../../../store';

export const getCurrentCourseSelector = (state: RootState) => state.currentCourse.currentCourse;
export const getCurrentCourseErrorSelector = (state: RootState) => state.currentCourse.courseError;
export const getCurrentCourseLoadingSelector = (state: RootState) => state.currentCourse.isLoading;

export const getCurrentLessonSelector = (state: RootState) => state.currentCourse.currentLesson;
export const getCurrentLessonErrorSelector = (state: RootState) => state.currentCourse.lessonError;
export const getCurrentLessonLoadingSelector = (state: RootState) => state
  .currentCourse
  .isLessonLoading;
