import styled from 'styled-components';
import { IInputProps, ILabelProps } from './types';

export const StyledInput = styled.input<IInputProps>`
  position: relative;
  color: ${({ disabled, theme }) => (disabled ? theme.palette.secondaryGray : theme.palette.black5)};
  border-radius: 12px;
  padding: 0 16px ;
  margin: 8px 0 8px 0;
  border: 1px solid ${(props) => props.theme.palette.black80};
  height: 48px;
  font: ${(props) => props.theme.typography.body2.font};
  background: transparent;
  ${({ error, theme }) => error && `
  color: ${theme.palette.black5};
  border-color: ${theme.palette.red};
  `};
  &::placeholder {
    ${({ error, theme }) => error && `
  color: ${theme.palette.black65};
  `}
  }
  width: 100%;
  &:focus::-webkit-input-placeholder {
    opacity: 0;
    }
  &:focus:-moz-placeholder {
    opacity: 0;
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.palette.black40};
    transition: border-color 0.3s ease-in-out;
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.palette.black65};
  }
  &:focus:-ms-input-placeholder {
      opacity: 0;
  }
  &:focus:-moz-placeholder {
    opacity: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const InputFieldWarning = styled.span`
  color: ${(props) => props.theme.palette.red};
  margin-top: 0;
  font: ${(props) => props.theme.typography.body4.font};
  // @media(max-width: ${({ theme }) => theme.breakpoints.mobile}) {
  //   font-size: ${(props) => props.theme.typography.body2.fontSize};
  //   line-height: ${(props) => props.theme.typography.body2.lineHeight};
  // }
`;

export const StyledInputLabel = styled.label<ILabelProps>`
  color: ${({ disabled, error, theme }) => {
    if (error) {
      return theme.palette.red;
    }
    return disabled ? theme.palette.black10 : theme.palette.black10;
  }};
  font: ${(props) => props.theme.typography.caption2.font};
  // @media(max-width: 320px) {
  //   font-size: ${(props) => props.theme.typography.body2.fontSize};
  //   line-height: ${(props) => props.theme.typography.body2.lineHeight};
  // }
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InputIconWrapper = styled.div`
  position: absolute;
  right: 16px;
  bottom: 24px;
  z-index: 2;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperWithIcon = styled.div`
  position: relative;
`;
