export const API_ROUTES = {
  NOTIFY_ME: '/mock/notify',
  LOGIN: '/auth/sign-in',
  REGISTER: '/auth/sign-up',
  SIGN_VERIFY: '/auth/sign-verify',
  RESEND_CODE: '/auth/resend-code',
  GOOGLE_SIGN_IN: '/auth/google-sign-in',
  FACEBOOK_SIGN_IN: '/auth/facebook-sign-in',
  APPLE_SIGN_IN: '/auth/apple-sign-in',
  LOGOUT: '/auth/sign-out',
  ME: '/auth/me',
  PROFILE: '/profile',
  REFRESH_TOKEN: '/auth/refresh-token',
  RESET_PASSWORD: '/auth/reset-password',
  RESET_VERIFY: '/auth/reset-verify',
  SET_PASSWORD: '/auth/set-password',
  COURSES: '/courses',
  COURSES_ID: '/courses/:id',
  LESSONS_ID: '/courses/lessons/:id',
  PROGRESS: '/progress',
  FAVORITE: '/favorite',
  PROFILE_AVATAR: '/profile/avatar',
  PROFILE_SUBCSRIPTIONS: '/profile/subscriptions',
  FRIENDS: '/friends',
  FRIEND_CODE: '/friends/code',
  SUBSCRIPTION: '/subscription',
};
