import React, {
  ChangeEvent, FC, memo, useRef,
} from 'react';
import { IAvatarProps } from '../../types';
import defaultAvatar from '../../../../images/profile-avatar.png';
import {
  AvatarButtons, AvatarContainer, AvatarImg, DeleteBtn, UploadBtn,
} from '../../styles';
import { useAppSelector } from '../../../../hooks';
import { getGlobalProfileSelector, getGlobalProfileUrlSelector } from '../../../../core/selectors';

const Avatar: FC<IAvatarProps> = memo(({ deleteAvatar, uploadAvatar }) => {
  const profileUrl = useAppSelector(getGlobalProfileUrlSelector);
  const profile = useAppSelector(getGlobalProfileSelector);
  const ref = useRef<HTMLInputElement>(null);
  const currAvatar = profileUrl || defaultAvatar;

  const triggerClick = () => {
    ref?.current?.click();
  };

  const resetInput = () => {
    if (ref.current?.files && ref.current?.value) {
      ref.current.files = null;
      ref.current.value = '';
    }
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      uploadAvatar(file);
    }
  };

  const onDelete = () => {
    deleteAvatar();
    resetInput();
  };

  return (
    <AvatarContainer>
      <AvatarImg src={currAvatar} alt="" />
      <AvatarButtons>
        <input type="file" hidden ref={ref} onChange={onFileChange} />
        <UploadBtn onClick={triggerClick}>Upload photo</UploadBtn>
        <DeleteBtn onClick={onDelete} disabled={!(profile && profileUrl)}>Delete</DeleteBtn>
      </AvatarButtons>
    </AvatarContainer>
  );
});

export default Avatar;
