import React, { FC, memo } from 'react';
import calendar from '../../../icons/datepicker.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {
  CustomStyledDatepicker,
  StyledLabel,
  Wrapper,
  DatepickerWrapper,
  StyledIcon,
} from './style';
import { InputFieldWarning } from '../CustomStyledInput/style';
import { IDatepickerProps } from './types';

const CustomDatepicker: FC<IDatepickerProps> = memo(({
  selected,
  onChange,
  dateFormat,
  showIcon = true,
  placeholder,
  label,
  error,
  onBlur,
  maxDate,
  minDate,
}) => {
  const handleChange = (date: Date | null) => {
    onChange(date);
  };
  return (
    <Wrapper>
      {label ? <StyledLabel {...(error ? { error: true } : {})}>{label}</StyledLabel> : null}
      <DatepickerWrapper>
        <CustomStyledDatepicker
          selected={selected}
          onChange={handleChange}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          showIcon={false}
          showPopperArrow={false}
          {...(error ? { error: true } : {})}
          onBlur={onBlur}
          maxDate={maxDate}
          minDate={minDate}
        />
        {showIcon && <StyledIcon src={calendar} alt="" />}
      </DatepickerWrapper>
      {error ? <InputFieldWarning>{error}</InputFieldWarning> : null}
    </Wrapper>
  );
});

export default CustomDatepicker;
