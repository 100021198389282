import React, { memo } from 'react';
import {
  ContactAddressContainer,
  ContactContainer,
  ContactDescription,
  ContactHeader,
  ContactMainContainer,
  ContactTitle,
  FooterContainer,
  PrivacyContainer,
  SocialNetworkContainer,
  SocialNetworkLink,
  StyledLink,
  TextContainer,
  ContactHeaderNewLogo,
  ComingSoon,
} from '../../styles';
import { ContactFormComponent } from '../Form';
import { IContactForm } from '../../../../core/types';
import {
  COMING_SOON,
  // CONTACT_ADDRESS,
  CONTACT_DESCRIPTION,
  CONTACT_EMAIL,
  CONTACT_TITLE,
  INSTAGRAM_URL,
} from '../../constants';
// import { ReactComponent as FBIcon } from '../../../../icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../../icons/instagram.svg';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
// import { ReactComponent as TwitterIcon } from '../../../../icons/twitter.svg';

interface IMobileContactProps {
  onSubmit: (data: IContactForm) => void;
  isMobile: boolean;
}

const ContactHeaderWrapper = memo(() => (
  <ContactHeader>
    <ContactHeaderNewLogo />
  </ContactHeader>
));

const ContactTextWrapper = memo(() => (
  <>
    <ContactTitle>{CONTACT_TITLE}</ContactTitle>
    <ContactDescription>{CONTACT_DESCRIPTION}</ContactDescription>
    <ComingSoon>{COMING_SOON}</ComingSoon>
  </>
));

const SocialNetwork = memo(() => (
  <SocialNetworkContainer>
    {/* <SocialNetworkLink> */}
    {/*  <FBIcon /> */}
    {/* </SocialNetworkLink> */}
    {/* <SocialNetworkLink> */}
    {/*  <TwitterIcon /> */}
    {/* </SocialNetworkLink> */}
    <SocialNetworkLink href={INSTAGRAM_URL}>
      <InstagramIcon />
    </SocialNetworkLink>
  </SocialNetworkContainer>
));

const Footer = memo<{ isMobile: boolean }>(({ isMobile }) => (
  <FooterContainer>
    <ContactAddressContainer>
      {/* <TextContainer> */}
      {/*  {CONTACT_ADDRESS} */}
      {/* </TextContainer> */}
      <TextContainer>{CONTACT_EMAIL}</TextContainer>
      {!isMobile && <SocialNetwork />}
    </ContactAddressContainer>
    {isMobile && <SocialNetwork />}
    <PrivacyContainer>
      {` © Swanly, ${new Date().getFullYear()}`}
      <div style={{ display: 'flex', gap: '8px' }}>
        <StyledLink target="_blank" href={APP_ROUTES.PRIVACY_POLICY}>
          Privacy Policy
        </StyledLink>
        <StyledLink target="_blank" href={APP_ROUTES.TERMS_OF_USE}>
          Terms of Use
        </StyledLink>
      </div>
    </PrivacyContainer>
  </FooterContainer>
));

const ContactWrapper = memo<IMobileContactProps>(({ onSubmit, isMobile }) => (
  <ContactContainer>
    <ContactHeaderWrapper />
    <ContactMainContainer>
      <ContactTextWrapper />
      {isMobile ? (
        <ContactFormComponent onSubmit={onSubmit} />
      ) : (
        <ContactFormComponent onSubmit={onSubmit} />
      )}
    </ContactMainContainer>
    <Footer isMobile={isMobile} />
  </ContactContainer>
));

export default ContactWrapper;
