import styled from 'styled-components';
import { ReactComponent as ArrowNew } from '../../../../icons/arrow-down.svg';

export const CircleArrow = styled(ArrowNew)`
  width: 48px;
  height: 48px;
`;

export const PopperBox = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  position: absolute;
  display: none;
  right: 20px;
`;

export const PopperContainer = styled.div`
  width: 375px;
  background-color: ${(props) => props.theme.palette.black100};
  padding: 24px 32px;
  border-radius: 24px;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const TextCenter = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`;

export const PopperTitle = styled.p`
  font: ${(props) => props.theme.typography.caption_mobile.font};
  color: ${(props) => props.theme.palette.black10};
`;

export const PopperSubTitle = styled.p`
  font: ${(props) => props.theme.typography.body3.font};
  color: ${(props) => props.theme.palette.black40};
`;

export const Arrow = styled.span`
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${(props) => props.theme.palette.black100};
`;

export const PopperHeader = styled.h5`
  font: ${(props) => props.theme.typography.h5_popper.font};
  color: ${(props) => props.theme.palette.white};
`;
