import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    margin-left: 4.54%;
    margin-right: 4.54%;
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin: 0;
    }
`;

export const MobileMenuContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 760px;
    background: black;
    position: fixed;
    z-index: 5;
`;

export const MobileMenuItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 40px;
    padding-left: 16px;
`;

export const StyledMobileMenuSignInButton = styled.button`
    font: ${(props) => props.theme.typography.body1Header.font};
    color: ${(props) => props.theme.palette.white};
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    white-space: nowrap;
`;

export const HrLine = styled.hr`
    margin-top: 96px;
    margin-bottom: 8px;
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid ${(props) => props.theme.palette.black80}
`;

export const FooterMobile = styled.div<{ userEnabled?: boolean; }>`
    ${(props) => (props.userEnabled ? `
    color: white;
    margin-bottom: 56px;
    margin-top: 78px;
    position: relative;
    ` : `
    width: 100%;
    color: white;
    position: absolute; 
    bottom: 49px;
    `)}
`;
export const FooterMobileContainer = styled.div`
    padding-left: 16px;
    padding-right: 16px;
`;

export const LinkEmail = styled(Link)`
    font: ${(props) => props.theme.typography.body4_new.font};
    text-decoration: none;
    color: ${(props) => props.theme.palette.white};
`;

export const LinkInstagram = styled(Link)`
    display: flex;
    align-items: end;
    text-decoration: none;
    margin-bottom: 16px;
    color: ${(props) => props.theme.palette.white};
    font: ${(props) => props.theme.typography.subtitle3_new.font};
`;
