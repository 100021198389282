import React, {
  FC, memo, useCallback, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../utils/constants';
import {
  IRegisterRequest,
} from './types';
import { register } from './services';
import RegisterWrapper from './components/RegisterWrapper';
import { getLoadingRegisterSelector, getRegisterError } from './selectors';
import { setAuthTokens, setTempToken } from '../Login/slices';
import { resendCode } from '../Login/services';
import { getTempTokenSelector, getVerifyError } from '../../core/selectors';
import { IVerificationCodeRequest } from '../../types';
import { signVerify } from '../../core/services';

const Register: FC = memo(() => {
  const [width] = useWindowSize();
  const dispatch = useAppDispatch();
  const [isCodePage, setCodePage] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const registerError = useAppSelector(getRegisterError);
  const verifyError = useAppSelector(getVerifyError);
  const isLoading = useAppSelector(getLoadingRegisterSelector);
  const tempToken = useAppSelector(getTempTokenSelector);

  const onSubmit = useCallback((values: IRegisterRequest) => {
    dispatch(register(values)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        if (response.payload && 'tempToken' in response.payload) {
          dispatch(setTempToken(response.payload.tempToken));
          setEmail(values.email);
          setCodePage(true);
        }
      }
    });
  }, []);

  const onVerificationSubmit = useCallback((values: IVerificationCodeRequest) => {
    if (tempToken) {
      dispatch(signVerify({ ...values, tempToken })).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          if (response.payload && 'accessToken' in response.payload) {
            dispatch(setAuthTokens(response.payload));
          }
        }
      });
    }
  }, [tempToken]);

  const onResendCode = useCallback(() => {
    if (email) {
      dispatch(resendCode({ email }));
    }
  }, [email]);

  return (
    <RegisterWrapper
      onSubmit={onSubmit}
      onVerificationSubmit={onVerificationSubmit}
      onResendCode={onResendCode}
      registerError={registerError}
      verifyError={verifyError}
      isCodePage={isCodePage}
      isMobile={width <= BREAKPOINTS.mobile}
      isLoading={isLoading}
      email={email}
    />
  );
});

export default Register;
