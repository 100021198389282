import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MenuItemAboutUsLink,
  MenuItemAboutUsText,
  MenuItemCoursesLink,
  MenuItemCoursesText,
} from '../constants';
import {
  HeaderLogo, HeaderWebLinksContainer, HeaderWebLinksItemsContainer,
} from '../styles';
import WebMenuLink from './WebMenuLink';
import { APP_ROUTES } from '../../../router/appRoutes';

const WebMenu = memo(() => {
  const navigate = useNavigate();
  return (
    <HeaderWebLinksContainer>
      <HeaderLogo onClick={() => navigate(APP_ROUTES.MAIN)} />
      <HeaderWebLinksItemsContainer>
        <WebMenuLink label={MenuItemCoursesText} link={MenuItemCoursesLink} />
        <WebMenuLink label={MenuItemAboutUsText} link={MenuItemAboutUsLink} />
        {/* <WebMenuLink label={MenuItemBlogText} link={MenuItemBlogLink} /> */}
      </HeaderWebLinksItemsContainer>
    </HeaderWebLinksContainer>
  );
});

export default WebMenu;
