import { RootState } from '../../../store';

export const getLoadingResetPasswordSelector = (state: RootState) => state
  .resetPassword
  .isLoading;
export const getResetPasswordErrorSelector = (state: RootState) => state
  .resetPassword
  .resetPasswordError;
export const getResetVerifyErrorSelector = (state: RootState) => state
  .resetPassword
  .resetVerifyError;
export const getSetPasswordErrorSelector = (state: RootState) => state
  .resetPassword
  .setPasswordError;
