import React from 'react';
import Lottie from 'lottie-react';
import sLoader from '../../../S_Loader.json';
import { PageLoaderContainer } from './styles';

const PageLoader = () => (
  <PageLoaderContainer>
    <Lottie
      animationData={sLoader}
      loop
    />
  </PageLoaderContainer>
);

export default PageLoader;
