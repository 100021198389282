import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Close } from '../../../images/close-icon.svg';
import signInBackgroundImage from '../../../images/sign-in-background.jpg';
import { ReactComponent as SwanlyIcon } from '../../../images/swanly-icon.svg';
import { ReactComponent as WarningPoint } from '../../../images/warning-point.svg';
import CustomStyledInput from '../../inputs/CustomStyledInput';
import { DefaultButton } from '../../buttons/DefaultButton';
import { ReactComponent as LogoMobileLightIcon } from '../../../images/logo-mobile-light.svg';
import { ReactComponent as LogoDesktopLightIcon } from '../../../images/logo-desktop-light.svg';
import { ReactComponent as AppleIcon } from '../../../images/apple-icon.svg';
import { ReactComponent as GoogleIcon } from '../../../images/google-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../images/facebook-icon.svg';

export const StyledLoginWrapper = styled.div`
  display: flex;
`;

export const BackButton = styled.span`
  position: absolute;
  cursor: pointer;
  top: 32px;
  left: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    top: 64px;
    left: 64px;
  }
`;

export const StyledCloseIcon = styled(Close)`
  width: 24px;
  height: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 48px;
    height: 48px;
  }
`;

export const StyledWallpaperContainer = styled.div`
  background-image: url("${signInBackgroundImage}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledSwanlyIcon = styled(SwanlyIcon)``;

export const StyledWallpaperTitle = styled.span`
  ${(props) => props.theme.typography.landingTitleMedium};
  color: ${(props) => props.theme.palette.white};
  position: relative;
  bottom: -134px;
`;

export const StyledPageContainer = styled.div`
  background-color: ${(props) => props.theme.palette.black100};
  min-height: 100vh;
  width: 100%;
  max-width: 699px;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 96px 16px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 160px 178px 0;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 40px;
  }
`;

export const StyledSocialNetworkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`;

export const StyledTitle = styled.span`
  ${(props) => props.theme.typography.subtitle1};
  color: ${(props) => props.theme.palette.black5};
  span {
    color: ${(props) => props.theme.palette.black65};
  }
`;

export const StyledSubTitle = styled.span`
  text-align: center;
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.black40};
`;

export const StyledOr = styled(StyledTitle)`
  margin: 16px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: unset;
  }
`;

export const StyledSocialNetworkButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 343px;
  gap: 24px;
  margin-bottom: 40px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: unset;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 32px;
`;

export const StyledWarningPoint = styled(WarningPoint)`
  color: ${(props) => props.theme.palette.red};
  width: 16px;
  height: 16px;
`;

export const CustomEmailStyledInput = styled(CustomStyledInput)`
  & input {
    padding-right: 36px;
  }
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`;

export const ForgotPassword = styled(Link)`
  font: ${(props) => props.theme.typography.body3.font};
  color: ${(props) => props.theme.palette.black5};
  text-decoration: none;
  position: absolute;
  right: 0;
  bottom: -18px;
  z-index: 1;
  cursor: pointer;
`;

export const StyledErrorFormMessage = styled.p`
  font: ${(props) => props.theme.typography.body3.font};
  color: ${(props) => props.theme.palette.red};
  text-align: center;
`;

export const SubmitStyledButton = styled(DefaultButton)`
  height: 40px;
  //width: 117px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: unset;
  min-width: unset;
  align-self: center;
  padding: 7px 31px 10px;
`;

export const QuestionComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font: ${(props) => props.theme.typography.body1.font};
  color: ${(props) => props.theme.palette.black65};
  gap: 8px;
`;

export const QuestionStyledSpan = styled.span`
  color: ${(props) => props.theme.palette.black40};
`;

export const CountDownStyledSpan = styled.span`
  color: ${(props) => props.theme.palette.black80};
  font: ${(props) => props.theme.typography.subtitle3.font};
`;

export const StyledSignUp = styled(Link)`
  font: ${(props) => props.theme.typography.subtitle3.font};
  color: ${(props) => props.theme.palette.black5};
  text-decoration: none;
`;

export const StyledTextButton = styled.button`
  font: ${(props) => props.theme.typography.subtitle3.font};
  color: ${(props) => props.theme.palette.black5};
  text-decoration: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const StyledLogoMobileIcon = styled(LogoMobileLightIcon)`
  width: 162px;
  height: 28px;
  margin-bottom: 48px;
`;
export const StyledLogoDesktopIcon = styled(LogoDesktopLightIcon)`
  width: 210px;
  height: 36px;
  margin-bottom: 80px;
`;
export const StyledAppleIcon = styled(AppleIcon)``;
export const StyledGoogleIcon = styled(GoogleIcon)``;
export const StyledFacebookIcon = styled(FacebookIcon)``;

export const SocialNetworkButton = styled.button`
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.black95};
  cursor: pointer;
`;
