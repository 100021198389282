import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IProfileResponse } from '../../../types';
import { PROFILE_SUCCESS_MESSAGE, AVATAR_SUCCESS_MESSAGE, DELETE_AVATAR_SUCCESS_MESSAGE } from '../../../containers/Profile/constants';
import { getErrorMessage } from '../../../utils/helpers';
import {
  getProfile, updateProfile, uploadAvatar, deleteAvatar,
} from '../../services';

export interface IGlobalProfileState {
  isProfileLoading: boolean;
  profile: IProfileResponse | null;
  profileError: string;
  profileUrl: string;
}

const initialState: IGlobalProfileState = {
  isProfileLoading: false,
  profile: null,
  profileUrl: '',
  profileError: '',
};

export const globalProfileSlice = createSlice({
  name: 'globalProfile',
  initialState,
  reducers: {
    resetGlobalProfile: (state) => {
      state.isProfileLoading = initialState.isProfileLoading;
      state.profile = initialState.profile;
      state.profileUrl = initialState.profileUrl;
      state.profileError = initialState.profileError;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProfile.fulfilled,
      (state, { payload }: PayloadAction<IProfileResponse>) => {
        state.profile = payload;
        if (payload.avatar && payload.avatar.url) {
          state.profileUrl = payload.avatar.url;
        }
      },
    );
    builder.addCase(getProfile.rejected, (state, { payload }) => {
      const error = getErrorMessage(payload);
      state.profileError = error;
      toast.error(error);
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.profileError = '';
      state.isProfileLoading = true;
    });
    builder.addCase(
      updateProfile.fulfilled,
      (state) => {
        state.isProfileLoading = false;
        toast.success(PROFILE_SUCCESS_MESSAGE);
      },
    );
    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.isProfileLoading = false;
      const error = getErrorMessage(payload);
      state.profileError = error;
      toast.error(error);
    });
    builder.addCase(
      uploadAvatar.fulfilled,
      (state, { payload }) => {
        state.profileUrl = payload.url;
        toast.success(AVATAR_SUCCESS_MESSAGE);
      },
    );
    builder.addCase(uploadAvatar.rejected, (state, { payload }) => {
      const error = getErrorMessage(payload);
      toast.error(error);
    });
    builder.addCase(
      deleteAvatar.fulfilled,
      (state) => {
        state.profileUrl = '';
        toast.success(DELETE_AVATAR_SUCCESS_MESSAGE);
      },
    );
    builder.addCase(deleteAvatar.rejected, (state, { payload }) => {
      const error = getErrorMessage(payload);
      toast.error(error);
    });
  },
});

export const { resetGlobalProfile } = globalProfileSlice.actions;
export default globalProfileSlice.reducer;
