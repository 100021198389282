import React, { memo } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import uuid from 'react-uuid';
import { StyledNavLink, StyleSlashSpace } from './styles';
import { APP_ROUTES } from '../../core/router/appRoutes';

interface IBreadcrumbsProps {
  match?: {
    [key: string]: string
  }
}

const Breadcrumbs = memo<IBreadcrumbsProps>(({ match: _match }) => {
  const routes = Object.values(APP_ROUTES).map((route) => (
    {
      path: route,
      ...(route === APP_ROUTES.MAIN ? { breadcrumbs: 'Main' } : {}),

    }
  )).slice(1);
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <div key={uuid()}>
      {breadcrumbs.map(({
        breadcrumb, match,
      }, index) => (
        <>
          <StyledNavLink key={uuid()} to={match.pathname}>
            {
              (_match && match.params.id && (match.params.id in _match)) ? (
                _match[match.params.id]
              ) : breadcrumb
            }
          </StyledNavLink>
          {
              index + 1 < breadcrumbs.length && (
                <StyleSlashSpace>
                  /
                </StyleSlashSpace>
              )
            }
        </>
      ))}
    </div>
  );
});

export default Breadcrumbs;
