import React, { memo } from 'react';
import uuid from 'react-uuid';
import 'swiper/css';
import 'swiper/css/grid';
import { useNavigate } from 'react-router-dom';
import { ICourse } from '../../../../types';
import {
  SliderLessonCard,
  SliderLessonCardCourse,
  SliderLessonCardCourseName,
  SliderLessonCardImage,
  SliderLessonCardLessonContainer,
  SliderLessonCardLessonDivider,
  SliderLessonCardLessonDuration,
  SliderLessonCardLessonName,
  SliderLessonCardTextContainer,
} from '../../styles';

import lessonMock from '../../../../images/lesson-image-mock.jpg';
import { SliderLessonCardCourseNew } from '../../../../components/common/styles';
import { getMinutes } from '../../../../utils/helpers';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { useLoadImage } from '../../../../utils/hooks/useLoadImage';
import CourseSkeleton from '../../../../components/loaders/CourseSkeleton';

interface ISliderLessonProps {
  course: ICourse;
  isMobile: boolean;
}

const SliderLesson = ({ course, isMobile }: ISliderLessonProps) => {
  const navigate = useNavigate();
  const src = useLoadImage(course.lessons[0]?.image?.url);

  return (
    <SliderLessonCard
      key={uuid()}
      onClick={() => navigate(
        APP_ROUTES.COURSES_ID.replace(':id', course.id),
        {
          state: {
            lessonId: course.lessons[0]?.id,
          },
        },
      )}
    >
      {src ? (
        <SliderLessonCardImage
          key={uuid()}
          image={src ?? lessonMock}
        >
          <SliderLessonCardCourseNew>
            new
          </SliderLessonCardCourseNew>
        </SliderLessonCardImage>
      ) : (
        <CourseSkeleton
          isMobile={isMobile}
          webHeight={96}
          webWidth={164}
          mobileHeight={96}
          mobileWidth={164}
        />
      )}
      <SliderLessonCardTextContainer>
        <SliderLessonCardCourse>
          Course
        </SliderLessonCardCourse>
        <SliderLessonCardCourseName>
          {course.name}
        </SliderLessonCardCourseName>
        <SliderLessonCardLessonDivider />
        <SliderLessonCardLessonContainer>
          <SliderLessonCardLessonName>
            {course.lessons[0]?.name}
          </SliderLessonCardLessonName>
          <SliderLessonCardLessonDuration>
            {getMinutes(course.lessons[0]?.duration ?? 0)}
            {' '}
            min
          </SliderLessonCardLessonDuration>
        </SliderLessonCardLessonContainer>
      </SliderLessonCardTextContainer>
    </SliderLessonCard>
  );
};

export default memo(SliderLesson);
