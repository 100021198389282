import { createSlice } from '@reduxjs/toolkit';
import {
  getErrorMessage,
} from '../../../utils/helpers';
import { register } from '../services';

export interface IRegisterState {
  isLoading: boolean
  registerError: string | null
}

const initialState: IRegisterState = {
  isLoading: false,
  registerError: null,
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      register.fulfilled,
      (state) => {
        state.isLoading = false;
        state.registerError = initialState.registerError;
      },
    );
    builder.addCase(register.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.registerError = getErrorMessage(payload);
    });
  },
});

export default registerSlice.reducer;
