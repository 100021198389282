import styled from 'styled-components';

export const PageContainer = styled.div`
  margin: 30px auto;
  padding: 0 30px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Header = styled.h2`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 10px;
`;

export const SubHeader = styled.h3`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 10px;
`;

export const Paragraph = styled.p`
  margin-bottom: 10px;
`;

export const UnorderedList = styled.ul`
  margin: 0 0 10px 35px;
  ${'>li'} {
    margin-bottom: 5px;
  }
`;
