import React, { useCallback, useState } from 'react';
import defaultAvatar from '../../../../images/avatar-default.png';
import {
  TitleAvatar,
  SubstitleAvatar,
  DeleteIcon,
  Avatar,
  FriendBox,
  AvatarContainer,
} from '../../containers/Friends/style';
import CustomModal from '../../../../components/modals/CustomModal';
import { Container, Text } from './styles';
import { IFriend } from '../../../../types';

interface IFriendProps {
  friend: IFriend;
  onRemove: (id: string) => void;
  isFirst: boolean;
}

const Friend = ({ friend, onRemove, isFirst }: IFriendProps) => {
  const [isOpen, setOpen] = useState(false);
  const {
    avatar, firstName, email, id,
  } = friend;
  const src = (avatar && avatar.url) || defaultAvatar;

  const open = () => setOpen(true);
  const close = useCallback(() => setOpen(false), []);
  const remove = useCallback(() => {
    onRemove(id);
    close();
  }, [id]);

  return (
    <>
      <FriendBox $isFirst={isFirst}>
        <AvatarContainer>
          <Avatar src={src} alt="" />
          <span>
            <TitleAvatar>{firstName || 'Friend'}</TitleAvatar>
            <SubstitleAvatar>{email}</SubstitleAvatar>
          </span>
        </AvatarContainer>
        <DeleteIcon onClick={open} />
      </FriendBox>
      <CustomModal
        isOpen={isOpen}
        onClose={close}
        onCancelButtonClick={close}
        title="Remove a member"
        isTitleCented
        modalWidth={489}
        submitText="Remove"
        onSubmitClick={remove}
        isBtnsCented
      >
        <Container>
          <Text>Are you sure you want to remove this friend?</Text>
          <AvatarContainer>
            <Avatar src={src} alt="" />
            <span>
              <TitleAvatar>{firstName}</TitleAvatar>
              <SubstitleAvatar>{email}</SubstitleAvatar>
            </span>
          </AvatarContainer>
        </Container>
      </CustomModal>
    </>
  );
};

export default Friend;
