import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { CountDownStyledSpan } from '../../wallpapers/auth/styles';

interface ICountDownButtonProps {
  children: ReactElement;
}

const CountDownButton = forwardRef<any, ICountDownButtonProps>((
  {
    children,
  },
  ref,
) => {
  const [timer, setTimer] = useState(60);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(countdown);
      setDisableButton(false);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  return (
    <>
      {
        disableButton ? (
          <CountDownStyledSpan>
            Resend in
            {' '}
            {timer}
            {' '}
            {timer && timer > 1 ? 'seconds' : 'second'}
          </CountDownStyledSpan>
        ) : (children)
      }
      <button
        aria-label="button"
        type="button"
        ref={ref}
        hidden
        onClick={() => { setDisableButton(true); setTimer(60); }}
      />
    </>
  );
});

export default CountDownButton;
