import styled from 'styled-components';

export const MainLayoutContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const ChildrenContainer = styled.div`
    min-height: 550px;
`;
