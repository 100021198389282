import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { resetPasswordSchema } from '../../../../utils/validation/schemas';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import { IResetPasswordRequest } from '../../types';
import {
  CustomEmailStyledInput,
  StyledErrorFormMessage,
  StyledWarningPoint,
  SubmitStyledButton,
} from '../../../../components/wallpapers/auth/styles';
import { StyledRegisterForm } from '../../styles';

interface IResetPasswordFormComponentProps {
  onSubmit: (data: IResetPasswordRequest) => void
  errorMessage?: string | null
  isLoading: boolean
}

const ResetPasswordForm: FC<IResetPasswordFormComponentProps> = ({
  onSubmit, errorMessage, isLoading,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isValid, errors, isDirty },
  } = useForm<IResetPasswordRequest>({
    resolver: joiResolver(resetPasswordSchema),
    mode: 'onBlur',
  });

  return (
    <StyledRegisterForm
      onSubmit={handleSubmit(onSubmit)}
    >
      <CustomEmailStyledInput
        {...register('email')}
        label="Email"
        placeholder="Enter email"
        {...(errors.email?.message ? { error: errors?.email?.message } : {})}
        inputMode="email"
        {...(errors.email?.message ? { icon: <StyledWarningPoint /> } : {})}
      />
      {errorMessage && (<StyledErrorFormMessage>{errorMessage}</StyledErrorFormMessage>)}
      <SubmitStyledButton
        disabled={!isValid || !isDirty}
        type="submit"
        isLoading={isLoading}
      >
        <StyledDefaultButtonTitle>
          Send
        </StyledDefaultButtonTitle>
      </SubmitStyledButton>
    </StyledRegisterForm>
  );
};

export default ResetPasswordForm;
