import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Block, WhiteArrow, Wrapper } from '../../styles';

interface IWrapper {
  children: ReactNode
}

const PageWrapper = ({ children }: IWrapper) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Block onClick={() => navigate(-1)}>
        <WhiteArrow />
        <p>Back</p>
      </Block>
      {children}
    </Wrapper>
  );
};

export default PageWrapper;
