const apiSocket = process.env.REACT_APP_API_SERVER_ADDRESS;

export default {
  restApiHost: `https://${apiSocket}/`,
  secretKey: process.env.REACT_APP_PASSWORD_SECRET_KEY,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  facebookClientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
  appleClientId: process.env.REACT_APP_APPLE_BUNDLE_ID,
  appleRedirectUrl: process.env.REACT_APP_APPLE_REDIRECT_URI,
  isReleased: process.env.REACT_APP_IS_RELEASED === 'true',
};
