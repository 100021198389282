import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { setPasswordSchema } from '../../../../utils/validation/schemas';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import { ISetPasswordRequest } from '../../types';
import {
  StyledErrorFormMessage,
  SubmitStyledButton,
} from '../../../../components/wallpapers/auth/styles';
import { StyledRegisterForm } from '../../styles';
import CustomPasswordInput from '../../../../components/inputs/CustomPasswordInput';

interface ISetPasswordFormComponentProps {
  onSubmit: (data: ISetPasswordRequest) => void
  errorMessage?: string | null
  isLoading: boolean
}

const SetPasswordForm: FC<ISetPasswordFormComponentProps> = ({
  onSubmit, errorMessage, isLoading,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isValid, errors, isDirty },
  } = useForm<ISetPasswordRequest>({
    resolver: joiResolver(setPasswordSchema),
    mode: 'onBlur',
  });

  return (
    <StyledRegisterForm
      onSubmit={handleSubmit(onSubmit)}
    >
      <CustomPasswordInput
        label="New Password"
        placeholder="Create password"
        {...register('password')}
        error={errors.password?.message ?? errors?.password?.message}
      />
      <CustomPasswordInput
        label="Confirm new password"
        placeholder="Confirm password"
        {...register('confirmPassword')}
        error={errors.confirmPassword?.message ?? errors?.confirmPassword?.message}
      />
      {errorMessage && (<StyledErrorFormMessage>{errorMessage}</StyledErrorFormMessage>)}
      <SubmitStyledButton
        disabled={!isValid || !isDirty}
        type="submit"
        isLoading={isLoading}
      >
        <StyledDefaultButtonTitle>
          Save
        </StyledDefaultButtonTitle>
      </SubmitStyledButton>
    </StyledRegisterForm>
  );
};

export default SetPasswordForm;
