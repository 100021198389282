import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../containers/Login/slices';
import profileReducer from '../containers/Profile/slices';
import subscriptionReducer from '../containers/Subscription/slices';
import contactReducer from '../containers/Contact/slices';
import registerReducer from '../containers/Register/slices';
import resetPasswordReducer from '../containers/ResetPassword/slices';
import verifyReducer from '../core/slices/verify';
import globalProfileReducer from '../core/slices/profile';
import coursesReducer from '../core/slices/courses';
import courseReducer from '../containers/Course/slices';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    contact: contactReducer,
    register: registerReducer,
    resetPassword: resetPasswordReducer,
    verify: verifyReducer,
    courses: coursesReducer,
    currentCourse: courseReducer,
    profile: profileReducer,
    globalProfile: globalProfileReducer,
    subscription: subscriptionReducer,
  // add here container reducers
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
