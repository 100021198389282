import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as MobileLogo } from '../../../images/mobile-logo.svg';
import { ReactComponent as MobileLogoWhite } from '../../../images/mobile-logo-white.svg';

export const HeaderContainer = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    margin-left: 80px;
    margin-right: 80px;
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin: 0;
      height: 48px;
    }
`;
export const HeaderWebLinksItemsContainer = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
    gap: 56px;
    margin-left: 80px;
`;

export const HeaderWebLinksContainer = styled.div`
    display: flex;
    height: 24px;
    margin-right: 20px;
    align-items: center;
    justify-content: space-between;
`;

export const HeaderLogo = styled(MobileLogo)`
  width: 108px;
  margin: 16px 0px 16px 16px;
  height: 16px;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 160px;
    height: 24px;
    margin: 18px 0px 18px 0px;
  }
`;

export const HeaderLogoWhite = styled(MobileLogoWhite)`
  width: 108px;
  margin: 16px 0px 16px 16px;
  height: 16px;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 160px;
    height: 24px;
    margin: 18px 0px 18px 0px;
  }
`;

export const HeaderButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: fit-content;
    height: 32px;
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-right: 16px;
    }
`;

export const StyledWebLinksButton = styled.button`
  font: ${(props) => props.theme.typography.subtitle3_new.font};
  color: ${(props) => props.theme.palette.black100};
  text-decoration: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
`;

export const StyledHeaderSignInButton = styled.button`
  font: ${(props) => props.theme.typography.subtitle3.font};
  color: ${(props) => props.theme.palette.black100};
  text-decoration: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
`;

export const StyledLogoInHeader = styled.div`
  display: flex;
  position: relative;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
  background: ${(props) => props.theme.palette.black10};
`;

export const HeaderBackdrop = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 60px;
  }
`;

export const HeaderBackground = styled.div<{ blackTheme?: boolean; }>`
  background-color: ${({ blackTheme, theme }) => (blackTheme ? theme.palette.black : theme.palette.white)};
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
`;

export const MenuLink = styled(Link)`
  display: block;
  margin-right: 16px;
`;

export const MenuLinkMobile = styled(Link)`
  display: block;
  margin-right: 16px;
`;
