import React from 'react';
import {
  SpecialCardContainer,
  BodyHidden,
  BodyTextWhite,
  Box,
  CardContentLeftStyled,
  PriceContainerStyled,
  PriceWhite,
  StyledBadge,
  SmStyledLogo,
  WhiteArrowForward,
  Block,
  OptionsText,
  TypographyBox,
} from '../../styles';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';
import { Divider } from '../../../Profile/containers/Friends/style';
import { StyledDefaultButton } from '../../../Profile/styles';
import { ISubscriptionMapping } from '../../../Profile/types';

interface ISpecialCardProps {
  subscription: ISubscriptionMapping;
  description: string;
}

const StudentsCard = ({ subscription, description }: ISpecialCardProps) => {
  const { period, price, text } = subscription;

  return (
    <SpecialCardContainer>
      <CardContentLeftStyled>
        <Box>
          <SmStyledLogo />
          <StyledBadge>Students</StyledBadge>
        </Box>
        <TypographyBox>
          <BodyHidden>{`billed ${text}`}</BodyHidden>
          <PriceContainerStyled>
            <PriceWhite>{`$${price}`}</PriceWhite>
            <BodyTextWhite>{`/ ${period}`}</BodyTextWhite>
          </PriceContainerStyled>
        </TypographyBox>
        <BodyTextWhite>{description}</BodyTextWhite>
        <StyledDefaultButton
          type="button"
          isLoading={false}
        >
          <StyledDefaultButtonTitle>
            Verify student status
          </StyledDefaultButtonTitle>
        </StyledDefaultButton>
        <Divider />
        <Block>
          <OptionsText>I have a student code</OptionsText>
          <WhiteArrowForward />
        </Block>
      </CardContentLeftStyled>
    </SpecialCardContainer>
  );
};

export default StudentsCard;
