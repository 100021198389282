import React, { memo, ReactNode } from 'react';
import uuid from 'react-uuid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import { ICourse } from '../../../../types';
import {
  ClassesContainer,
  NewCoursesPageContainer,
  PageTitle,
} from '../../styles';
import SliderLesson from '../SliderLesson';

interface ISliderClassesProps {
  title: ReactNode;
  courses: ICourse[] | null | undefined;
  isMobile: boolean;
}

const SliderClasses = memo<ISliderClassesProps>(
  (
    {
      title,
      courses,
      isMobile,
    },
  ) => (
    <NewCoursesPageContainer>
      <PageTitle>
        {title}
      </PageTitle>
      <ClassesContainer>
        <Swiper
          modules={[Grid]}
          slidesPerView={isMobile ? 1 : 3}
          grid={{
            rows: 2,
          }}
          spaceBetween={24}
          freeMode
        >
          {
              courses?.map((course) => (
                <SwiperSlide key={uuid()}>
                  <SliderLesson course={course} isMobile={isMobile} />
                </SwiperSlide>
              ))
            }
        </Swiper>
      </ClassesContainer>
    </NewCoursesPageContainer>
  ),
);

export default SliderClasses;
