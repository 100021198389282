import { RootState } from '../../../store';

export const getIsPasswordLoadingSelector = (state: RootState) => state.profile.isPasswordLoading;
export const getProfileSubscriptionsSelector = (state: RootState) => state.profile.subscriptions;
export const getProfileFriendsSelector = (state: RootState) => state.profile.friends;
export const getFriendCodeSelector = (state: RootState) => state.profile.code;
export const getIsSubscriptionsLoadingSelector = (
  state: RootState,
) => state.profile.isSubscriptionsLoading;
export const getIsSubmitSuccessfullSelector = (
  state: RootState,
) => state.profile.isSubmitSuccessfull;
