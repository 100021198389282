import React, { memo, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import uuid from 'react-uuid';
import { IconButton } from '../../../../components/buttons/IconButton';
import {
  ButtonTitle,
  PageContainer,
  StyledDesktopSlide,
  StyledInstagramIcon,
  StyledLogoItem,
  StyledMobileSlide,
  StyledSliderContainer,
  StyledSliderMobileContentContainer,
  SubTitle,
  TextContentContainer,
  StyledInstaLink,
  Title,
} from './styles';

import slider1 from '../../../../images/background-slider-1.jpg';
import slider2 from '../../../../images/background-slider-2.jpg';
import slider3 from '../../../../images/background-slider-3.jpg';
import slider4 from '../../../../images/background-slider-4.jpg';
import slider5 from '../../../../images/background-slider-5.jpg';
import slider6 from '../../../../images/background-slider-6.jpg';
import slider7 from '../../../../images/background-slider-7.jpg';
import slider8 from '../../../../images/background-slider-8.jpg';
import slider9 from '../../../../images/background-slider-9.jpg';
import slider10 from '../../../../images/background-slider-10.jpg';
import { chunkArray } from '../../../../utils/helpers';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../../../utils/constants';
import { INSTAGRAM_URL } from '../../../Contact/constants';

const Home5 = memo(() => {
  const images = useMemo(() => chunkArray([
    slider1,
    slider2,
    slider3,
    slider4,
    slider5,
    slider6,
    slider7,
    slider8,
    slider9,
    slider10,
  ], 2), []);
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.mobile, [width]);
  return (
    <PageContainer>
      <TextContentContainer>
        <Title>
          Be a part
          <br />
          of our community
        </Title>
        <SubTitle>
          Don`t miss out on our exclusive content and the chance to connect with fellow members.
          Click the link below to follow us on Instagram and become a part of our growing community!
        </SubTitle>
        <StyledInstaLink to={INSTAGRAM_URL}>
          <IconButton
            isLoading={false}
            icon={<StyledInstagramIcon />}
          >
            <ButtonTitle>
              Follow us
            </ButtonTitle>
          </IconButton>
        </StyledInstaLink>
      </TextContentContainer>
      <StyledSliderContainer>
        {
          images.map((imageSliderArray, index) => (isMobile
            ? (
              <StyledSliderMobileContentContainer key={uuid()}>
                <Swiper
                  key={uuid()}
                  modules={[Autoplay]}
                  spaceBetween={0}
                  slidesPerView={2}
                  loop
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                    loop: true,
                  }}
                  speed={9000}
                  freeMode
                  dir={index > 0 ? 'rtl' : 'ltl'}
                >
                  {
                  imageSliderArray.map((image) => (
                    <SwiperSlide key={uuid()}>
                      <StyledMobileSlide key={uuid()} image={image} />
                    </SwiperSlide>
                  ))
                }
                </Swiper>
              </StyledSliderMobileContentContainer>
            )
            : (
              <Swiper
                key={uuid()}
                modules={[Autoplay]}
                spaceBetween="40px"
                slidesPerView={1.9}
                loop
                autoplay={{
                  delay: 1,
                  disableOnInteraction: false,
                  loop: true,
                  reverseDirection: index > 0,
                }}
                speed={15000}
                direction="vertical"
                freeMode
                dir={index > 0 ? 'rtl' : 'ltl'}
              >
                {
                  imageSliderArray.map((image) => (
                    <SwiperSlide key={uuid()}>
                      <StyledDesktopSlide key={uuid()} image={image} />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            )))
        }
        <StyledLogoItem />
      </StyledSliderContainer>
    </PageContainer>
  );
});

export default Home5;
