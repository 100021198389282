import { createSlice } from '@reduxjs/toolkit';
import {
  getErrorMessage,
} from '../../../utils/helpers';
import { signVerify } from '../../services';

export interface IVerifyState {
  isLoading: boolean
  verifyError: string | null
}

const initialState: IVerifyState = {
  isLoading: false,
  verifyError: null,
};

export const verifySlice = createSlice({
  name: 'verify',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signVerify.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      signVerify.fulfilled,
      (state) => {
        state.isLoading = false;
        state.verifyError = initialState.verifyError;
      },
    );
    builder.addCase(signVerify.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.verifyError = getErrorMessage(payload);
    });
  },
});

export default verifySlice.reducer;
