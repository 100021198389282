import React, { memo, ReactNode } from 'react';
import {
  CoursesContainer,
  NewCoursesPageContainer,
  PageTitle,
  StyledDivider,
} from './styles';
import { ICourse } from '../../../types';
import Course from './components/Course';

interface IPartCoursesProps {
  courses: ICourse[] | null | undefined;
  title: ReactNode;
  hasDivider?: boolean;
  isMobile: boolean;
}

const PartCourses = memo<IPartCoursesProps>(
  (
    {
      title,
      courses,
      hasDivider,
      isMobile,
    },
  ) => (
    <>
      <NewCoursesPageContainer>
        <PageTitle>
          {title}
        </PageTitle>
        <CoursesContainer>
          {courses?.map((course) => (
            <Course course={course} isMobile={isMobile} />
          ))}
        </CoursesContainer>
      </NewCoursesPageContainer>
      {hasDivider && (
      <StyledDivider />
      )}
    </>
  ),
);

export default PartCourses;
