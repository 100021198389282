import React, { memo, useState } from 'react';
import {
  Box,
  CancelSubscriptionText,
  CardContentLeft,
  CardContentRight,
  PremiumBadge,
  PriceBox,
  RedBadge,
  StyledLogo,
  SubscriptionCard,
  BodyText,
  TextBreak,
  Price,
  PriceContainer,
  TransparentBtn,
  Block,
  SmallBlackBtn,
} from '../../styles';
import CustomModal from '../../../../components/modals/CustomModal';
import { IPremiumCardProps } from '../../types';
import {
  SUBSCRIPTION_MAPPING,
} from '../../constants';
import { transformDateFull, transformDateShort } from '../../../../utils/helpers';
import { ProductID } from '../../../../types';
import { StyledDefaultButtonTitle } from '../../../../components/buttons/DefaultButton/styles';

const Card = memo(({ subscription, setOpen }: IPremiumCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    productId,
    expiresDate,
  } = subscription;
  const id = productId.toLowerCase();
  const isPremium = productId === ProductID.MONTH || productId === ProductID.YEAR;
  const isFriends = productId === ProductID.FRIENDS;

  return (
    <>
      <SubscriptionCard>
        <CardContentLeft>
          <RedBadge>active</RedBadge>
          <Box>
            <StyledLogo />
            {isPremium
              ? <PremiumBadge>Premium</PremiumBadge>
              : <PremiumBadge>{productId}</PremiumBadge>}
          </Box>
          <PriceBox>
            <BodyText>{`billed ${SUBSCRIPTION_MAPPING[id].text}`}</BodyText>
            <PriceContainer>
              <Price>{`$${SUBSCRIPTION_MAPPING[id].price}`}</Price>
              <span>/</span>
              <BodyText>{SUBSCRIPTION_MAPPING[id].period}</BodyText>
            </PriceContainer>
          </PriceBox>
          <BodyText>{`Your subscription will renew on ${transformDateFull(expiresDate)}`}</BodyText>
        </CardContentLeft>
        <CardContentRight>
          {isFriends && (
          <SmallBlackBtn type="button" onClick={() => setOpen(true)}>
            <StyledDefaultButtonTitle>
              Friends Sharing Settings
            </StyledDefaultButtonTitle>
          </SmallBlackBtn>
          )}
          <Block>
            <TransparentBtn type="button" onClick={() => setIsOpen(true)}>
              <CancelSubscriptionText>Cancel Subscription</CancelSubscriptionText>
            </TransparentBtn>
            <TextBreak>
              {`If you cancel now, you can still access
            your subscription until ${transformDateShort(expiresDate)}`}
            </TextBreak>
          </Block>
        </CardContentRight>
      </SubscriptionCard>
      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Cancel Subscription"
        onCancelButtonClick={() => setIsOpen(false)}
        cancelText="Close"
      >
        <BodyText>Subscription can not be canceled at the moment.</BodyText>
      </CustomModal>
    </>
  );
});

export default Card;
