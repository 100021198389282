import { RootState } from '../../../store';

export const getSubscriptionsSelector = (state: RootState) => state.subscription.subscriptions;
export const getIsLoadingSelector = (state: RootState) => state.subscription.isLoading;
export const getIsInvitationLoadingSelector = (
  state: RootState,
) => state.subscription.isInvitationLoading;
export const getIsInvitationSuccessfullSelector = (
  state: RootState,
) => state.subscription.isInvitationSuccessfull;
