import { createGlobalStyle } from 'styled-components';
import { fontFamily } from '../../../utils/constants';
// @ts-ignore
import LeagueGothic from '../../../fonts/LeagueGothic-Regular.otf';
// @ts-ignore
import LabilGroteskTrialLight from '../../../fonts/LabilGroteskTrial-Light.otf';
// @ts-ignore
import LabilGroteskTrialMedium from '../../../fonts/LabilGroteskTrial-Medium.otf';
// @ts-ignore
// import LabilGroteskTrialRegular from '../../../fonts/LabilGroteskTrial-Regular.otf';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: ${fontFamily};
  };
  
  html {
    scroll-behavior: smooth;
  }

  /*@font-face {
    font-family: 'Labil Grotesk Trial';
    font-weight: 300;
    src: url(${LabilGroteskTrialLight}) format('truetype');
    font-display: fallback;
  }
  @font-face {
    font-family: 'Labil Grotesk Trial';
    font-weight: 400;
    src: url(${LabilGroteskTrialLight}) format('truetype');
    font-display: fallback;
  }
  @font-face {
    font-family: 'Labil Grotesk Trial';
    font-weight: 600;
    src: url(${LabilGroteskTrialMedium}) format('truetype');
    font-display: fallback;
  }
  @font-face {
    font-family: 'League Gothic';
    font-weight: 400;
    src: url(${LeagueGothic}) format('truetype');
    font-display: fallback;
  }*/

`;

export default GlobalStyle;
