import React, {
  memo, useMemo,
} from 'react';
import uuid from 'react-uuid';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel } from 'swiper/modules';
import {
  FeatureText,
  PageContainer,
  StyledImage,
  StyledSlide,
  StyledSliderContainer,
  StyledSwiperSlide,
  SubTitle,
  TextContentContainer,
  Title,
} from './styles';

import sliderMobile1 from '../../../../images/benefit-mobile-1.jpg';
import sliderMobile2 from '../../../../images/benefit-mobile-2.jpg';
import sliderMobile3 from '../../../../images/benefit-mobile-3.jpg';
import sliderMobile4 from '../../../../images/benefit-mobile-4.jpg';
import sliderDesktop1 from '../../../../images/benefit-desktop-1.jpg';
import sliderDesktop2 from '../../../../images/benefit-desktop-2.jpg';
import sliderDesktop3 from '../../../../images/benefit-desktop-3.jpg';
import sliderDesktop4 from '../../../../images/benefit-desktop-4.jpg';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../../../utils/constants';

const Home4 = memo(() => {
  const slides = [
    {
      imageMobile: sliderMobile1,
      imageDesktop: sliderDesktop1,
      title: 'Unleash Your Grace',
      text: 'Dive into Swanly\'s courses, where classical ballet meets contemporary techniques. Perfect for every level, our sessions infuse the poise of ballet with holistic well-being.',
    },
    {
      imageMobile: sliderMobile2,
      imageDesktop: sliderDesktop2,
      title: 'Personalized Paths to Wellness',
      text: 'Whether you\'re looking for muscle toning, flexibility, or a mental health boost, Swanly\'s adaptive courses mold to your unique journey, ensuring every step you take is meaningful.',
    },
    {
      imageMobile: sliderMobile3,
      imageDesktop: sliderDesktop3,
      title: 'Community Spirit, Individual Growth',
      text: 'Join a global community of Swanly enthusiasts. Share, learn, and grow together, while still focusing on your individual progress and mental harmony.',
    },
    {
      imageMobile: sliderMobile4,
      imageDesktop: sliderDesktop4,
      title: 'From Experts, For You',
      text: 'Designed by seasoned dancers and wellness professionals, Swanly\'s courses give you access to top-tier instruction right at your fingertips. Experience the synergy of ballet and wellness like never before.',
    },
  ];

  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.mobile, [width]);

  return (
    <PageContainer>
      <FeatureText>
        Swanly&apos;s Signature Features
      </FeatureText>
      {
        isMobile ? (
          <>
            {
              slides.map((imageSliderArray) => (
                <StyledSlide key={uuid()}>
                  <TextContentContainer key={uuid()}>
                    <Title key={uuid()}>
                      {imageSliderArray.title}
                    </Title>
                    <SubTitle key={uuid()}>
                      {imageSliderArray.text}
                    </SubTitle>
                  </TextContentContainer>
                  <StyledImage
                    key={imageSliderArray.imageDesktop}
                    image={imageSliderArray.imageDesktop}
                  />
                </StyledSlide>
              ))
            }
          </>
        ) : (
          <StyledSliderContainer>
            <Swiper
              direction="vertical"
              modules={[Mousewheel]}
              mousewheel={{
                releaseOnEdges: true,
              }}
              speed={3000}
            >
              {
                slides.map((imageSliderArray) => (
                  <SwiperSlide key={uuid()}>
                    <StyledSwiperSlide key={uuid()}>
                      <StyledImage
                        key={uuid()}
                        image={imageSliderArray.imageDesktop}
                      />
                      <TextContentContainer key={uuid()}>
                        <Title key={uuid()}>
                          {imageSliderArray.title}
                        </Title>
                        <SubTitle key={uuid()}>
                          {imageSliderArray.text}
                        </SubTitle>
                      </TextContentContainer>
                    </StyledSwiperSlide>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </StyledSliderContainer>
        )
      }

    </PageContainer>
  );
});

export default Home4;
