import React, { memo } from 'react';
import { CONTACT_EMAIL, INSTAGRAM_URL } from '../../../../containers/Contact/constants';
import { IProfileResponse } from '../../../../types';
import {
  InstagramIcon, LinksContainer,
} from '../../Footer/styles';
import {
  MenuItemAboutUsLink,
  MenuItemAboutUsText, MenuItemCoursesLink,
  MenuItemCoursesText, MenuItemSignInLink, MenuItemSignInText,
} from '../constants';
import { ProfileCardComponent } from '../ProfileCard';
import { ProfileCardColors } from '../ProfileCard/types';
import MobileMenuLink from './MobileMenuLink';
import {
  FooterMobile,
  FooterMobileContainer,
  HrLine,
  LinkEmail,
  LinkInstagram,
  MobileMenuContainer, MobileMenuItemsContainer,
} from './styles';

interface IMobileMenuProps {
  profile: IProfileResponse | null,
  userEnabled: boolean;
}

export const MobileMenu = memo<IMobileMenuProps>(({ profile, userEnabled }) => (
  <MobileMenuContainer>
    {profile && userEnabled ? (
      <>
        <ProfileCardComponent profile={profile} theme={ProfileCardColors.DARK} />
        <HrLine />
        <MobileMenuItemsContainer>
          <MobileMenuLink link={MenuItemCoursesLink} label={MenuItemCoursesText} />
          <MobileMenuLink link={MenuItemAboutUsLink} label={MenuItemAboutUsText} />
          {/* <MobileMenuLink link={MenuItemBlogLink} label={MenuItemBlogText} /> */}
        </MobileMenuItemsContainer>
      </>
    )
      : (
        <MobileMenuItemsContainer>
          <MobileMenuLink link={MenuItemSignInLink} label={MenuItemSignInText} />
          <MobileMenuLink link={MenuItemCoursesLink} label={MenuItemCoursesText} />
          <MobileMenuLink link={MenuItemAboutUsLink} label={MenuItemAboutUsText} />
          {/* <MobileMenuLink link={MenuItemBlogLink} label={MenuItemBlogText} /> */}
        </MobileMenuItemsContainer>
      )}
    <FooterMobile userEnabled={userEnabled}>
      <HrLine />
      <FooterMobileContainer>
        <LinksContainer>
          <LinkInstagram to={INSTAGRAM_URL}>
            Follow us
            <InstagramIcon />
          </LinkInstagram>
          <LinkEmail
            to="#"
            onClick={() => {
              window.location.href = `mailto:${CONTACT_EMAIL}`;
            }}
          >
            {CONTACT_EMAIL}
          </LinkEmail>
        </LinksContainer>
      </FooterMobileContainer>
    </FooterMobile>
  </MobileMenuContainer>
));
