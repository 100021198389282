import styled from 'styled-components';
import { IImageProps } from '../../../../core/types';
import { StyledEllipsisSpan } from '../../../common/styles';

export const CoursesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  min-height: 220px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
  }
`;

export const CourseWideCard = styled.div<IImageProps>`
  background-image: url(${(props) => props.image});
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-width: 343px;
  min-height: 184px;
  padding: 12px;
  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 491px;
    padding: 16px;
    min-height: 280px;
  }
`;

export const NewCoursesPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  padding: 16px 16px 64px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 40px;
    padding: 16px 80px 96px;
  }
`;

export const PageTitle = styled.span`
  font: ${(props) => props.theme.typography.h3_mobile.font};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.black100};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h5.font};
  }
`;

export const StyledCardTitle = styled.span`
  font: ${(props) => props.theme.typography.h2_mobile.font};
  margin-bottom: 4px;
  text-transform: capitalize;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h5.font};
    margin-bottom: 8px;
  }
`;
export const StyledCardSubTitle = styled(StyledEllipsisSpan)`
  font: ${(props) => props.theme.typography.body5.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.body3_new.font};
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: calc(100% - 22px);
  margin: 0 auto;
  background-color: ${(props) => props.theme.palette.black65};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(100% - 160px);
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: ${(props) => props.theme.palette.white};
`;

export const SliderCourseCardLevel = styled.span`
  font: ${(props) => props.theme.typography.subtitle4.font};
  text-transform: uppercase;
`;
export const SliderCourseCardTitle = styled.span`
  font: ${(props) => props.theme.typography.h5.font};
  margin-bottom: 8px;
  text-transform: capitalize;
`;

export const SliderCourseCardSubTitle = styled(StyledEllipsisSpan)`
  font: ${(props) => props.theme.typography.body4_new.font};
`;
