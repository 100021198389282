import styled from 'styled-components';
import { StyledContentContainer, StyledForm, StyledLogoMobileIcon } from '../../../components/wallpapers/auth/styles';

export const StyledRegisterContentContainer = styled(StyledContentContainer)`
  gap: 40px;
`;

export const StyledRegisterLogoMobileIcon = styled(StyledLogoMobileIcon)`
  margin-bottom: 8px;
`;

export const StyledRegisterForm = styled(StyledForm)`
  margin-bottom: unset;
`;

export const StyledVerificationForm = styled(StyledRegisterForm)`
  max-width: 263px;
`;
