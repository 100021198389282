import React, {
  memo,
  ReactElement,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BackButton,
  StyledCloseIcon,
  StyledContentWrapper,
  StyledLoginWrapper,
  StyledPageContainer,
  StyledSwanlyIcon,
  StyledWallpaperContainer,
  StyledWallpaperTitle,
} from './styles';

interface IAuthWallpaperProps {
  isMobile?: boolean;
  backButtonRoute?: string | null;
  children?: ReactElement
}

const AuthWallpaper = memo<IAuthWallpaperProps>((
  {
    isMobile,
    children,
    backButtonRoute,
  },
) => {
  const navigate = useNavigate();
  return (
    <StyledLoginWrapper>
      <BackButton
        onClick={
          () => (backButtonRoute ? navigate(backButtonRoute) : navigate(-1))
        }
      >
        <StyledCloseIcon />
      </BackButton>
      {!isMobile && (
        <StyledWallpaperContainer>
          <StyledSwanlyIcon />
          <StyledWallpaperTitle>
            Join the movement
          </StyledWallpaperTitle>
        </StyledWallpaperContainer>
      )}
      <StyledPageContainer>
        <StyledContentWrapper>
          {children}
        </StyledContentWrapper>
      </StyledPageContainer>
    </StyledLoginWrapper>
  );
});

export default AuthWallpaper;
