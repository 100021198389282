import { AxiosError, ResponseType } from 'axios';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../utils/helpers';
import { IErrorResponse } from '../../types';
import axiosApiInstance from './interceptors';

interface IPostOptions {
  path: string;
  data: { [key:string]: any };
}

interface IAxiosResponse {
  [key:string]: any
}

export default async function put(options:IPostOptions, contentType: string = 'application/json', responseType: ResponseType = 'json', params?: any): Promise<any> {
  const { path, data = {} } = options;
  try {
    const res: IAxiosResponse = await axiosApiInstance.put<IAxiosResponse>(path, data, {
      headers: {
        'content-type': contentType,
      },
      responseType,
      params,
    });
    if (res.data.error) {
      throw res.data.error;
    }
    return res.data;
  } catch (error: any) {
    if (error?.response?.data) {
      const { response } = error as AxiosError<IErrorResponse>;
      if (response?.data.statusCode === 500) {
        toast(getErrorMessage(response?.data));
      }
    }
    throw error?.response?.data;
  }
}
