import React, {
  memo, useCallback, useEffect, useMemo,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getCoursesErrorSelector,
  getCoursesSelector,
  getGlobalProfileSelector,
  getCurrentUserSelector,
} from '../../core/selectors';
import { fetchCourses } from '../../core/services';
import PageWrapper from './components/PageWrapper';
import {
  getCurrentCourseLoadingSelector,
  getCurrentCourseSelector,
  getCurrentLessonLoadingSelector,
  getCurrentLessonSelector,
} from './selectors';
import {
  getCourse, getLesson, removeFavorite, setFavorite, setProgress,
} from './services';
import { setCurrentCourse, setCurrentLesson } from './slices';
import { ILesson } from '../../types';
import { FavoriteContentType } from '../../core/types';
import PageLoader from '../../components/loaders/PageLoader';

const Course = memo(() => {
  const currentUser = useAppSelector(getCurrentUserSelector);
  const currentUserProfile = useAppSelector(getGlobalProfileSelector);
  const courses = useAppSelector(getCoursesSelector);
  const coursesError = useAppSelector(getCoursesErrorSelector);
  const currentCourse = useAppSelector(getCurrentCourseSelector);
  const isCurrentCourseLoading = useAppSelector(getCurrentCourseLoadingSelector);
  const currentLesson = useAppSelector(getCurrentLessonSelector);
  const isCurrentLessonLoading = useAppSelector(getCurrentLessonLoadingSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isSubscribed = useMemo(() => (
    currentUserProfile?.isFriend || currentUserProfile?.isSubscribed
  ), [currentUserProfile]);

  useEffect(() => {
    const lessonId = location?.state?.lessonId;
    if (lessonId) {
      dispatch(getLesson(lessonId));
      navigate(location.pathname, { replace: true });
    }
  }, [location.state]);

  const { id } = useParams();

  const handleOnLessonClick = useCallback(
    (lessonId: string) => {
      dispatch(getLesson(lessonId));
    },
    [dispatch],
  );

  const handleClosePlayerClick = useCallback(() => {
    dispatch(setCurrentLesson(null));
  }, [dispatch]);

  const handleOnProgressVideo = useCallback((lessonId: string) => {
    if (currentUser) {
      dispatch(setProgress({ lessonId }));
    }
  }, [dispatch]);

  const handleOnFavoriteClick = useCallback(
    (lesson: ILesson) => {
      dispatch(
        lesson.isFavorite
          ? removeFavorite({ contentId: lesson.id, contentType: FavoriteContentType.LESSON })
          : setFavorite({ contentId: lesson.id, contentType: FavoriteContentType.LESSON }),
      );
    },
    [dispatch],
  );

  const handleOnShareClick = useCallback(
    (lesson: ILesson) => {
      toast.success(lesson.name);
    },
    [dispatch],
  );

  useEffect(() => {
    if (!courses && !coursesError) {
      dispatch(fetchCourses({ page: 1, pageSize: 10 }));
    }
  }, [courses, coursesError, dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getCourse(id));
    }
    return () => { dispatch(setCurrentCourse(null)); };
  }, [id, dispatch]);

  if ((!currentCourse || isCurrentCourseLoading)) {
    return (
      <PageLoader />
    );
  }

  return (
    <PageWrapper
      isAuth={!!currentUser}
      courses={courses}
      currentCourse={currentCourse}
      hasSubscription={isSubscribed}
      onLessonClick={handleOnLessonClick}
      currentLesson={currentLesson}
      isCurrentLessonLoading={isCurrentLessonLoading}
      onFavoriteClick={handleOnFavoriteClick}
      onShareClick={handleOnShareClick}
      onClosePlayerClick={handleClosePlayerClick}
      onProgress={handleOnProgressVideo}
    />
  );
});

export default Course;
