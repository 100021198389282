import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  post,
} from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse, INotifyMeResponse } from '../../../core/types';
import { INotifyMeRequest } from '../types';

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const notifyMe = createAsyncThunk<INotifyMeResponse, INotifyMeRequest, ICommonChunkConfig>(
  'contact/notifyMe',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await post({ path: API_ROUTES.NOTIFY_ME, data: _data });
      return response as INotifyMeResponse;
    } catch (error) {
      return rejectWithValue(error as IErrorResponse);
    }
  },
);
