import styled from 'styled-components';
import { ReactComponent as EyeOpened } from '../../../images/opened-eye.svg';
import { ReactComponent as EyeClosed } from '../../../images/closed-eye.svg';
import CustomStyledInput from '../CustomStyledInput';

export const OpenedEye = styled(EyeOpened)`
  color: ${(props) => props.theme.palette.black65};
  cursor: pointer;
  width: 16px;
  height: 10px;
`;

export const ClosedEye = styled(EyeClosed)`
  color: ${(props) => props.theme.palette.black65};
  cursor: pointer;
  width: 16px;
  height: 10px;
`;

export const CustomPasswordStyledInput = styled(CustomStyledInput)`
  & input {
    padding-right: 36px;
  }
`;
