import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { ILabelProps } from '../CustomStyledInput/types';
import { IDatepickerStyleProps } from './types';

export const CustomStyledDatepicker = styled(DatePicker)<IDatepickerStyleProps>`
  width: 100%;
  color: ${(props) => props.theme.palette.black100};
  border-radius: 12px;
  padding: 0 16px;
  border: 1px solid ${(props) => props.theme.palette.black25};
  height: 48px;
  font: ${(props) => props.theme.typography.body2.font};
  ${({ error, theme }) => error && `
    border-color: ${theme.palette.red};
  `};
  &:focus,
  &:active {
    outline: 0
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledLabel = styled.label<ILabelProps>`
  font: ${(props) => props.theme.typography.caption2.font};
  color: ${({ error, theme }) => {
    if (error) {
      return theme.palette.red;
    }
    return theme.palette.black100;
  }};
`;

export const DatepickerWrapper = styled.label`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const StyledIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 16px;
`;
