import { fontFamily } from './constants';

export const theme = {
  palette: {
    black: '#000000',
    black100: '#171717',
    black95: '#232323',
    black80: '#454545',
    black65: '#686868',
    black40: '#A2A2A2',
    black25: '#C5C5C5',
    black10: '#E8E8E8',
    black8: '#ECEBEB',
    black5: '#F3F3F3',
    gray: 'rgba(0, 0, 0, 0.7)',
    transparentGray30: 'rgba(35,35,35, 30%)',
    transparentGray50: 'rgba(35,35,35, 50%)',
    secondaryGray: 'rgba(0, 0, 0, 0.3)',
    tetriaryGray: 'rgba(0, 0, 0, 0.1)',
    quaternaryGray: 'rgba(0, 0, 0, 0.03)',
    titleGray: 'rgba(15, 14, 14, 0.00)',
    white: '#FFFFFF',
    lightGreen: '#E3FD9C',
    green: '#91C600',
    red: '#E33A3A',
    red100: '#E33A3A',
    red10: '#FCEBEB',
    red65: '#ED7F7F',
    dirtyPink: '#DAD1D2',
    orange: '#FDCA69',
    blue: '#0E46CA',
    skyBlue: '#82ABFF',
    arctic: '#BDE3FF',
    lightBlue: '#E7F5FF',
    blueWhite: '#F4FAFE',
    overlay: '#FBFBFB',
    outline: '#E2E2E2',
    lightGray: '#F6F6F6',
    darkGray: '#B3B3B3',
    secondary: '#4D4D4D',
    gradientGray: '#A0A8BC85',
    gradientDarkGray: '#36425F26',
    secondaryGradientLightGray: '#41414226',
    secondaryGradientWhite: '#50505085',
  },
  shadow: {
    dropShadow_white: '0px 0px 17px 0px rgba(255, 55, 55, 0.55)',
    profileCardShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.1);',
    primaryButtonGlow: '0px 4px 16px 0px rgba(255, 55, 55, 0.50), 0px -4px 16px 0px rgba(255, 55, 55, 0.50)',
    primaryButtonGlowSm: '0px 4px 8px 0px rgba(255, 55, 55, 0.50), 0px -4px 8px 0px rgba(255, 55, 55, 0.50)',
    secondaryShadow: '0px 4px 16px 0px rgba(255, 255, 255, 0.3), 0px -4px 16px 0px rgba(255, 255, 255, 0.3)',
    footerButtonShadow: '0px 4px 16px 0px #FFFFFF4D, 0px -4px 16px 0px #FFFFFF4D',
  },
  typography: {
    fontFamily,
    color: '#000000',
    mobileTitle: {
      fontSize: '54px',
      lineHeight: '54px',
      fontWeight: '400',
      fontFamily: 'League Gothic, sans-serif',
      // font: 'normal 400 54px/54px League Gothic',
      textTransform: 'uppercase',
      letterSpacing: '0.3px',
    },
    h1_mobile: {
      fontSize: '56px',
      lineHeight: '56px',
      fontWeight: '400',
      fontFamily: 'League Gothic, sans-serif',
      font: 'normal 400 56px/56px League Gothic',
      letterSpacing: '0.3px',
    },
    h1_mobile_arizona: {
      fontSize: '56px',
      lineHeight: '56px',
      fontWeight: '300',
      fontFamily: 'ABC Arizona Plus Variable Unlicensed Trial, sans-serif',
      font: 'normal 300 56px/56px ABC Arizona Plus Variable Unlicensed Trial',
      letterSpacing: '0.3px',
    },
    h1_mobile_new: {
      fontSize: '64px',
      lineHeight: '64px',
      fontWeight: '400',
      fontFamily: 'League Gothic, sans-serif',
      font: 'normal 400 64px/64px League Gothic',
    },
    h1: {
      fontSize: '112px',
      lineHeight: '112px',
      fontStyle: 'normal',
      fontWeight: '400',
      font: 'normal 400 112px/112px League Gothic',
      letterSpacing: '0.3px',
    },
    h1_arizona: {
      fontSize: '80px',
      lineHeight: '80px',
      fontWeight: '300',
      fontFamily: 'ABC Arizona Plus Variable Unlicensed Trial, sans-serif',
      font: 'normal 300 80px/80px ABC Arizona Plus Variable Unlicensed Trial',
      letterSpacing: '0.3px',
    },
    h1_new: {
      fontSize: '120px',
      lineHeight: '120px',
      fontStyle: 'normal',
      fontWeight: '400',
      font: 'normal 400 120px/120px League Gothic',
    },
    h2_lowercase: {
      fontSize: '96px',
      lineHeight: '96px',
      fontStyle: 'normal',
      fontWeight: '400',
      fontFamily: 'League Gothic, sans-serif',
      font: 'normal 400 96px/96px League Gothic',
    },
    h2_mobile: {
      fontSize: '40px',
      lineHeight: '40px',
      fontStyle: 'normal',
      fontWeight: '400',
      fontFamily: 'League Gothic, sans-serif',
      font: 'normal 400 40px/40px League Gothic',
    },
    h1_mobile_lowercase: {
      fontSize: '56px',
      lineHeight: '56px',
      fontWeight: '400',
      fontFamily: 'League Gothic, sans-serif',
      font: 'normal 400 56px/56px League Gothic',
      letterSpacing: '0.3px',
    },
    h2: {
      fontSize: '48px',
      lineHeight: '48px',
      fontStyle: 'normal',
      fontWeight: '400',
      font: 'normal 400 48px/48px League Gothic',
    },
    h3: {
      fontSize: '64px',
      lineHeight: '64px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 64px/64px League Gothic',
    },
    h3_mobile: {
      fontSize: '36px',
      lineHeight: '36px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 36px/36px League Gothic',
    },
    h4_mobile: {
      fontSize: '18px',
      lineHeight: '18px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 18px/18px League Gothic',
    },
    h5: {
      fontSize: '48px',
      lineHeight: '48px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 48px/48px League Gothic',
    },
    h5_popper: {
      fontSize: '28px',
      lineHeight: '28px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 28px/28px League Gothic',
    },
    h5_mobile: {
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 14px/14px League Gothic',
    },
    h5_mobile_new: {
      fontSize: '36px',
      lineHeight: '36px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 36px/36px League Gothic',
    },
    h6: {
      fontSize: '24px',
      lineHeight: '25px',
      fontStyle: 'normal',
      fontWeight: '400',
      font: 'normal 400 20px/22px League Gothic',
    },
    h6_mobile: {
      fontSize: '20px',
      lineHeight: '22px',
      fontStyle: 'normal',
      fontWeight: '400',
      font: 'normal 400 20px/22px League Gothic',
      letterSpacing: '0.5px',
    },
    subtitle1: {
      fontSize: '24px',
      lineHeight: '28.8px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 24px/28.8px ${fontFamily}`,
    },
    subtitle1_helvetica: {
      fontSize: '24px',
      lineHeight: '28.8px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: 'normal 600 24px/28.8px Helvetica',
    },
    subtitle2: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 20px/24px ${fontFamily}`,
    },
    subtitle2_new: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 22px/26px ${fontFamily}`,
    },
    subtitle2_helvetica: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 22px/26px Helvetica',
    },
    subtitle2_mobile: {
      fontSize: '24px',
      lineHeight: '28.8px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 24px/28.8px ${fontFamily}`,
    },
    subtitle3: {
      fontSize: '18px',
      lineHeight: '21.6px',
      fontWeight: '600',
      fontWeightLite: '500',
      fontStyle: 'normal',
      font: `normal 600 18px/21.6px ${fontFamily}`,
    },
    subtitle3_new: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 16px/20px ${fontFamily}`,
    },
    subtitle4: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 14px/18px ${fontFamily}`,
    },
    subtitle22_26: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 22px/26px ${fontFamily}`,
    },
    subtitle8: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 14px/18px ${fontFamily}`,
    },
    subtitle10: {
      fontSize: '14px',
      lineHeight: '20.02px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 14px/20.2px ${fontFamily}`,
    },
    caption: {
      fontSize: '24px',
      lineHeight: '28.8px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 24px/28.8px ${fontFamily}`,
    },
    caption2: {
      fontSize: '12px',
      lineHeight: '14.88px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 12px/14.88px ${fontFamily}`,
    },
    caption_mobile: {
      fontSize: '16px',
      lineHeight: '19.2px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 16px/19.2px ${fontFamily}`,
    },
    caption_mobile_2: {
      fontSize: '16px',
      lineHeight: '19.2px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 16px/19.2px ${fontFamily}`,
    },
    body: {
      fontSize: '24px',
      lineHeight: '33.6px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 24px/33.6px ${fontFamily}`,
    },
    body_helvetica: {
      fontSize: '24px',
      lineHeight: '33.6px',
      fontWeight: '300',
      fontStyle: 'normal',
      font: 'normal 300 24px/33.6px Helvetica',
    },
    body_mobile_new: {
      fontSize: '21px',
      lineHeight: '25.2px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 21px/25.2px ${fontFamily}`,
    },
    body_mobile: {
      fontSize: '16px',
      lineHeight: '22.4px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 300 16px/22.4px ${fontFamily}`,
    },
    body_mobile_helvetica: {
      fontSize: '16px',
      lineHeight: '22.4px',
      fontWeight: '300',
      fontStyle: 'normal',
      font: 'normal 300 16px/22.4px Helvetica',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '22.4px',
      fontWeight: '300',
      fontStyle: 'normal',
      font: `normal 300 16px/22.4px ${fontFamily}`,
    },
    body1Header: {
      fontSize: '28px',
      lineHeight: '39.2px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 28px/39.2px ${fontFamily}`,
    },
    popperHeader: {
      fontSize: '28px',
      lineHeight: '39.2px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 28px/39.2px ${fontFamily}`,
    },
    body1_new: {
      fontSize: '32px',
      lineHeight: '38px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 32px/38px ${fontFamily}`,
    },
    body2: {
      fontSize: '16px',
      lineHeight: '22.4px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 16px/22.4px ${fontFamily}`,
    },
    body2_mobile: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 20px/28px ${fontFamily}`,
    },
    body2_new: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 24px/32px ${fontFamily}`,
    },
    body3: {
      fontSize: '14px',
      lineHeight: '16.8px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 14px/16.8px ${fontFamily}`,
    },
    body3_new: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 20px/28px ${fontFamily}`,
    },
    body3_mobile: {
      fontSize: '16px',
      lineHeight: '21.4px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 16px/21.4px ${fontFamily}`,
    },
    body4: {
      fontSize: '14px',
      lineHeight: '16.8px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 14px/16.8px ${fontFamily}`,
    },
    body16_20: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 16px/20px ${fontFamily}`,
    },
    body4_mobile: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 16px/20px ${fontFamily}`,
    },
    body14_20: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 14px/20px ${fontFamily}`,
    },
    body14_20_500: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 14px/20px ${fontFamily}`,
    },
    body14_22: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 14px/22px ${fontFamily}`,
    },
    body4_new: {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 16px/22px ${fontFamily}`,
    },
    body5: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 12px/16px ${fontFamily}`,
    },
    body5_mobile: {
      fontSize: '14px',
      lineHeight: '16.8px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 14px/16.8px ${fontFamily}`,
    },
    body6_mobile: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: `normal 400 12px/16px ${fontFamily}`,
    },
    button: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 18px/24px ${fontFamily}`,
    },
    button_helvetica: {
      fontSize: '18px',
      lineHeight: '18px',
      fontWeight: '400',
      fontStyle: 'normal',
      font: 'normal 400 18px/24px Helvetica',
    },
    button_xl: {
      fontSize: '21px',
      lineHeight: '21px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 21px/21px ${fontFamily}`,
    },
    button_xs: {
      fontSize: '10px',
      lineHeight: '10px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 10px/10px ${fontFamily}`,
    },
    button_s: {
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: '500',
      fontStyle: 'normal',
      font: `normal 500 14px/14px ${fontFamily}`,
    },
    overline: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 12px/16px ${fontFamily}`,
    },
    premiumButtonWeb: {
      fontSize: '18px',
      lineHeight: '18px',
      fontWeight: '600',
      font: `normal 600 18px/18px ${fontFamily}`,
    },
    premiumButtonMobile: {
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: '500',
      font: `normal 500 14px/14px ${fontFamily}`,
    },
    overline_mobile: {
      fontSize: '10px',
      lineHeight: '12px',
      fontWeight: '600',
      fontStyle: 'normal',
      font: `normal 600 10px/12px ${fontFamily}`,
    },
    logo: {
      color: '#072365',
      fontWeight: '900',
      fontSize: '18.85px',
      lineHeight: '17.39px',
      fontStyle: 'italic',
    },
    megaFont: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '70px',
      lineHeight: '120%',
      font: `normal 700 70px/120% ${fontFamily}`,
    },
    landingTitleMedium: {
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '40px',
      lineHeight: '50px',
      font: `normal 800 40px/50px ${fontFamily}`,
    },
  },
  breakpoints: {
    mobile: '767px',
    tablet: '768px',
    desktop: '1200px',
    desktop_medium: '1680px',
  },
};
