import React, { memo, useMemo, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import uuid from 'react-uuid';
import {
  PageContainer,
  StyledAvatar,
  StyledLocation,
  StyledName,
  StyledNameContainer,
  StyledNavButton,
  StyledNavigateButtonsContainer,
  StyledNextIcon,
  StyledPrevIcon,
  StyledSlideContainer,
  StyledSliderContainer,
  StyledTextContainer,
  SubTitle,
  TextContentContainer,
  Title,
} from './styles';
import avatar1 from '../../../../images/avatar-1.jpg';
import avatar2 from '../../../../images/avatar-2.jpg';
import avatar3 from '../../../../images/avatar-3.jpg';
import avatar4 from '../../../../images/avatar-4.jpg';
import avatar5 from '../../../../images/avatar-5.jpg';
import avatar6 from '../../../../images/avatar-6.jpg';
import avatar7 from '../../../../images/avatar-7.jpg';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../../../utils/constants';

const Home6 = memo(() => {
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.mobile, [width]);
  const feedbackArr = [
    {
      image: avatar1,
      name: 'Maya D.',
      location: 'Seattle, WA',
      text: '"Swanly is a breath of fresh air. In the short time I\'ve been using it, the app has made me feel seen and understood, embracing the unique needs of the feminine body. The routines are detailed and intuitive, and I\'ve begun feeling more connected to myself."',
    },
    {
      image: avatar2,
      name: 'Sophia M.',
      location: 'Los Angeles, CA',
      text: '"Since joining Swanly, my mornings are now filled with a delightful blend of ballet routines that are both invigorating and soul-soothing. Every movement feels purposeful. My posture has improved dramatically, and my confidence has skyrocketed."',
    },
    {
      image: avatar3,
      name: 'Vanessa P.',
      location: 'Boston, MA',
      text: '"Never did I imagine that combining ballet and stretching could be so revolutionary for my fitness journey. Swanly is refreshing, fun, and genuinely innovative. I\'m excited to dive deeper and explore more that the platform has to offer."',
    },
    {
      image: avatar4,
      name: 'Lila K.',
      location: 'New York, NY',
      text: '"The stretching sessions Swanly offers are a revelation. Only a week in and the chronic tension in my back is already subsiding. It\'s an emotional and physical relief that I never expected to find in an app. Eagerly awaiting more content!"',
    },
    {
      image: avatar5,
      name: 'Aria J.',
      location: 'Chicago, IL',
      text: '"At first, I was unsure about trying another wellness app. But Swanly\'s holistic approach to well-being, blending the beauty of ballet with effective stretching, has been an absolute game-changer for my daily routine."',
    },
    {
      image: avatar6,
      name: 'Ethan W.',
      location: 'Dallas, TX',
      text: '"My partner stumbled upon Swanly and now she can\'t stop talking about it. She\'s even got me trying some of the routines! We\'re both eagerly waiting for the community features to launch. It\'s a great way for us to bond."',
    },
    {
      image: avatar7,
      name: 'Anna V.',
      location: 'San Francisco, CA',
      text: '"A friend introduced me to Swanly, and I\'ve been hooked since day one. The fusion of ballet techniques with deep stretches is genuinely unique. The movements are fluid, the instructions are clear, and I genuinely look forward to my sessions now."',
    },
  ];
  return (
    <PageContainer>
      <TextContentContainer>
        <Title>
          What people say&nbsp;about&nbsp;us
        </Title>
      </TextContentContainer>
      <StyledSliderContainer>
        <Swiper
          modules={[Autoplay, Navigation]}
          slidesPerView={1}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
            loop: true,
          }}
          freeMode
          speed={1500}
        >
          {
            feedbackArr?.map((feedback) => (
              <SwiperSlide key={uuid()}>
                <StyledSlideContainer key={uuid()}>
                  <StyledAvatar image={feedback.image} key={uuid()} />
                  <StyledTextContainer key={uuid()}>
                    <StyledNameContainer key={uuid()}>
                      <StyledName key={uuid()}>
                        {feedback.name}
                      </StyledName>
                      {
                        !isMobile && (
                          <StyledLocation key={uuid()}>
                            {' '}
                            &minus;
                            {' '}
                            {feedback.location}
                          </StyledLocation>
                        )
                      }
                    </StyledNameContainer>
                    <SubTitle key={uuid()}>
                      {feedback.text}
                    </SubTitle>
                  </StyledTextContainer>
                </StyledSlideContainer>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </StyledSliderContainer>
      <StyledNavigateButtonsContainer>
        <StyledNavButton ref={prevRef}>
          <StyledPrevIcon />
        </StyledNavButton>
        <StyledNavButton ref={nextRef}>
          <StyledNextIcon />
        </StyledNavButton>
      </StyledNavigateButtonsContainer>
    </PageContainer>
  );
});

export default Home6;
