import React, { FC, memo, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PageContainer, Tab, TabActive, TabsContainer, Tabs,
} from '../../styles';
import { TABS } from '../../constants';
import { IProfileTabsProps } from '../../types';

const ProfileTabs: FC<PropsWithChildren<IProfileTabsProps>> = memo(({ location, children }) => {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <TabsContainer>
        <Tabs>
          {TABS.map(({ path, name, id }) => (path === location ? (
            <TabActive key={id} onClick={() => navigate(path)}>{name}</TabActive>
          ) : (
            <Tab key={id} onClick={() => navigate(path)}>{name}</Tab>
          )))}
        </Tabs>
        {children}
      </TabsContainer>
    </PageContainer>
  );
});

export default ProfileTabs;
