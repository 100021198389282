import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
`;

export const Text = styled.p`
  font: ${(props) => props.theme.typography.body4_mobile.font};
  color: ${(props) => props.theme.palette.black100};
`;
