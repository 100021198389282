import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetPremiumHeaderButton } from '../../../../components/buttons/DefaultButton/styles';
import {
  HeaderButtonsContainer, HeaderLogo, HeaderLogoWhite,
} from '../styles';
import { APP_ROUTES } from '../../../router/appRoutes';
import { ReactComponent as BurgerClose } from '../../../../images/burger-close.svg';
import { ReactComponent as BurgerOpen } from '../../../../images/burger-open.svg';

interface HeaderMobileProps {
  isMobile: boolean;
  blackTheme: boolean;
  subscribeStatus?: boolean;
  handleSubmit: () => void;
}

const HeaderMobile = memo<HeaderMobileProps>(({
  isMobile, blackTheme, handleSubmit, subscribeStatus,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {isMobile && blackTheme
        ? <HeaderLogoWhite onClick={() => navigate(APP_ROUTES.MAIN)} />
        : <HeaderLogo onClick={() => navigate(APP_ROUTES.MAIN)} /> }
      <HeaderButtonsContainer>
        {!subscribeStatus && (
        <GetPremiumHeaderButton
          type="submit"
          onClick={() => navigate(APP_ROUTES.SUBSCRIPTION)}
        >
          Get Premium
        </GetPremiumHeaderButton>
        )}
        {isMobile && blackTheme ? <BurgerOpen onClick={handleSubmit} />
          : <BurgerClose onClick={handleSubmit} />}
      </HeaderButtonsContainer>
    </>
  );
});

export default HeaderMobile;
