import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/helpers';
import {
  updatePassword,
  getSubscriptions,
  getFriends,
  getFriendCode,
  regenerateFriendCode,
  generateFriendCode,
} from '../services';
import {
  PASSWORD_SUCCESS_MESSAGE,
} from '../constants';
import { IFriend, ISubscription } from '../../../types';

export interface IProfileState {
  isPasswordLoading: boolean;
  isSubmitSuccessfull: boolean;
  isSubscriptionsLoading: boolean;
  subscriptions: ISubscription[] | null;
  friends: IFriend[];
  code: string;
}

const initialState: IProfileState = {
  isPasswordLoading: false,
  isSubmitSuccessfull: false,
  isSubscriptionsLoading: false,
  subscriptions: null,
  friends: [],
  code: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setIsSubmitSuccessfull: (state, { payload }: PayloadAction<boolean>) => {
      state.isSubmitSuccessfull = payload;
    },
    resetLocalProfile: (state) => {
      state.isPasswordLoading = initialState.isPasswordLoading;
      state.isSubmitSuccessfull = initialState.isSubmitSuccessfull;
      state.subscriptions = initialState.subscriptions;
      state.friends = initialState.friends;
      state.code = initialState.code;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePassword.pending, (state) => {
      state.isPasswordLoading = true;
      state.isSubmitSuccessfull = false;
    });
    builder.addCase(
      updatePassword.fulfilled,
      (state) => {
        state.isPasswordLoading = false;
        state.isSubmitSuccessfull = true;
        toast.success(PASSWORD_SUCCESS_MESSAGE);
      },
    );
    builder.addCase(updatePassword.rejected, (state, { payload }) => {
      state.isPasswordLoading = false;
      state.isSubmitSuccessfull = false;
      toast.error(getErrorMessage(payload));
    });
    builder.addCase(
      getSubscriptions.pending,
      (state) => {
        state.isSubscriptionsLoading = true;
      },
    );
    builder.addCase(
      getSubscriptions.fulfilled,
      (state, { payload }) => {
        state.subscriptions = payload.subscriptions;
        state.isSubscriptionsLoading = false;
      },
    );
    builder.addCase(getSubscriptions.rejected, (state, { payload }) => {
      state.isSubscriptionsLoading = false;
      toast.error(getErrorMessage(payload));
    });
    builder.addCase(
      getFriends.fulfilled,
      (state, { payload }) => {
        state.friends = payload.friends;
      },
    );
    builder.addCase(
      getFriends.rejected,
      (state) => {
        state.friends = [];
      },
    );
    builder.addCase(
      getFriendCode.fulfilled,
      (state, { payload }) => {
        state.code = payload.code;
      },
    );
    builder.addCase(getFriendCode.rejected, (state, { payload }) => {
      toast.error(getErrorMessage(payload));
    });
    builder.addCase(
      regenerateFriendCode.fulfilled,
      (state, { payload }) => {
        state.code = payload.code;
      },
    );
    builder.addCase(regenerateFriendCode.rejected, (state, { payload }) => {
      toast.error(getErrorMessage(payload));
    });
    builder.addCase(
      generateFriendCode.fulfilled,
      (state, { payload }) => {
        state.code = payload.code;
      },
    );
    builder.addCase(generateFriendCode.rejected, (state, { payload }) => {
      toast.error(getErrorMessage(payload));
    });
  },
});
export const { setIsSubmitSuccessfull, resetLocalProfile } = profileSlice.actions;
export default profileSlice.reducer;
