import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { verificationCodeSchema } from '../../../utils/validation/schemas';
import { StyledDefaultButtonTitle } from '../../buttons/DefaultButton/styles';
import {
  StyledErrorFormMessage,
  SubmitStyledButton,
} from '../../wallpapers/auth/styles';
import { StyledVerificationForm } from '../../../containers/ResetPassword/styles';
import CustomCodeInput from '../../inputs/CustomCodeInput';
import { IVerificationCodeRequest } from '../../../types';

interface IRegisterFormComponentProps {
  onSubmit: (data: IVerificationCodeRequest) => void
  errorMessage?: string | null
  isLoading: boolean
}

const VerificationCodeForm: FC<IRegisterFormComponentProps> = ({
  onSubmit, errorMessage, isLoading,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isValid, errors, isDirty },
  } = useForm<IVerificationCodeRequest>({
    resolver: joiResolver(verificationCodeSchema),
    mode: 'onBlur',
  });

  return (
    <StyledVerificationForm
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="code"
        control={control}
        render={({ field: { onChange } }) => (
          <CustomCodeInput
            onChange={onChange}
            name="code"
            label="Verification code"
            {...(errors.code?.message ? { error: errors?.code?.message } : {})}
          />
        )}
      />
      {errorMessage && (<StyledErrorFormMessage>{errorMessage}</StyledErrorFormMessage>)}
      <SubmitStyledButton
        disabled={!(isValid && isDirty)}
        type="submit"
        isLoading={isLoading}
      >
        <StyledDefaultButtonTitle>
          Confirm
        </StyledDefaultButtonTitle>
      </SubmitStyledButton>
    </StyledVerificationForm>
  );
};

export default VerificationCodeForm;
