import styled from 'styled-components';

export const PlayerContainer = styled.div`
  position: fixed;
  height: calc(100vh - 40px);
  width: 100vw;
  top: 40px;
  background-color: ${({ theme }) => theme.palette.black100};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: calc(100vh - 60px);
    top: 60px;
  }
`;

export const PlayerBackDrop = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  padding: 24px 40px 0;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.white};
  align-items: flex-start;
  background: linear-gradient(180deg, ${({ theme }) => theme.palette.black100} 0%, ${({ theme }) => theme.palette.titleGray} 100%);
  z-index: 3;
`;
