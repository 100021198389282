import styled from 'styled-components';

export const StyledBlackPageWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.black100};
`;

export const StyledSliderContainer = styled.div`
  width: 100%;
  & .swiper {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    min-height: 0;
    min-width: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;
