import React, {
  FC, memo, useCallback, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useWindowSize from '../../utils/hooks/useWindowSize';
import ResetPasswordWrapper from './components/ResetPasswordWrapper';
import { BREAKPOINTS } from '../../utils/constants';
import { resendCode } from '../Login/services';
import { resetPassword, resetVerify, setPassword } from './services';
import { IVerificationCodeRequest } from '../../types';
import { ActiveScreen, IResetPasswordRequest, ISetPasswordRequest } from './types';
import { setTempToken } from '../Login/slices';
import { APP_ROUTES } from '../../core/router/appRoutes';
import {
  getLoadingResetPasswordSelector,
  getResetPasswordErrorSelector,
  getResetVerifyErrorSelector,
  getSetPasswordErrorSelector,
} from './selectors';
import { getTempTokenSelector } from '../../core/selectors';

const ResetPassword: FC = memo(() => {
  const [width] = useWindowSize();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<ActiveScreen>(ActiveScreen.EMAIL);
  const [email, setEmail] = useState<string>('');

  const navigate = useNavigate();

  const resetPasswordError = useAppSelector(getResetPasswordErrorSelector);
  const resetVerifyError = useAppSelector(getResetVerifyErrorSelector);
  const setPasswordError = useAppSelector(getSetPasswordErrorSelector);
  const isLoading = useAppSelector(getLoadingResetPasswordSelector);
  const tempToken = useAppSelector(getTempTokenSelector);

  const onSubmit = useCallback((values: IResetPasswordRequest) => {
    dispatch(resetPassword(values)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        if (response.payload && 'tempToken' in response.payload) {
          dispatch(setTempToken(response.payload.tempToken));
          setEmail(values.email);
          setPage(ActiveScreen.CODE);
        }
      }
    });
  }, []);

  const onVerificationSubmit = useCallback((values: IVerificationCodeRequest) => {
    if (tempToken) {
      dispatch(resetVerify({ ...values, tempToken })).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          if (response.payload && 'tempToken' in response.payload) {
            dispatch(setTempToken(response.payload.tempToken));
            setPage(ActiveScreen.PASSWORD);
          }
        }
      });
    }
  }, [tempToken]);

  const onPasswordSubmit = useCallback((values: ISetPasswordRequest) => {
    if (tempToken) {
      dispatch(setPassword({ ...values, tempToken })).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          setTempToken(null);
          setPage(ActiveScreen.EMAIL);
          navigate(APP_ROUTES.LOGIN);
        }
      });
    }
  }, [tempToken]);

  const onResendCode = useCallback(() => {
    if (email) {
      dispatch(resendCode({ email }));
    }
  }, [email]);

  return (
    <ResetPasswordWrapper
      onSubmit={onSubmit}
      onVerificationSubmit={onVerificationSubmit}
      onPasswordSubmit={onPasswordSubmit}
      onResendCode={onResendCode}
      resetPasswordError={resetPasswordError}
      resetVerifyError={resetVerifyError}
      setPasswordError={setPasswordError}
      page={page}
      isMobile={width <= BREAKPOINTS.mobile}
      isLoading={isLoading}
      email={email}
    />
  );
});

export default ResetPassword;
