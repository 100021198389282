import React, {
  FC, memo, useCallback, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  facebookSignIn, getCurrentUser, googleSignIn, login, resendCode,
} from './services';
import LoginWrapper from './components/LoginWrapper';
import {
  getLoadingUserSelector, getLoginError, getTempTokenSelector, getVerifyError,
} from '../../core/selectors';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../utils/constants';
import { ILoginRequest } from './types';
import { IVerificationCodeRequest } from '../../types';
import { setAuthTokens } from './slices';
import { signVerify } from '../../core/services';

const Login: FC = memo(() => {
  const [width] = useWindowSize();
  const dispatch = useAppDispatch();

  const [isCodePage, setCodePage] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const loginError = useAppSelector(getLoginError);
  const verifyError = useAppSelector(getVerifyError);
  const isLoading = useAppSelector(getLoadingUserSelector);
  const tempToken = useAppSelector(getTempTokenSelector);

  const getCurrentUserCallback = useCallback((response: any) => {
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(getCurrentUser());
    }
  }, []);

  const onResendCode = useCallback(() => {
    if (email) {
      dispatch(resendCode({ email }));
    }
  }, [email]);

  const onSubmit = (values: ILoginRequest) => {
    dispatch(login(values)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        getCurrentUserCallback(response);
      }
      if (response.meta.requestStatus === 'rejected') {
        if (response.payload && 'statusCode' in response.payload) {
          const { statusCode } = response.payload;
          if (statusCode === 406) {
            setEmail(values.email);
            dispatch(resendCode({ email: values.email }));
            setCodePage(true);
          }
        }
      }
    });
  };

  const onVerificationSubmit = useCallback((values: IVerificationCodeRequest) => {
    if (tempToken) {
      dispatch(signVerify({ ...values, tempToken })).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          if (response.payload && 'accessToken' in response.payload) {
            dispatch(setAuthTokens(response.payload));
          }
        }
      });
    }
  }, [tempToken]);

  const onGoogleSignInResponse = (token: string) => {
    dispatch(googleSignIn({ token })).then((response) => {
      getCurrentUserCallback(response);
    });
  };

  const onFacebookSignInResponse = (token: string) => {
    dispatch(facebookSignIn({ token })).then((response) => {
      getCurrentUserCallback(response);
    });
  };

  return (
    <LoginWrapper
      onSubmit={onSubmit}
      isCodePage={isCodePage}
      onResendCode={onResendCode}
      onVerificationSubmit={onVerificationSubmit}
      loginError={loginError}
      verifyError={verifyError}
      onFacebookSignInResponse={onFacebookSignInResponse}
      onGoogleSignInResponse={onGoogleSignInResponse}
      isMobile={width <= BREAKPOINTS.mobile}
      isLoading={isLoading}
      email={email}
      backButtonVerificationClick={() => setCodePage(false)}
    />
  );
});

export default Login;
