import React, {
  JSX, memo, useEffect,
} from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { ContentContainer } from './styles';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  getCurrentUser, /* , logout */
} from '../../../containers/Login/services';
import { COOKIE_ACCESS_TOKEN_NAME, REDIRECT_URL_NAME } from '../../../utils/constants';
import { setAccessToken } from '../../../containers/Login/slices';
import { getAccessTokenSelector, getCurrentUserSelector } from '../../selectors';
import { getProfile } from '../../services';
import { getStorageValue, removeStorageValue } from '../../../utils/helpers';
import { APP_ROUTES } from '../../router/appRoutes';

interface IBaseLayoutProps {
  children: JSX.Element
}

const BaseLayout = memo<IBaseLayoutProps>(({ children }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isAuthenticationRoute = pathname === APP_ROUTES.LOGIN
  || pathname === APP_ROUTES.REGISTER || pathname === APP_ROUTES.RESET_PASSWORD;
  const currentUser = useAppSelector(getCurrentUserSelector);
  const accessToken = useAppSelector(getAccessTokenSelector);

  // const onLogoutClickHandler = () => {
  //   dispatch(logout());
  // };

  useEffect(() => {
    dispatch(setAccessToken(Cookies.get(COOKIE_ACCESS_TOKEN_NAME) || ''));
  }, []);

  useEffect(() => {
    if (getStorageValue(REDIRECT_URL_NAME) && !isAuthenticationRoute) {
      removeStorageValue(REDIRECT_URL_NAME);
    }
  }, [pathname]);

  useEffect(() => {
    if (!currentUser && accessToken) {
      dispatch(getCurrentUser());
      dispatch(getProfile());
    }
  }, [currentUser, accessToken, dispatch]);

  return (
    <ContentContainer>
      {
        !currentUser ? (
          children
        ) : (
          children // TODO registered layout
        )
      }
    </ContentContainer>
  );
});

export default BaseLayout;
