import { IOtpCodeResponse } from '../../../types';

export interface IResetPasswordRequest {
  email: string;
}

export interface ISetPasswordRequest extends IOtpCodeResponse {
  password: string
  confirmPassword: string
}

export enum ActiveScreen {
  CODE = 'code',
  PASSWORD = 'password',
  EMAIL = 'email',
}
