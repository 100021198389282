import styled from 'styled-components';

export const PageLoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.black100};
  position: fixed;
  top: 0;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  z-index: 1;
`;
