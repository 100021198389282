import React from 'react';
import {
  Card,
  WhiteBox,
  OneTextIcon,
  TwelveTextIcon,
  CardContentLeftStyled,
  PriceContainerStyled,
  PriceWhite,
  BodyTextWhite,
  BodyHidden,
  RedBadgeStyled,
  BadgeContainer,
} from '../../styles';
import { ISubscriptionMapping } from '../../../Profile/types';
import { SubscriptionType } from '../../constants';

interface IPremiumCardProps {
  subscription: ISubscriptionMapping;
  type: SubscriptionType;
  changeSubscription: (id: string) => void;
  isActive: boolean;
  description: string;
}

const PremiumCard = ({
  subscription, type, changeSubscription, isActive, description,
}: IPremiumCardProps) => {
  const isMonth = type === SubscriptionType.MONTH;
  const {
    price, text, period, trialDays,
  } = subscription;

  return (
    <Card isActive={isActive} onClick={() => changeSubscription(type)}>
      <WhiteBox>
        <p>{`${trialDays} Day Trial`}</p>
      </WhiteBox>
      {isMonth ? <OneTextIcon /> : <TwelveTextIcon />}
      <CardContentLeftStyled>
        <BadgeContainer>
          {isMonth ? null : <RedBadgeStyled>{description}</RedBadgeStyled>}
          <PriceContainerStyled>
            <PriceWhite>{`$${price}`}</PriceWhite>
            <BodyTextWhite>{`/ ${period}`}</BodyTextWhite>
          </PriceContainerStyled>
        </BadgeContainer>
        <span>
          <BodyTextWhite>{`$${price}`}</BodyTextWhite>
          <BodyHidden>{`billed ${text}`}</BodyHidden>
        </span>
      </CardContentLeftStyled>
    </Card>
  );
};

export default PremiumCard;
