import React, { memo, useEffect, useMemo } from 'react';
import PageWrapper from './PageWrapper';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getCoursesErrorSelector, getCoursesSelector } from '../../../../core/selectors';
import { fetchCourses } from '../../../../core/services';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../../../utils/constants';

const Home3 = memo(() => {
  const courses = useAppSelector(getCoursesSelector);
  const coursesError = useAppSelector(getCoursesErrorSelector);

  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.mobile, [width]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!coursesError && !courses) {
      dispatch(fetchCourses({
        page: 1,
        pageSize: 20,
      }));
    }
  }, [courses, coursesError]);
  return (
    <PageWrapper isMobile={isMobile} courses={courses} />
  );
});

export default Home3;
