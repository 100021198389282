import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../icons/close-icon.svg';

export const ModalStyles = styled.div<{ $isPrimaryTheme?: boolean, $modalWidth?: number }>`
  background-color: ${({ $isPrimaryTheme, theme }) => ($isPrimaryTheme ? theme.palette.white : theme.palette.black100)};
  width: ${({ $modalWidth }) => `${$modalWidth}px`};
  height: fit-content;
  border-radius: 24px;
  &:active, &:focus {
    outline: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    border-radius: 24px 24px 0px 0px;
    height: fit-content;
    width: 100%;
  }
  position: relative;
`;

export const OverlayStyles = styled.div<{ $isPrimaryTheme?: boolean }>`
  position: fixed;
  inset: 0px;
  background-image: ${({ theme, $isPrimaryTheme }) => ($isPrimaryTheme
    ? `linear-gradient(${theme.palette.gradientGray}, ${theme.palette.gradientDarkGray})`
    : `linear-gradient(${theme.palette.secondaryGradientWhite}, ${theme.palette.secondaryGradientLightGray})`)};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: flex-end;
  }
`;

export const ModalContainer = styled.div`
  padding: 32px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`;

export const ModalHeader = styled.div<{ $isTitleCented: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isTitleCented }) => ($isTitleCented ? 'center' : 'space-between')};
  position: relative;
`;

export const Title = styled.h2<{ $isPrimary: boolean, $headerSm: boolean }>`
  font: ${({ theme, $headerSm }) => ($headerSm ? theme.typography.h5_popper.font : theme.typography.h2.font)};
  color: ${({ $isPrimary, theme }) => ($isPrimary ? theme.palette.black : theme.palette.white)};
`;

export const StyledClose = styled(CloseIcon)`
  width: 32px;
  height: 32px;
  cursor: pointer;

  position: absolute;
  top: 16px;
  right: 17px;
`;

export const ModalBody = styled.div`
  width: 100%;
`;

export const ModalFooter = styled.div<{ $isBtnsCented: boolean }>`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: ${({ $isBtnsCented }) => ($isBtnsCented ? 'center' : 'flex-end')};
  }
`;

export const CancelButton = styled.button`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 44px;
  color: ${(props) => props.theme.palette.black100};
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 80px;
  border: 1px solid ${(props) => props.theme.palette.black100};
  min-width: 170px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 8px;
`;
