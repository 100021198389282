import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Instagram } from '../../../icons/instagram-white.svg';
import { ReactComponent as AppStore } from '../../../images/appstore.svg';

export const FooterBackground = styled.div`
    display: flex;
    width: 100%;
    background-color: ${(props) => props.theme.palette.black100};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction:column;
    width: 100%;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 64px;
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        height: auto;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 64px;
      }
`;

export const LinksContainer = styled.div`
    display: flex;
    flex-direction:column;
    margin-top: 40px;
    margin-bottom: 40px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 0px;
        margin-bottom: 0px;
      }
`;

export const LinkEmail = styled(Link)`
    font: ${(props) => props.theme.typography.body4_new.font};
    text-decoration: none;
    color: ${(props) => props.theme.palette.white};
    margin-bottom: 16px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: -15px;
        margin-bottom: 24px;
      }
`;

export const InstagramIcon = styled(Instagram)`
    width: 16px;
    height: 16px;
    margin-left: 8px;
`;

export const AppStoreIcon = styled(AppStore)`
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-bottom: 24px;
    }
`;

export const LinkInstagram = styled(Link)`
    display: flex;
    align-items: end;
    text-decoration: none;
    color: ${(props) => props.theme.palette.white};
    font: ${(props) => props.theme.typography.subtitle3_new.font};
`;

export const TextForForm = styled.span`
    font: ${(props) => props.theme.typography.subtitle22_26.font};
    color: ${(props) => props.theme.palette.white};
    margin-bottom: 16px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-bottom: 10px;
    }
`;

export const HrLine = styled.hr`
    margin-top: 40px;
    margin-bottom: 40px;
    border: 1px solid ${(props) => props.theme.palette.black95}
`;

export const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        justify-content: inherit;
        gap: 40px;
    }
`;

export const InfoLink = styled(Link)`
    text-decoration: none;
    font: ${(props) => props.theme.typography.body5.font};
    color: ${(props) => props.theme.palette.black10};
`;

export const CopyrightBlock = styled.div`
    margin-top: 24px;
    margin-bottom: 64px;
    font: ${(props) => props.theme.typography.body5.font};
    color: ${(props) => props.theme.palette.black10};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

export const FooterBlock = styled.div`
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const FormBlock = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: inherit;
    }
`;

export const FooterEndBlock = styled.div`
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 64px;
    }
`;
