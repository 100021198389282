import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import {
  SliderCourseCardLevel,
  SliderCourseCardSubTitle,
  SliderCourseCardTitle,
  StyledTextContainer,
} from '../../styles';
import { SliderCourseCard } from '../../../../common/styles';
import { ICourse } from '../../../../../types';
import sliderMock from '../../../../../images/background-slide-mock.jpg';
import sliderVerticalMock from '../../../../../images/vertical-mock.jpg';
import { APP_ROUTES } from '../../../../../core/router/appRoutes';
import { getCourseLevels } from '../../../../../utils/helpers';
import CourseSkeleton from '../../../../loaders/CourseSkeleton';
import { useLoadImage } from '../../../../../utils/hooks/useLoadImage';

interface ICourseProps {
  course: ICourse;
  isMobile: boolean;
}

const Course = ({ course, isMobile }: ICourseProps) => {
  const navigate = useNavigate();
  const url = isMobile ? course.image?.url : course?.coursePreview?.url;
  const src = useLoadImage(url);

  return (
    src ? (
      <SliderCourseCard
        onClick={() => navigate(APP_ROUTES.COURSES_ID.replace(':id', course.id))}
        key={uuid()}
        image={
        isMobile
          ? (src ?? sliderVerticalMock)
          : (src ?? sliderMock)
      }
      >
        <StyledTextContainer key={uuid()}>
          <SliderCourseCardLevel key={uuid()}>
            {getCourseLevels(course.level)}
          </SliderCourseCardLevel>
          <SliderCourseCardTitle key={uuid()}>
            {course.name}
          </SliderCourseCardTitle>
          <SliderCourseCardSubTitle key={uuid()}>
            {course.shortDescription}
          </SliderCourseCardSubTitle>
        </StyledTextContainer>
      </SliderCourseCard>
    ) : (
      <CourseSkeleton
        isMobile={isMobile}
        webHeight={280}
        webWidth={491}
        mobileHeight={417}
        mobileWidth="100%"
      />
    )
  );
};

export default memo(Course);
