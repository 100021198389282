import React, {
  FC, memo, useCallback,
} from 'react';
import { IContactForm } from '../../core/types';
import ContactWrapper from './components/ContactWrapper';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { BREAKPOINTS } from '../../utils/constants';
import { useAppDispatch } from '../../hooks';
import { notifyMe } from './services';

const Contact: FC = memo(() => {
  const [width] = useWindowSize();
  const dispatch = useAppDispatch();

  const onSubmitHandler = useCallback((values: IContactForm) => {
    dispatch(notifyMe(values));
  }, []);

  return (
    <ContactWrapper
      onSubmit={onSubmitHandler}
      isMobile={width <= BREAKPOINTS.mobile}
    />
  );
});

export default Contact;
