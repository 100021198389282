import React from 'react';
import {
  Header,
  PageContainer,
  Paragraph,
  SubHeader,
  UnorderedList,
} from './styles';

export default function TermsOfUse() {
  return (
    <PageContainer>
      <Header>Terms of Use</Header>
      <Header>Welcome to Swanly!</Header>
      <Paragraph>
        Thank you for using our product. “Swanly” is provided by Swanly Inc. By
        using Swanly, you agree to the following terms and conditions (Terms of
        Use).
      </Paragraph>
      <SubHeader>1. Basic Terms</SubHeader>
      <Paragraph>
        1.1. <b>Account Responsibility:</b> You are responsible for all
        activities that occur under your user account. 1.2.{' '}
        <b>Password Security:</b> You are responsible for safeguarding the
        password you use to access Swanly and for any activities or actions
        under your password. 1.3. <b>User Conduct:</b> You must not abuse,
        harass, threaten, impersonate, or intimidate other Swanly users. 1.4.
        <b> Compliance with Laws:</b> You may not use Swanly for any illegal or
        unauthorized purpose. International users agree to comply with all local
        laws regarding online conduct and acceptable content. 1.5.
        <b> Content Responsibility:</b> You are solely responsible for your
        conduct and any data, text, information, screen names, photos, profiles,
        or links that you submit, post, and display on Swanly. 1.6.
        <b> Intellectual Property:</b> You must not copy, create derivative
        works of, or modify another app to falsely imply that it is associated
        with Swanly. 1.7. <b> Prohibited Content:</b> You must not upload, post,
        email, transmit, forward, provide links to, or otherwise make available
        any unsolicited or unauthorized advertising, promotional materials,
        “junk mail,” “spam,” ”chain letters,” ”pyramid schemes,” or any other
        form of solicitation to any Swanly users. 1.8.
        <b> Security:</b> You must not interfere with or disrupt Swanly or
        servers or networks connected to Swanly such as transmitting worms,
        viruses, or any code of a destructive nature. 1.9.
        <b> Legal Compliance:</b> You must not, in using Swanly, intentionally
        or unintentionally violate any applicable local, state, national, or
        international law.
      </Paragraph>
      <Paragraph>
        Violation of any of these terms will result in the termination of your
        Swanly account. While Swanly prohibits such conduct and content within
        the app, you understand and agree that Swanly Inc. cannot be responsible
        for the content posted on its app.
      </Paragraph>
      <SubHeader>2. General Conditions</SubHeader>
      <Paragraph>
        2.1. <b>Service Modification:</b>
        We reserve the right to modify or terminate the Swanly service for any
        reason, without notice at any time. 2.2. <b>Terms Modification:</b>
        Swanly Inc. reserves the right to change or modify these Terms of Use at
        any time, in its sole discretion, by posting changes on Swanly. Your
        continued use of Swanly will constitute your acceptance of such changes.
        2.3. <b>Service Refusal:</b> We reserve the right to refuse service to
        anyone for any reason at any time. 2.4.
        <b> Content Removal:</b> We may, but have no obligation to, remove
        content and accounts containing content that we determine in our sole
        discretion is unlawful, offensive, threatening, libelous, defamatory,
        obscene, or otherwise objectionable or violates any party’s intellectual
        property or these Terms of Use. 2.5. <b> Data Usage:</b>
        As a condition of your use of Swanly, you agree that Swanly Inc. has the
        right to retain, use, and publish in an aggregate manner information
        collected from your use of the app. 2.6.
        <b>Username Reclamation:</b> We reserve the right to reclaim usernames
        on behalf of businesses or individuals that hold legal claims or
        trademarks on those usernames.
      </Paragraph>
      <SubHeader>3. Disclaimer</SubHeader>
      <Paragraph>
        The techniques and suggestions presented in the Swanly application and
        website are not intended to substitute for proper medical advice.
        Consult your physician before beginning any new exercise program. The
        Swanly application and website assume no responsibility for injuries
        suffered while practicing these techniques.
      </Paragraph>
      <SubHeader>4. Subscriptions</SubHeader>
      <Paragraph>
        4.1. <b>Subscription Options:</b> We offer in-app purchases within the
        Swanly app, including Monthly, Yearly, and Lifetime subscriptions. 4.2.
        <b>Payment:</b> Payment will be charged to your payment method (PayPal,
        Credit Card, iTunes, Google Play) at the confirmation of purchase. 4.3.{' '}
        <b>Auto-Renewal:</b> Subscriptions are automatically renewed unless
        turned off at least 24 hours before the end of the current period. 4.4.{' '}
        <b>Free Trials:</b> A 7-day free trial may be offered with
        Monthly/Yearly subscriptions. If you do not cancel the subscription
        before the trial period ends, the subscription will auto-renew, and you
        will be charged. 4.5. <b>Unused Free Trials:</b>
        Any unused portion of a free trial period will be forfeited when you
        purchase a subscription. 4.6. <b>Pricing Changes:</b> We reserve the
        right to modify current and future pricing models for our service at any
        time.
      </Paragraph>
      <SubHeader>5. Community Terms</SubHeader>
      <Paragraph>
        5.1. <b>Content Ownership:</b> You own the content you create and share
        on the Swanly Community. You are free to share your content with anyone
        else, wherever you want. 5.2. <b>Licensing:</b> When you share, post, or
        upload content covered by intellectual property rights (like photos) on
        Swanly, you grant us a non-exclusive, transferable, sub-licensable,
        royalty-free, worldwide license to host, use, distribute, modify, run,
        copy, publicly perform or display, translate, and create derivative
        works of your content. 5.3.
        <b>Intellectual Property Rights:</b> Swanly takes intellectual property
        rights seriously. You own all the content and information you post on
        Swanly and control how it is shared. Before sharing content, ensure you
        have the right to do so and respect others&apos; copyrights, trademarks,
        and other legal rights. 5.4.
        <b>Copyright Compliance:</b> Ensure that the copyright of the pictures
        you use in community posts is owned by you or authorized by the
        copyright owner. Swanly is not responsible for disputes arising from
        illegal use; all consequences shall be borne by the user.
      </Paragraph>
      <SubHeader> 6. Refund Policy</SubHeader>
      <Paragraph>
        6.1. <b>No Refunds for Premium:</b> Due to its virtual nature, Premium
        is consumed once purchased, and a refund is not available. 6.2.{' '}
        <b>Partial Refunds:</b> If a refund is eligible under legislation or
        judged by Swanly, a partial refund may be issued.
      </Paragraph>
      <UnorderedList>
        <li>
          6.2.1. For in-app purchases made through the App Store, refunds must
          be handled by Apple.
        </li>
        <li>
          6.2.2. For in-app purchases made through Google Play Store, our
          website, or PayPal, a refund may be issued with a 50% transaction fee
          and the fee for used Premium days. Orders beyond 30 days after payment
          will not be refunded.
        </li>
        <li>
          6.2.3. Users must cancel future renewals in the relevant app market to
          avoid automatic renewals.
        </li>
        <li>
          6.2.4. Lifetime subscriptions, gift card purchases, and promotional
          sales are not eligible for a refund.
        </li>
      </UnorderedList>
      <SubHeader>7. Copyright</SubHeader>
      <Paragraph>
        All resources, including images, videos, audio, and texts, are protected
        by trademark and copyright. Unauthorized use of these resources is
        illegal. All rights, title, and interest in and to Swanly (excluding
        content provided by users) are and will remain the exclusive property of
        Swanly Inc. and its licensors. The services are protected by copyright,
        trademark, and laws of all available countries. Nothing in the Terms
        gives you a right to use the Swanly name or any of the Swanly
        trademarks, logos, domain names, images, videos, audio texts, and other
        distinctive brand features. Any feedback, comments, or suggestions you
        provide regarding Swanly are entirely voluntary and we will be free to
        use them as we see fit without any obligation to you.
      </Paragraph>
    </PageContainer>
  );
}
