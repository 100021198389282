import React, { memo, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getCoursesErrorSelector, getCoursesLoadingSelector, getCoursesSelector, getGlobalProfileSelector,
} from '../../core/selectors';
import { fetchCourses } from '../../core/services';
import PageWrapper from './components/PageWrapper';
import PageLoader from '../../components/loaders/PageLoader';

const Courses = memo(() => {
  const courses = useAppSelector(getCoursesSelector);
  const isCoursesLoading = useAppSelector(getCoursesLoadingSelector);
  const coursesError = useAppSelector(getCoursesErrorSelector);
  const profile = useAppSelector(getGlobalProfileSelector);
  const dispatch = useAppDispatch();
  const isSubscribed = useMemo(() => (
    profile?.isFriend || profile?.isSubscribed
  ), [profile]);

  useEffect(() => {
    if (!courses && !coursesError) {
      dispatch(fetchCourses({ page: 1, pageSize: 10 }));
    }
  }, [courses, coursesError, dispatch]);

  if (!courses || isCoursesLoading) {
    return (
      <PageLoader />
    );
  }

  return (
    <PageWrapper
      isSubscribed={isSubscribed}
      courses={courses}
    />
  );
});

export default Courses;
