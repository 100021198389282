import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 64px 16px;
  background-color: ${(props) => props.theme.palette.white};
  min-height: 100vh;
  gap: 56px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: center;
    align-items: unset;
    padding: 96px 80px;
    position: relative;
  }
`;

export const StyledSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin-bottom: 121px;
  & .swiper {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    min-height: 0;
    min-width: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin-bottom: 92px;
  }
`;

export const StyledSwiperSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 118px;
  }
`;

export const StyledSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
`;

interface IStyledImageProps {
  image: string
}

export const StyledImage = styled.div<IStyledImageProps>`
  background-image: url(${(props) => props.image});
  width: 343px;
  height: 332px;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 748px;
    height: 724px;
  }
`;

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 654px;
  }
`;

export const FeatureText = styled.span`
  font: ${(props) => props.theme.typography.caption_mobile_2.font};
  color: ${(props) => props.theme.palette.black65};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: unset;
    position: absolute;
    top: 96px;
    left: 80px;
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.typography.h1_mobile_lowercase};
  color: ${(props) => props.theme.palette.black100};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.h2_lowercase};
  }
`;

export const SubTitle = styled.p`
  ${(props) => props.theme.typography.body_mobile};
  color: ${(props) => props.theme.palette.black100};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${(props) => props.theme.typography.body};
  }
`;
