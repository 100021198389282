import React, { memo, useRef } from 'react';
import AuthWallpaper from '../../../../components/wallpapers/auth';

import {
  StyledLogoDesktopIcon,
  StyledSocialNetworkContainer, StyledSubTitle,
  StyledTitle,
} from '../../../../components/wallpapers/auth/styles';
import { StyledRegisterContentContainer, StyledRegisterLogoMobileIcon } from '../../styles';
import { ActiveScreen, IResetPasswordRequest, ISetPasswordRequest } from '../../types';
import { IVerificationCodeRequest } from '../../../../types';
import VerificationCodeComponent from '../../../../components/wallpapers/otp';
import ResetPasswordForm from '../ResetPasswordForm';
import SetPasswordForm from '../SetPasswordForm';

interface IResetPasswordProps {
  onSubmit: (values: IResetPasswordRequest) => void
  onVerificationSubmit: (values: IVerificationCodeRequest) => void
  onPasswordSubmit: (values: ISetPasswordRequest) => void
  onResendCode: () => void
  resetPasswordError?: string | null
  resetVerifyError?: string | null
  setPasswordError?: string | null
  isMobile: boolean
  page: ActiveScreen
  isLoading: boolean
  email: string
}
const ResetPasswordWrapper = memo<IResetPasswordProps>((
  {
    onSubmit,
    onVerificationSubmit,
    onResendCode,
    resetPasswordError,
    resetVerifyError,
    setPasswordError,
    onPasswordSubmit,
    page,
    isMobile,
    isLoading,
    email,
  },
) => {
  const buttonRef = useRef<any>();
  const handleOnResendCode = () => {
    onResendCode();
    if (buttonRef?.current) {
      buttonRef.current.dispatchEvent(new Event('click', { cancelable: true, bubbles: true }));
    }
  };
  return (
    <AuthWallpaper
      isMobile={isMobile}
    >
      <StyledRegisterContentContainer>
        {isMobile ? (
          <StyledRegisterLogoMobileIcon />
        ) : (
          <StyledLogoDesktopIcon />
        )}
        {
          page === ActiveScreen.EMAIL && (
            <>
              <StyledSocialNetworkContainer>
                <StyledTitle>
                  Access recovery
                </StyledTitle>
                <StyledSubTitle>
                  Enter the email you used to register your account
                  {' '}
                  {email}
                </StyledSubTitle>
              </StyledSocialNetworkContainer>
              <ResetPasswordForm
                isLoading={isLoading}
                onSubmit={onSubmit}
                errorMessage={resetPasswordError}
              />
            </>
          )
        }
        {
          page === ActiveScreen.CODE && (
            <VerificationCodeComponent
              email={email}
              errorMessage={resetVerifyError}
              isLoading={isLoading}
              ref={buttonRef}
              onVerificationSubmit={onVerificationSubmit}
              handleOnResendCode={handleOnResendCode}
            />
          )
        }
        {
          page === ActiveScreen.PASSWORD && (
            <>
              <StyledSocialNetworkContainer>
                <StyledTitle>
                  New password
                </StyledTitle>
              </StyledSocialNetworkContainer>
              <SetPasswordForm
                isLoading={isLoading}
                onSubmit={onPasswordSubmit}
                errorMessage={setPasswordError}
              />
            </>
          )
        }
      </StyledRegisterContentContainer>
    </AuthWallpaper>
  );
});

export default ResetPasswordWrapper;
