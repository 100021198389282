import styled from 'styled-components';
import subscriptionMobile from '../../../images/subscription-mobile.jpg';
import subscriptionDesktop from '../../../images/subscription-desktop.jpg';
import { ReactComponent as SwanlyIcon } from '../../../icons/swanly-text-icon.svg';
import { StyledEllipsisSpan } from '../../../components/common/styles';

export const HeadBannerPageContainer = styled.div`
  width: 100%;
  padding: 16px 0 48px;
  position: relative;
  & .swiper {
    & .swiper-pagination-bullets-dynamic {
      width: 98px!important;
      bottom: 24px;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background: ${(props) => props.theme.palette.white};
        opacity: 0.65;
        margin: 0 6px;
        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px 0 96px;
  }
`;

interface IImageProps {
  image: string
}

export const HeadBannerBackDrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 16px 60px;
  color: ${(props) => props.theme.palette.white};
  border-radius: 16px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px 140px 96px;
    width: 1120px;
    height: 680px;
  }
`;

export const HeadBannerSlideContainer = styled.div<IImageProps>`
  background-image: url(${(props) => props.image});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 16px;
  min-width: 375px;
  min-height: 503px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 1120px;
    height: 680px;
  }
`;

export const HeadBannerSlideCardLevel = styled.span`
  font: ${(props) => props.theme.typography.subtitle4.font};
  text-transform: uppercase;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.subtitle1.font};
  }
`;
export const HeadBannerSlideCardTitle = styled.span`
  font: ${(props) => props.theme.typography.h3.font};
  margin-bottom: 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h1_new.font};
  }
`;
export const HeadBannerSlideCardSubTitle = styled(StyledEllipsisSpan)`
  font: ${(props) => props.theme.typography.body4_new.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.body3_new.font};
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: calc(100% - 22px);
  margin: 0 auto;
  background-color: ${(props) => props.theme.palette.black65};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(100% - 160px);
  }
`;

export const NewCoursesPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  padding: 16px 16px 64px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 40px;
    padding: 16px 80px 96px;
  }
`;

export const PageTitle = styled.span`
  font: ${(props) => props.theme.typography.h3_mobile.font};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.black100};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h5.font};
  }
`;

export const CoursesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  min-height: 220px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
  }
`;

export const ClassesContainer = styled(CoursesContainer)`
  & .swiper-wrapper {
    max-height: 220px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      max-height: 308px;
    }
  }
`;

export const CourseWideCard = styled.div<IImageProps>`
  background-image: url(${(props) => props.image});
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-width: 343px;
  min-height: 184px;
  padding: 12px;
  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 491px;
    padding: 16px;
    min-height: 280px;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: ${(props) => props.theme.palette.white};
`;

export const StyledCardTitle = styled.span`
  font: ${(props) => props.theme.typography.h2_mobile.font};
  margin-bottom: 4px;
  text-transform: capitalize;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h5.font};
    margin-bottom: 8px;
  }
`;
export const StyledCardSubTitle = styled(StyledEllipsisSpan)`
  font: ${(props) => props.theme.typography.body5.font};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.body3_new.font};
  }
`;

export const SliderCourseCardSubTitle = styled(StyledEllipsisSpan)`
  font: ${(props) => props.theme.typography.body4_new.font};
`;

export const SliderLessonCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 320px;
  min-height: 98px;
  gap: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 491px;
    min-height: 134px;
  }
`;

export const SliderLessonCardImage = styled.div<IImageProps>`
  background-image: url(${(props) => props.image});
  min-height: 96px;
  min-width: 164px;
  border-radius: 12px;
  position: relative;
  background-size: cover;
`;

export const SliderLessonCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 140px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 243px;
  }
`;

export const SliderLessonCardCourse = styled.span`
  font: ${(props) => props.theme.typography.body5.font};
  color: ${(props) => props.theme.palette.black65};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.body4_new.font};
  }
`;

export const SliderLessonCardCourseName = styled.span`
  font: ${(props) => props.theme.typography.body14_20.font};
  color: ${(props) => props.theme.palette.black100};
`;

export const SliderLessonCardLessonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
`;

export const SliderLessonCardLessonName = styled.span`
  font: ${(props) => props.theme.typography.body14_20_500.font};
  color: ${(props) => props.theme.palette.black100};
`;

export const SliderLessonCardLessonDuration = styled.span`
  font: ${(props) => props.theme.typography.body14_20.font};
  color: ${(props) => props.theme.palette.black65};
`;

export const SliderLessonCardLessonDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.palette.black10};
`;

export const StyledSubscriptionContainer = styled.div`
  padding: 16px 16px 64px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 16px 80px 96px;
  }
`;

export const StyledSubscriptionBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 46px 16px;
  min-width: 343px;
  min-height: 770px;
  background-image: url(${subscriptionMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 12px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-height: 702px;
    padding: 46px 124px;
    background-image: url(${subscriptionDesktop});
    border-radius: 40px;
    flex-direction: row;
  }
`;

export const StyledSubscriptionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 532px;
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const StyledLogo = styled(SwanlyIcon)`
  
`;

export const StyledPremium = styled.div`
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: ${(props) => props.theme.palette.black100};
  font: ${(props) => props.theme.typography.body3.font};
  border-radius: 80px;
  border: 1px solid ${(props) => props.theme.palette.red100};
  box-shadow: 0px 0px 8px 0px ${(props) => props.theme.palette.red100}, 0px 0px 2px 0px ${(props) => props.theme.palette.red100};
`;

export const StyledSubscriptionTitle = styled.span`
  color: ${(props) => props.theme.palette.black100};
  font: ${(props) => props.theme.typography.h1_mobile.font};
  margin: 8px 0;
  word-break: keep-all;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.h2_lowercase.font};
  }
`;

export const StyledSubscriptionSubTitle = styled.span`
  color: ${(props) => props.theme.palette.black100};
  font: ${(props) => props.theme.typography.body14_22.font};
  text-align: center;
  margin-bottom: 32px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font: ${(props) => props.theme.typography.body4_new};
  }
`;

export const StyledSubscriptionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 64px;
  background-color: ${(props) => props.theme.palette.red100};
  box-shadow: ${(props) => props.theme.shadow.primaryButtonGlow};
  color: ${(props) => props.theme.palette.white};
  font: ${(props) => props.theme.typography.button_xl.font};
  border: none;
  border-radius: 80px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const StyledSubscriptionBenefitsButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.palette.black100};
  font: ${(props) => props.theme.typography.subtitle3_new.font};
  cursor: pointer;
`;
